import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import n from "../../../navigation/Route.Names";
import BreadcrumbComponent from "../../../common/breadcrumb/breadcrumb";
import {
  CLONE_TIMESHEET,
  CREATE_TIMESHEET_TITLE,
  ENABLE_WEEKEND,
  FAILURE,
  MY_SPACE,
  NOTIFICATION_DURATION,
  OVERALL_COMMENT,
  PROJECT_DATE,
  PROJECT_DATE_VALUE,
  PROJECT_EXTENTION,
  PROJECT_TITLE,
  START,
  SUCCESS,
  TIMESHEET_BREADCRUMB,
  TimeSheetStrings,
  TOTAL_HOUR,
  TOTAL_WORK_HOUR_text,
  WARNING,
} from "../../../../constants";
import produce from "immer";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  TableColumnType,
  TimePicker,
  notification,
} from "antd";
import "./CreateTimeSheet.css";
import CreateDropDownOption from "./CreateDropdownOptionsList";
import CreateTimeSheetFooter from "./CreateTimeSheetFooter";
import PreviewSheet from "../PreviewSheet";
import dayjs from "dayjs";
import moment from "moment";
import { isFilledComment, replaceString } from "../../../utils/util";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import {
  getDetailTimeSheet,
  getLandingTimeSheet,
  previewTimesheet,
  saveTimesheet,
  getDetailTimeSheetWithEdit,
} from "../../../../redux/actions/timesheet";
import Timer_Comment from "../../../../themes/assets/images/svg/Timer-comment.svg";
import Chart_Icon_Comment from "../../../../themes/assets/images/svg/Chat Mobile.svg";
import Office_Briefcase from "../../../../themes/assets/images/svg/Briefcase.svg";
import Time_Calender from "../../../../themes/assets/images/svg/CalendarBlank.svg";
import { TimesheetRowTable } from "./TimesheetRowTable";
import { GoalsErrorIcon } from "../../../common/svg/svg";
import Filled_comment from "../../../../themes/assets/images/svg/Filled-comment.svg";
import {
  notificationAction,
  payloadAction,
  timesheetUpdateRowAction,
  updateWeekendStatus,
  timesheetAddRowAction,
} from "../../../../redux/reducers/timesheet";
import { cloneDeep, isEmpty } from "lodash";
import { type } from "os";

const CreateTimesheet = (props) => {
  const [api, contextHolder] = notification.useNotification({
    top: 0,
    maxCount: 1,
  });
  const timesheet = useAppSelector((state: any) => state.timesheet);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    status = [],
    timeSheetInfo = {},
    holidayDetails,
    myActivities,
    timesheets,
    totalHours = { hours: 0 },
    displayWeek = "",
    defaultHoursAndMinutes,
    originalTimesheets = [],
  } = timesheet?.details || {};
  const { hours } = totalHours;
  const isDisabled = hours <= 0 || hours === undefined;
  const {
    shiftTypes = [],
    dropDownSuggestions = [],
    projects = [],
    task = [],
  } = timeSheetInfo;
  const {
    configuration,
    loading,
    isCloneTimesheetEnabled,
    cloneSelectedWeek = {},
    selectedWeekStart,
    selectedWeekEnd,
  } = timesheet;
  const param = useParams();
  const { week, shift, timesheetId } = param || {};
  const [defaultWeekSelection, setDefaultWeekSelection] = useState(week);
  const [defaultShiftSelection, setDefaultShiftSelection] = useState(shift);
  const [displayPreview, setDisplayPreview] = useState(false);
  const [tableForm] = Form.useForm();
  const [commentForm] = Form.useForm();
  const [commentInfo, setCommentInfo] = useState<any>({});
  const [open, setOpen] = useState(false);
  const isSubmit = timesheets?.length ? true : false;
  const [isLeastHours, setLeastHours] = useState(false);

  const [projectInfo, setProjectInfo] = useState({});
  const [hideDraftBtn, setHideDraftBtn] = useState(false);

  const handleOpenModal = ({
    type,
    index,
    info,
    parentIndex,
    projectName,
    weekSelection,
  }) => {
    //let comment=info
    // if(type==="daily"){
    //   comment=''
    // }else{
    //   comment=''
    // }
    setCommentInfo({
      type,
      index,
      info,
      parentIndex,
      projectName,
      weekSelection,
      iscommentDisable: info ? false : true,
    });
    commentForm.setFieldValue("comment", info);
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getLandingTimeSheet({}));
  }, []);

  const onCloseHandler = () => {
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  const fieldUpdateHandler = ({
    type,
    value,
    index,
    parentindex,
    recordListIndex,
  }) => {
    const updatedTimesheet = tableForm.getFieldValue("projects");
    dispatch(
      timesheetUpdateRowAction({
        type,
        value,
        index,
        parentindex,
        timesheets: updatedTimesheet,
        originalTimesheets,
        recordListIndex: recordListIndex,
      })
    );
  };
  useEffect(() => {
    if (configuration?.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: configuration.alertMessage.additionalInfo,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon: type === FAILURE ? <GoalsErrorIcon /> : null,
        className:
          type === SUCCESS
            ? `custom-toast-area custom-success-toast`
            : type === FAILURE
            ? `custom-toast-area custom-error-toast`
            : `custom-toast-area custom-info-toast`,

        onClose: onCloseHandler,
      });
    }
  }, [configuration?.isShowNotification]);

  useEffect(() => {
    if (Object.keys(configuration)?.length > 0) {
      if (configuration?.alertMessage?.type === "error") {
        setHideDraftBtn(true);
      }
    }
  }, [configuration]);

  useEffect(() => {
    if (timesheetId) {
      setDefaultShiftSelection(timesheet?.details?.shiftType);
      setDefaultWeekSelection(timesheet?.details?.displayWeek);
    }
  }, [timesheet?.details]);

  useEffect(() => {
    detailDispatch();
  }, [dropDownSuggestions, defaultWeekSelection, timesheetId]);

  useEffect(() => {
    if (timesheets && Array.isArray(timesheets)) {
      const totalMinutesSum = timesheets.reduce((totalMinutes, timesheet) => {
        const totalHours = timesheet["totalHours"];
        if (totalHours) {
          let [hours, minutes] = totalHours.split(":").map(Number);
          minutes = isNaN(minutes) ? 0 : minutes;
          totalMinutes += hours * 60 + minutes;
        }
        return totalMinutes;
      }, 0);
      if (totalMinutesSum >= defaultHoursAndMinutes?.hours * 60) {
        setLeastHours(true);
      } else {
        setLeastHours(false);
      }
    }
  }, [timesheets]);

  const queryParams = new URLSearchParams(window.location.search);
  const displayValue = queryParams.get("displayValue") || "";
  const weekStartDate = queryParams.get("weekStartDate") || "";
  const weekEndDate = queryParams.get("weekEndDate") || "";

  const detailDispatch = () => {
    const { cloneWeekStart, cloneWeekEnd } = cloneSelectedWeek || {};
    if (timesheetId) {
      dispatch(getDetailTimeSheetWithEdit(timesheetId));
    } else {
      const filterDropDown = dropDownSuggestions.filter(
        (item) => item.weekStartDate === defaultWeekSelection
      );
      const [single_row = {}] = filterDropDown;
      dispatch(
        getDetailTimeSheet(
          isCloneTimesheetEnabled
            ? {
                isClone: true,
                cloneWeekStart,
                cloneWeekEnd,
                weekStartDate: single_row.weekStartDate || selectedWeekStart,
                weekEndDate: single_row.weekEndDate || selectedWeekEnd,
              }
            : { displayValue, weekEndDate, weekStartDate, ...single_row }
        )
      );
    }
  };

  const onChange = (e, projectIndex) => {
    const isChecked = e?.target?.checked;
    const updatedTimesheet = tableForm.getFieldValue("projects");
    dispatch(
      updateWeekendStatus({
        projectIndex,
        value: isChecked,
        type: "enabledWeekend",
        timesheets: updatedTimesheet,
      })
    );
  };

  const items = [
    {
      title: (
        <Link
          to={`/${START}`}
          // onClick={(e) => redirection(e, TIMESHEET_BREADCRUMB)}
          className="link-highlight"
        >
          {"My Space"}
        </Link>
      ),
    },
    {
      title: (
        <Link
          to={`/${n.START}/${n.MY_SPACE}/timesheet`}
          className="link-highlight"
        >
          {TIMESHEET_BREADCRUMB}
        </Link>
      ),
    },
    {
      title: isCloneTimesheetEnabled ? CLONE_TIMESHEET : CREATE_TIMESHEET_TITLE,
    },
  ];

  const onSubmit = () => {
    tableForm.validateFields().then(() => {});
  };
  const preview = () => {
    return constructPayLoad();
  };

  const weekHandler = (info) => {
    setDefaultWeekSelection(info);
    setHideDraftBtn(false);
  };
  const shiftHandler = (info) => {
    setDefaultShiftSelection(info);
  };

  const constructPayLoad = () => {
    const filterWeeks = dropDownSuggestions.filter(
      (item) => item.weekStartDate === defaultWeekSelection
    );
    let [
      singleRecord = { weekStartDate: "", weekEndDate: "", displayValue: "" },
    ] = filterWeeks;
    // const {
    //   weekStartDate = "",
    //   weekEndDate = "",
    //   displayValue = "",
    // } = singleRecord;

    if (timesheetId) {
      let clonedObject = {};
      clonedObject = {
        ...clonedObject,
        weekStartDate: timesheet?.details?.weekStartDate || weekStartDate,
        weekEndDate: timesheet?.details?.weekEndDate || weekEndDate,
        displayValue: timesheet?.details?.displayWeek || displayWeek,
      };
      singleRecord = { ...clonedObject };
    }

    const payload: any = {
      tenantId: "100",
      shiftType: defaultShiftSelection,
      pendingManagerIds: [],
    };
    const recordInfo: any = {};
    const weekendProjectsList: any = [];
    let totalHours = 0;
    let totalMinutes = 0;
    //const updatedTimesheet=tableForm.getFieldValue("projects")
    timesheets.forEach((item) => {
      let project = {
        project: item.projectName,
        totalHours: item.totalHours,
      };
      let projectInfo = {
        projectName: item.projectName,
        isWorked: item.enabledWeekend,
        overallComments: item.overallComments,
      };
      item.recordsList.forEach((subitem) => {
        let subinfo = {};
        let taskinfo = {};
        subitem?.task?.forEach((taskItem) => {
          const workingHours = dayjs(taskItem?.working_hours).format("HH:mm");
          const splitHours = workingHours.toString().split(":");
          const [hours = "00", minutes = "00"] = splitHours;

          totalHours += parseInt(hours.toString(), 10);
          totalMinutes += parseInt(minutes.toString(), 10);
          if (totalMinutes >= 60) {
            totalHours += ~~(totalMinutes / 60);
            totalMinutes = totalMinutes % 60;
          }
          taskinfo["hours"] = hours;
          taskinfo["minutes"] = minutes;
        });
        project["tasks"] = subitem.task;
        subinfo["comments"] = subitem.comments;
        let finalInfo = { ...project, ...subinfo, ...taskinfo };
        console.log("finalInfo", finalInfo);
        const key = subitem?.workedOn;

        if (recordInfo[key]) {
          recordInfo[key]["halfDay"] = subitem?.halfDay;
          recordInfo[key]["isHoliday"] = subitem?.holiday;
          recordInfo[key]["holidayName"] = subitem?.holidayName;
          recordInfo[key]["isLeaveApplied"] = subitem?.leaveApplied;
          recordInfo[key]["projectInfo"].push(finalInfo);
        } else {
          recordInfo[key] = {};
          recordInfo[key]["halfDay"] = subitem?.halfDay;
          recordInfo[key]["isHoliday"] = subitem?.holiday;
          recordInfo[key]["holidayName"] = subitem?.holidayName;
          recordInfo[key]["isLeaveApplied"] = subitem?.leaveApplied;
          recordInfo[key]["projectInfo"] = [finalInfo];
        }
      });
      weekendProjectsList.push(projectInfo);
    });
    const finalPayload = {
      ...payload,
      weekendProjectsList,
      recordInfo,
      weekStartDate: singleRecord?.weekStartDate || weekStartDate,
      weekEndDate: singleRecord?.weekEndDate || weekEndDate,
      displayValue: singleRecord?.displayValue || displayValue,
      totalTime: {
        hours: totalHours,
        minutes: totalMinutes,
      },
    };
    return finalPayload;
  };

  const handlePreviewOpen = () => {
    if (!isDisabled) {
      const payloadRequest = preview();
      dispatch(payloadAction({ payload: payloadRequest }));
      dispatch(previewTimesheet({ payloadRequest }))
        .unwrap()
        .then(() => {
          setDisplayPreview(true);
        });
    }
  };

  const saveHandler = () => {
    const payloadRequest = preview();
    dispatch(saveTimesheet({ payload: payloadRequest }))
      .unwrap()
      .then(() => {
        navigate(`/${START}/${MY_SPACE}/${TIMESHEET_BREADCRUMB}`);
      });
  };

  const handlePreviewClose = () => {
    setDisplayPreview(false);
  };

  const initialValues = { projects: timesheets || [] };
  console.log("initialValues", initialValues);

  useEffect(() => {
    tableForm.setFieldValue("projects", timesheets || []);
  }, [timesheets]);

  const okHandler = () => {
    const comment = commentForm.getFieldValue("comment");
    const { type, index, info, parentIndex } = commentInfo;
    const updatedTimesheet = tableForm.getFieldValue("projects");
    if (type === "daily") {
      dispatch(
        timesheetUpdateRowAction({
          value: comment,
          index: index,
          type: "comments",
          parentindex: parentIndex,
          timesheets: updatedTimesheet,
          originalTimesheets,
        })
      );
    } else {
      dispatch(
        updateWeekendStatus({
          projectIndex: parentIndex,
          value: comment,
          type: "overallComments",
          timesheets: updatedTimesheet,
        })
      );
    }
    setOpen(false);
  };

  const deleteHandler = () => {
    const comment = commentForm.getFieldValue("comment");
    const { type, index, info, parentIndex } = commentInfo;
    const updatedTimesheet = tableForm.getFieldValue("projects");
    if (type === "daily") {
      dispatch(
        timesheetUpdateRowAction({
          value: "",
          index: index,
          type: "comments",
          parentindex: parentIndex,
          timesheets: updatedTimesheet,
          originalTimesheets,
        })
      );
    } else {
      dispatch(
        updateWeekendStatus({
          projectIndex: parentIndex,
          value: "",
          type: "overallComments",
          timesheets: updatedTimesheet,
        })
      );
    }
    setOpen(false);
  };

  const commentChangeHandler = (e) => {
    const comment = e?.target?.value;
    const isValidComment = comment ? false : true;
    if (commentInfo.iscommentDisable !== isValidComment)
      setCommentInfo({ ...commentInfo, iscommentDisable: isValidComment });
  };

  const clickOnAddRow = (recordIndex, projIndex, timesheets) => {
    dispatch(timesheetAddRowAction({ recordIndex, projIndex, timesheets }));
  };

  return (
    <>
      {contextHolder}
      <div className="main_container">
        <div className="hidestricky_breadcrump">
          <BreadcrumbComponent items={items} />
          <div className="Seperation-page-title">
            {isCloneTimesheetEnabled ? CLONE_TIMESHEET : CREATE_TIMESHEET_TITLE}
          </div>
        </div>
        <Spin spinning={loading}>
          <div className="create_data_list-otr" id="create_data_list-otr">
            {dropDownSuggestions.length > 0 && (
              <CreateDropDownOption
                shiftTypes={shiftTypes}
                dropDownSuggestions={dropDownSuggestions}
                projects={projects}
                weekHandler={weekHandler}
                shiftHandler={shiftHandler}
                timesheetId={timesheetId}
                displayWeek={timesheet?.details?.displayWeek}
                timesheetData={timesheet?.details}
                projectTitle={timesheet?.details?.projectTitle}
                isDisplayWeekDisabled={isCloneTimesheetEnabled}
              />
            )}
            <Form
              form={tableForm}
              onFinish={onSubmit}
              name="dynamic_form_item"
              initialValues={initialValues}
            >
              <div>
                {initialValues?.projects.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="table_form-otr"
                      id="table_form-otr"
                    >
                      <Form.List name={["projects", index, "recordsList"]}>
                        {(fields, { add, remove }) => {
                          return (
                            <div className="table_col-list" id="table_col-list">
                              <p
                                className="project_title-main"
                                id="project_title-main"
                              >{`${index + 1}. ${
                                timesheets
                                  ? timesheets[index]["projectName"]
                                  : ""
                              }`}</p>
                              {fields.map(({ key, name, ...restField }) => (
                                <Form.List name={[name, "task"]}>
                                  {(subFields, subOpt) => {
                                    return (
                                      <div>
                                        {subFields.map((subField) => {
                                          return (
                                            <TimesheetRowTable
                                              task={task}
                                              name={name}
                                              taskName={subField?.name}
                                              {...restField}
                                              parentIndex={index}
                                              timesheets={timesheets}
                                              isWeekendEnabled={
                                                timesheets
                                                  ? timesheets[index][
                                                      "enabledWeekend"
                                                    ]
                                                  : false
                                              }
                                              handleOpenModal={handleOpenModal}
                                              fieldUpdateHandler={
                                                fieldUpdateHandler
                                              }
                                              addTaskRow={() => {
                                                subOpt?.add();
                                                clickOnAddRow(
                                                  name,
                                                  index,
                                                  timesheets
                                                );
                                              }}
                                              deleteTaskRow={() => {
                                                subOpt?.remove(subField?.name);
                                                fieldUpdateHandler({
                                                  type: "task_delete",
                                                  value: "",
                                                  index: subField?.name,
                                                  parentindex: null,
                                                  recordListIndex: name,
                                                });
                                              }}
                                            />
                                          );
                                        })}
                                      </div>
                                    );
                                  }}
                                </Form.List>
                              ))}
                            </div>
                          );
                        }}
                      </Form.List>
                      <Checkbox
                        onChange={(e) => onChange(e, index)}
                        checked={
                          timesheets
                            ? timesheets[index]["enabledWeekend"]
                            : false
                        }
                      >
                        {ENABLE_WEEKEND}
                      </Checkbox>
                      {isSubmit && (
                        <div>
                          <div className="overall_cmd-otr" id="overall_cmd-otr">
                            <div
                              className={
                                isLeastHours
                                  ? "total_hr-otr"
                                  : "total_hr-otr less"
                              }
                              id="total_hr-otr"
                            >
                              <p id="total-hr-text">{TOTAL_WORK_HOUR_text}</p>
                              <span id="total-hr-count">
                                {timesheets
                                  ? (() => {
                                      let total_hrs =
                                        timesheets[index]["totalHours"];
                                      if (total_hrs) {
                                        let [hours, minutes] =
                                          total_hrs.split(":");
                                        minutes = minutes.padStart(2, "0");
                                        return `${hours}:${minutes}`;
                                      }
                                      return "";
                                    })()
                                  : ""}{" "}
                                hrs
                              </span>
                            </div>
                            <div className="add-cmd-otr">
                              <div className="overall_btn-otr">
                                <a
                                  id="overall_cmd"
                                  href="#"
                                  onClick={() =>
                                    handleOpenModal({
                                      parentIndex: index,
                                      type: "overAllComment",
                                      index: "",
                                      info: isFilledComment({
                                        index: "",
                                        parentindex: index,
                                        type: "overallComments",
                                        timesheets,
                                      }),
                                      projectName: timesheets
                                        ? timesheets[index]["projectName"]
                                        : "",
                                      weekSelection: displayWeek,
                                    })
                                  }
                                >
                                  <img
                                    src={
                                      isFilledComment({
                                        index: "",
                                        parentindex: index,
                                        type: "overallComments",
                                        timesheets,
                                      })
                                        ? Filled_comment
                                        : Chart_Icon_Comment
                                    }
                                  />
                                  {OVERALL_COMMENT}
                                </a>
                              </div>
                              <Form
                                form={commentForm}
                                onFinish={onSubmit}
                                name="comment_form_item"
                              >
                                <Modal
                                  className={`${
                                    commentInfo.iscommentDisable
                                      ? "cmd_time_model"
                                      : "cmd_time_model cmd_time_model_valid"
                                  }`}
                                  open={open}
                                  title={`${
                                    commentInfo.type === "daily"
                                      ? TimeSheetStrings?.DAILY_COMMENT
                                      : TimeSheetStrings?.OVERALL_COMMENT
                                  }`}
                                  okText="Add"
                                  cancelText={
                                    !commentInfo.iscommentDisable
                                      ? "Delete Comment"
                                      : ""
                                  }
                                  okButtonProps={{
                                    autoFocus: true,
                                    htmlType: "submit",
                                  }}
                                  onCancel={() =>
                                    !commentInfo.iscommentDisable
                                      ? deleteHandler()
                                      : setOpen(false)
                                  }
                                  onOk={() =>
                                    !commentInfo.iscommentDisable
                                      ? okHandler()
                                      : null
                                  }
                                  destroyOnClose
                                >
                                  <div
                                    className="project-title-otr"
                                    id="project-title-otr"
                                  >
                                    <span id="project-title_date">
                                      <img src={Time_Calender} /> {PROJECT_DATE}{" "}
                                      {commentInfo?.weekSelection}
                                    </span>
                                    <span id="project-title_text">
                                      <img src={Office_Briefcase} />{" "}
                                      {PROJECT_TITLE} {commentInfo?.projectName}
                                    </span>
                                  </div>
                                  <Form.Item name="comment" label="Comment">
                                    <Input.TextArea
                                      placeholder="Enter Here"
                                      onChange={commentChangeHandler}
                                    />
                                  </Form.Item>
                                </Modal>
                              </Form>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <CreateTimeSheetFooter
                defaultHoursAndMinutes={defaultHoursAndMinutes}
                previewPop={handlePreviewOpen}
                isDisabled={isDisabled}
                saveHandler={saveHandler}
                hideDraftBtn={hideDraftBtn}
                isLeastHours={isLeastHours}
              />
            </Form>
          </div>
        </Spin>
      </div>
      {displayPreview && (
        <PreviewSheet
          openPreview={displayPreview}
          closePreview={handlePreviewClose}
          pageName={CREATE_TIMESHEET_TITLE}
        />
      )}
    </>
  );
};

export default CreateTimesheet;
