import React, { useEffect, useState } from "react";
import "./index.css";
import {
  DOCUMENT_UPLOAD,
  FAILURE,
  FILE_NOTSUPPORTED_ERROR_MESSAGE,
  FILE_THREE_MB,
  IJP_FILE_NOTSUPPORTED_ERROR_MESSAGE,
  IJP_FILE_SIZE_ERROR_MESSAGE,
  NOTIFICATION_DURATION,
  NOTIFICATION_DURATION_COUNT,
  NOTIFICATION_POSITION,
  OPPORTUNITIES,
  OPPORTUNITIES_CERTIFICATE_WARNING_TEXT,
  OPPORTUNITIES_RESUME_WARNING_TEXT,
  RESUME_FILE_SUPPORTED_FORMATS,
  RESUME_SIZE_ERROR_MESSAGE,
  RESUME_SUPPORTED_FILE,
  RESUME_UPLOAD_SUBTITLE,
  RESUME_UPLOAD_TITLE,
  SAMPLE_RESUME,
  SAMPLE_RESUME_DESCRIPTION,
  SUCCESS,
  SUCCESS_SUB_TITLE,
  SUCCESS_TITLE,
  THOUSAND_TWENTY_FOUR,
  UPLOAD_BUTTON,
  UPLOAD_FAILED_TITLE,
  UPLOADING_RESUME,
  WARNING,
} from "../../../constants";
import { Link, useNavigate } from "react-router-dom";
import Download from "../../../themes/assets/images/svg/Download.svg";
import Delete from "../../../themes/assets/images/svg/Close-black.svg";
import PDF from "../../../themes/assets/images/svg/PDF.svg";
import ExportDOC from "../../../themes/assets/images/svg/ExportDOC.svg";
import FileArrowUp from "../../../themes/assets/images/svg/FileArrowUp.svg";
import { Alert, Spin, Upload as FileUpload, notification } from "antd";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { getResume, uploadResume } from "../../../redux/actions/opportunities";
import {
  AlertInfo,
  GoalsErrorIcon,
  GoalsInfoIcon,
  ToastErrorIcon,
  ToastSuccessIcon,
} from "../../common/svg/svg";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import n from "../../navigation/Route.Names";
import FixedFooter from "../../common/FixedFooterButtons/FixedFooter";
import {
  downloadAction,
  notificationAction,
} from "../../../redux/reducers/opportunities";
import { fileSize, isFileType } from "../../utils/util";
import amplitude from "amplitude-js";

const Upload = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification({
    top: NOTIFICATION_POSITION,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [fileOption, setFileOption] = useState<any>();
  const { loading, error, jobsData, configuration, fileInfo } = useAppSelector(
    (state: any) => state?.opportunities
  );

  useEffect(() => {
    dispatch(getResume({}));
  }, []);

  const onCloseHandler = () => {
    setErrorMessage("");
    dispatch(
      notificationAction({
        type: WARNING,
        message: "",
        isShowNotification: false,
        additionalInfo: "",
      })
    );
  };

  useEffect(() => {
    if (configuration.isShowNotification) {
      const type = configuration.alertMessage.type;
      api[type]({
        message: configuration.alertMessage.message,
        description: configuration.alertMessage.additionalInfo,
        placement: "topRight",
        duration: NOTIFICATION_DURATION,
        icon:
          type === SUCCESS ? (
            <ToastSuccessIcon />
          ) : type === FAILURE ? (
            <GoalsErrorIcon />
          ) : (
            <GoalsInfoIcon />
          ),
        className:
          type === SUCCESS
            ? `custom-toast-area custom-success-toast`
            : type === FAILURE
              ? `custom-toast-area custom-error-toast`
              : `custom-toast-area custom-info-toast`,

        onClose: onCloseHandler,
      });
    } else if (errorMessage) {
      api.open({
        message: UPLOAD_FAILED_TITLE,
        description: errorMessage,
        duration: NOTIFICATION_DURATION_COUNT,
        className: "custom-toast-area custom-error-toast",
        icon: <ToastErrorIcon />,
        onClose: onCloseHandler,
      });
    }
  }, [configuration.isShowNotification, errorMessage]);

  const beforeUploadHandler = (file: any) => {
    const isValidFormat = RESUME_SUPPORTED_FILE.indexOf(file.type) > -1;
    const isGt3MB =
      file.size / THOUSAND_TWENTY_FOUR / THOUSAND_TWENTY_FOUR > FILE_THREE_MB;
    setErrorMessage("");
    if (!isValidFormat) {
      setErrorMessage(IJP_FILE_NOTSUPPORTED_ERROR_MESSAGE);
      return false;
    } else if (isGt3MB) {
      setErrorMessage(IJP_FILE_SIZE_ERROR_MESSAGE);
      return false;
    }
    amplitude.getInstance().logEvent("Clicked Add File");
    return true;
  };

  const onCustomRequestHandler = async (options: any) => {
    const { file } = options;
    setFileOption(file);
  };

  const removeHandler = () => {
    setFileOption("");
  };

  const downloadHandler = () => {
    dispatch(downloadAction({}));
    amplitude.getInstance().logEvent("Clicked Download Sample Resume");
  };

  const breadbrumbItems = [
    {
      title: (
        <Link
          to={`/${n.START}/${n.MYWORK_URL}/opportunities`}
          className="link-highlight"
        >
          {OPPORTUNITIES}
        </Link>
      ),
    },
    {
      title: `${UPLOADING_RESUME}`,
    },
  ];

  const FileCard = ({ filename, size, isDownload, isRemove, isDoc }) => (
    <div className="download-section">
      <div className="file-detail-section">
        <img src={isDoc ? ExportDOC : PDF} />
        <div>
          <p>{filename}</p>
          <p>{size}</p>
        </div>
      </div>
      {isDownload && (
        <img src={Download} data-testid="download" onClick={downloadHandler} />
      )}
      {isRemove && (
        <img src={Delete} data-testid="close-black" onClick={removeHandler} />
      )}
    </div>
  );

  const uploadHandler = () => {
    const formData = new FormData();
    formData.set("file", fileOption);
    dispatch(uploadResume({ request: formData }))
      ?.unwrap()
      ?.then(() => {
        amplitude.getInstance().logEvent("Submitted Resume");
        navigate("/web/my_work/opportunities");
      });
  };

  return (
    <>
      {contextHolder}
      <div className="opportunities-page">
        <div className="opportunities-breadcrumb">
          <BreadcrumbComponent items={breadbrumbItems} />
        </div>
        <div className="opportunities-title">{UPLOADING_RESUME}</div>
        <Spin spinning={loading}>
          <div className="resume-list">
            <div className="resume-card">
              <h4 className="download-title">{SAMPLE_RESUME}</h4>
              <Alert
                message={SAMPLE_RESUME_DESCRIPTION}
                type="info"
                showIcon
                icon={<AlertInfo />}
              />
              {fileInfo && (
                <FileCard
                  filename={fileInfo?.fileName}
                  size={fileInfo?.fileSize}
                  isDownload={true}
                  isRemove={false}
                  isDoc={true}
                />
              )}
            </div>
            <div className="resume-card">
              <h4 className="upload-title">{DOCUMENT_UPLOAD}</h4>
              <div className="upload-section">
                {fileOption ? (
                  <div className="attachment-card">
                    <FileCard
                      filename={fileOption?.name}
                      size={fileSize({ size: fileOption?.size })}
                      isDownload={false}
                      isRemove={true}
                      isDoc={isFileType({
                        filename: fileOption?.name,
                        type: "doc",
                      })}
                    />
                  </div>
                ) : (
                  <>
                    <FileUpload
                      listType="picture-card"
                      beforeUpload={(file) => beforeUploadHandler(file)}
                      maxCount={1}
                      accept={RESUME_FILE_SUPPORTED_FORMATS}
                      showUploadList={false}
                      customRequest={(file) => onCustomRequestHandler(file)}
                      data-testid="upload-file"
                    >
                      <div className="file-upload-section">
                        <img src={FileArrowUp} />
                        <div>
                          <p>{RESUME_UPLOAD_TITLE}</p>
                          <p>{RESUME_UPLOAD_SUBTITLE}</p>
                        </div>
                      </div>
                    </FileUpload>
                    <div className="file-type-warning">
                      {OPPORTUNITIES_RESUME_WARNING_TEXT}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <FixedFooter
            fillButtonText={UPLOAD_BUTTON}
            disabled={fileOption && !loading ? false : true}
            isOutline={false}
            fillButtonIsHtmlType={false}
            fillBtnAction={uploadHandler}
            fillBtnStyles={fileOption && !loading ? "upload_btn enabled_btn" : "upload_btn"}
            fillBtnId="upload-btn"
          />
        </Spin>
      </div>
    </>
  );
};

export default Upload;
