import { Skeleton } from "antd";
import { useState } from "react";

const SkeletonImage = (props) => {
    const { featureMySpaceItem, className = '' } = props;
    const [isLoaded, setIsLoaded] = useState(true);

    const handleImageLoad = () => {
        setIsLoaded(true);
    };

    const handleImageError = () => {
        setIsLoaded(false);
    };

    return (
        <>
            {!isLoaded ? (
                <Skeleton.Image active className={`${className ? className : 'skelton_image'}`} />
            ) :
                (<img
                    src={featureMySpaceItem.menuImg}
                    alt="Feature"
                    onLoad={handleImageLoad}
                    onError={handleImageError}
                    className={`${className ? className : 'skelton_image'}`}
                />)}
        </>
    );
};

export default SkeletonImage;