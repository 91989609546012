import "./index.css";
import { FC } from "react";
import warningIcon from "../../../themes/assets/images/svg/leave_management/WarningIcon.svg";

interface ErrorComponentProps {
  errorTitle: string;
  errorDescription: string;
}

const LeaveRequestErrorComponent: FC<ErrorComponentProps> = ({
  errorTitle,
  errorDescription,
}) => {
  return (
    <div
      id="leave-request-error-container"
      className="leave-request-error-container"
    >
      <img src={warningIcon} alt="Warning" />
      <div className="leave-request-error-message-container">
        <p id="leave-request-error-title" className="leave-request-error-title">{errorTitle}</p>
        <p id="leave-request-error-desc" className="leave-request-error-desc">{errorDescription}</p>
      </div>
    </div>
  );
};

export default LeaveRequestErrorComponent;
