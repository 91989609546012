import {
  Form,
  Button,
  Table,
  Select,
  TimePicker,
  Typography,
  Dropdown,
} from "antd";
import type { MenuProps } from "antd";
import dayjs from "dayjs";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/store/redux-hooks";
import { timesheetUpdateRowAction } from "../../../../redux/reducers/timesheet";
import { isFilledComment } from "../../../utils/util";
import "./CreateTimeSheet.css";
import ThreeDots from "../../../../themes/assets/images/svg/DotsThreeVertical.svg";
const { Text } = Typography;
const { Column } = Table;

export const TimesheetRowTable = (props) => {
  const {
    isWeekendEnabled,
    parentIndex,
    name,
    task = [],
    handleOpenModal,
    fieldUpdateHandler,
    taskName,
  } = props;
  const timesheet = useAppSelector((state: any) => state.timesheet);
  const dispatch = useAppDispatch();
  const {
    status = [],
    timeSheetInfo = {},
    holidayDetails,
    myActivities,
    timesheets,
    day1,
    day2,
    day3,
    day4,
    day5,
    day6,
    day7,
    totalHours,
  } = timesheet?.details;

  const items: MenuProps["items"] =
    taskName === 0
      ? [
          {
            label: (
              <div
                onClick={() =>
                  handleOpenModal({
                    parentIndex: parentIndex,
                    type: "daily",
                    index: name,
                    info: isFilledComment({
                      index: name,
                      parentindex: parentIndex,
                      type: "comments",
                      timesheets,
                    }),
                    projectName: timesheets
                      ? timesheets[parentIndex]["projectName"]
                      : "",
                    weekSelection: timesheets
                      ? timesheets[parentIndex]["recordsList"][name]
                          ?.weekSelection
                      : "",
                  })
                }
              >
                Add Comment
              </div>
            ),
            key: 1,
          },
          {
            label: <div onClick={() => props?.addTaskRow()}>Add Task</div>,
            key: 2,
          },
        ]
      : [
          {
            label: (
              <div onClick={() => props?.deleteTaskRow()}>Delete Task</div>
            ),
            key: 1,
          },
        ];

  const updateHandler = ({ type, formatTime, timeindex, recordListIndex }) => {
    fieldUpdateHandler({
      type: type,
      value: formatTime,
      index: timeindex,
      parentindex: parentIndex,
      recordListIndex: recordListIndex,
    });
  };
  const isOne =
    name === 5 || name === 6 ? (isWeekendEnabled ? false : true) : false;
  return !isOne ? (
    <div
      className={
        taskName === 0
          ? "timesheet_row-main"
          : "timesheet_row-main timesheet_row-sub"
      }
      id="timesheet_row-main"
    >
      {/* <div className="timesheet-row"> */}
      {taskName === 0 ? (
        <p>
          {timesheets
            ? timesheets[parentIndex]["recordsList"][name]?.dayOfWeek
            : ""}
          {timesheets[parentIndex]["recordsList"][name]?.leaveApplied ||
          timesheets[parentIndex]["recordsList"][name]?.halfDay ? (
            <div className="leave-holiday-text">
              {timesheets[parentIndex]["recordsList"][name]?.leaveLabel}
            </div>
          ) : null}
          {timesheets[parentIndex]["recordsList"][name]?.holiday ? (
            <div className="leave-holiday-text">
              {timesheets[parentIndex]["recordsList"][name]?.holidayName}
            </div>
          ) : null}
        </p>
      ) : (
        <p></p>
      )}

      {/* {timesheets[parentIndex]["recordsList"][name]?.task?.map((item1, idx) => {
        return ( */}
      <Form.Item name={[taskName, "task"]}>
        <Select
          onChange={(value) => {
            fieldUpdateHandler({
              type: "task",
              value,
              index: taskName,
              parentindex: parentIndex,
              recordListIndex: name,
            });
          }}
        >
          {task.map((item) => (
            <Select.Option value={item}>{item}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* //   );
      // })} */}

      <Form.Item name={[taskName, "working_hours"]}>
        <TimePicker
          format="HH:mm"
          allowClear={false}
          open={false}
          suffixIcon
          onChange={(time) => {
            updateHandler({
              type: "working_hours",
              formatTime: time,
              timeindex: taskName,
              recordListIndex: name,
            });
          }}
          onBlur={(event: any) => {
            const input = event?.target?.value || "00:00";
            let hours = 0;
            let minutes = 0;

            const cleanedInput = input.replace(/[^0-9]/g, "");

            if (cleanedInput.length === 1) {
              hours = parseInt(cleanedInput, 10);
            } else if (cleanedInput.length === 2) {
              const totalMinutes = parseInt(cleanedInput, 10);
              hours = Math.floor(totalMinutes / 60);
              minutes = totalMinutes % 60;
            } else if (cleanedInput.length >= 3) {
              hours = parseInt(cleanedInput.slice(0, -2), 10);
              minutes = parseInt(cleanedInput.slice(-2), 10);
            }

            hours = Math.max(0, Math.min(23, hours));
            minutes = Math.max(0, Math.min(59, minutes));

            const formattedTime = dayjs().hour(hours).minute(minutes);

            if (formattedTime.isValid()) {
              updateHandler({
                type: "working_hours",
                formatTime: formattedTime,
                timeindex: taskName,
                recordListIndex: name,
              });
            }
          }}
        />
      </Form.Item>

      <span className="timer-cmd-outline" id="timer-cmd-outline">
        {taskName > 0 ? (
          <Dropdown
            menu={{ items }}
            // overlay={() => renderActionsMenu(record)}
            trigger={["click"]}
          >
            <span className="timesheet_icon_span">
              <img src={ThreeDots} className="timesheet_action_icon" alt="" />
            </span>
          </Dropdown>
        ) : (
          <Dropdown
            menu={{ items }}
            // overlay={() => renderActionsMenu(record)}
            trigger={["click"]}
          >
            <span className="timesheet_icon_span">
              <img src={ThreeDots} className="timesheet_action_icon" alt="" />
            </span>
          </Dropdown>
        )}
      </span>
    </div>
  ) : (
    // </div>
    <></>
  );
};
