import React, { useEffect, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import BreadcrumbComponent from "../../common/breadcrumb/breadcrumb";
import {
  CONTINUE,
  FEEDBACK_TEXT,
  IJP_REJECTED_MESSAGE,
  IJP_SELECTED_MESSAGE,
  JOB_DETAILS,
  JOB_ID,
  OPPORTUNITIES,
  PRIMARY_SKILLS_TITLE,
  SECONDARY_SKILLS_TITLE,
} from "../../../constants";
import { InfoCard } from "..";
import Location from "../../../themes/assets/images/Location.png";
import Job from "../../../themes/assets/images/Job.png";
import Time from "../../../themes/assets/images/Time.png";
import Exp from "../../../themes/assets/images/exp-icon.png";
import SelectIJP from "../../../themes/assets/images/svg/Select.svg";
import RejectIJP from "../../../themes/assets/images/svg/Reject.svg";
import { Alert, Button, notification, Spin } from "antd";
import { ToastErrorIcon, ToastSuccessIcon } from "../../common/svg/svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { getJobDetail } from "../../../redux/actions/opportunities";
import { getTimeAgo, isJobStatusAvailable, jobStatus } from "../../utils/util";
import { PreviewModal } from "../components";
import amplitude from "amplitude-js";

const DataCard = ({ title, desc }) => {
  return (
    <div>
      <div className="data-title">{title}</div>
      <div className="data-info" dangerouslySetInnerHTML={{ __html: desc }} />
    </div>
  );
};

const StickyFooter = ({ loading, onClickApply }) => {
  const navigate = useNavigate();
  return (
    <div className="footer-opportunity">
      <Button
        disabled={loading}
        type="primary"
        className="footer-btn-opportunity"
        onClick={() => onClickApply()}
      >
        Apply
      </Button>
    </div>
  );
};

const OpportunitiesDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { activeJobData, loading, jobsDetailData } = useAppSelector(
    (state: any) => state?.opportunities
  );
  const [popup, setPopUp] = useState(true);
  const { job_status = "", jobType } = activeJobData;
  const converted_status: any = job_status ? jobStatus(job_status) : "";
  const alertInfo = jobsDetailData?.Apply_Status_Warn || {};
  const isAlertInfo = jobsDetailData?.Apply_Status_Warn;
  const showApplyBtn = jobsDetailData?.showApplyBtn;
  const {
    message = "",
    bgColor = "",
    textColor = "",
    iconUrl = "",
  } = alertInfo;
  useEffect(() => {
    if (!activeJobData?.req_id) {
      navigate("/web/my_work/opportunities");
    }
  }, []);

  const [api, contextHolder] = notification.useNotification();

  const openSuccessNotification = () => {
    api.success({
      message: "Notification Title",
      description:
        "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      placement: "topRight",
      className: "custom-toast-area custom-success-toast",
      icon: <ToastSuccessIcon />,
    });
  };

  const openErrorNotification = () => {
    api.open({
      message: `Notification Message`,
      description: "hello",
      placement: "topRight",
      className: "custom-toast-area custom-error-toast",
      icon: <ToastErrorIcon />,
    });
  };

  function combineArrays(array1 = [], array2 = []) {
    if (array1?.length <= 0 || array2?.length <= 0) return;
    const combinedArray = [...array1, ...array2];
    const uniqueValues = new Set(combinedArray);
    return Array.from(uniqueValues).join(", ");
  }

  const onClickApply = () => {
    amplitude.getInstance().logEvent("Clicked Apply Job");
    navigate(
      `/web/my_work/opportunities/detail/application/${activeJobData?.req_id}`
    );
  };

  useEffect(() => {
    activeJobData?.req_id &&
      dispatch(
        getJobDetail({
          req_id: activeJobData?.req_id,
          job_title: activeJobData?.job_title,
          account: activeJobData?.account || "",
          experience_band: activeJobData?.experience_band,
          additional_field: activeJobData?.additional_field || "",
          primary_skills: activeJobData?.primary_skills || [],
          secondary_skills: activeJobData?.secondary_skills || [],
          jobType: jobType,
        })
      );
  }, []);

  const items = [
    {
      title: (
        <Link to={`/web/my_work/opportunities`} className="link-highlight">
          {OPPORTUNITIES}
        </Link>
      ),
    },
    {
      title: JOB_DETAILS,
    },
  ];

  const AlertComponent = ({ iconUrl }) => {
    return <img src={iconUrl} />;
  };
  const onClickContinue = (converted_status) => {
    if (converted_status === "rejected")
      localStorage.setItem(`rejected${activeJobData?.req_id}`, "true");
    setPopUp(false);
  };

  const { icon_infos = [] } = activeJobData;

  return (
    <>
      {contextHolder}
      <div className="opportunity-detail-page">
        <div className="opportunity-detail">
          <BreadcrumbComponent items={items} />
          {loading ? (
            <Spin spinning={loading}>
              <div className="job-additional-info spinner-opportunity" />
            </Spin>
          ) : (
            <>
              <div className="opportunities-title space-top">{JOB_DETAILS}</div>
              <div className="job-banner-section">
                <div className="job-banner-img">
                  <img src={jobsDetailData?.Job_Detail_Banner_Url} />
                </div>
                <div className="company-logo-img">
                  <img src={activeJobData?.account_icon_url} />
                </div>
              </div>
              <div className="job-main-info">
                <div className="job-title-info">
                  <div className="job-title-data">
                    {`${jobsDetailData?.Job_Title}`}
                  </div>
                  <div className="job-desc-data">
                    {`${
                      activeJobData?.capability_centre
                        ? activeJobData?.capability_centre
                        : ""
                    } | ${JOB_ID}: ${activeJobData?.req_id}`}
                  </div>
                </div>
                <div className="info-card-holder">
                  {icon_infos?.map((info, index) => (
                    <InfoCard
                      key={index}
                      icon={info.icon_url}
                      title={info?.label}
                    />
                  ))}
                  {/* <InfoCard
                    bgColor={"#fff"}
                    icon={Location}
                    title={activeJobData?.job_location}
                  />
                  <InfoCard
                    bgColor={"#fff"}
                    icon={Job}
                    title={activeJobData?.account}
                  />

                  <InfoCard
                    bgColor={"#fff"}
                    icon={Exp}
                    title={`${activeJobData?.experience_band} Years`}
                  /> */}
                </div>
              </div>
              {isAlertInfo && (
                <div className="job-status-alert-area">
                  <Alert
                    message={message}
                    type="info"
                    showIcon
                    icon={<AlertComponent iconUrl={iconUrl} />}
                    style={{
                      backgroundColor: bgColor,
                      color: textColor,
                      borderColor: bgColor,
                    }}
                    //className="info-message"
                  />
                </div>
              )}
              {jobsDetailData?.feedbackSummary && (
                <div className="job-additional-info">
                  <DataCard
                    title={FEEDBACK_TEXT}
                    desc={jobsDetailData?.feedbackSummary}
                  />
                </div>
              )}
              <div className="job-additional-info">
                {job_status && (
                  <div className={`${jobStatus(job_status)} job-status`}>
                    {job_status}
                  </div>
                )}

                <DataCard
                  title={PRIMARY_SKILLS_TITLE}
                  desc={activeJobData?.primary_skills}
                />
                <DataCard
                  title={SECONDARY_SKILLS_TITLE}
                  desc={activeJobData?.secondary_skills}
                />
                <DataCard
                  title={"Job Overview"}
                  desc={jobsDetailData?.Job_Summary}
                />
                <DataCard
                  title={"Roles & Responsibilities"}
                  desc={jobsDetailData?.Roles_and_Responsibilities}
                />
                <DataCard
                  title={"Education and Experience Required"}
                  desc={jobsDetailData?.Qualitification_and_Skills}
                />
                <DataCard
                  title={"Competencies"}
                  desc={jobsDetailData?.Preferred_Qualification}
                />
              </div>
            </>
          )}
        </div>
        {!job_status && showApplyBtn && (
          <StickyFooter loading={loading} onClickApply={onClickApply} />
        )}
      </div>
      {((converted_status === "selected" && jobsDetailData?.intervalPopup) ||
        (converted_status === "rejected" &&
          localStorage?.getItem(`rejected${activeJobData?.req_id}`) !==
            "true")) && (
        <PreviewModal
          isOpen={isJobStatusAvailable(converted_status) && popup}
          img={converted_status === "selected" ? SelectIJP : ""}
          title={
            converted_status === "selected"
              ? IJP_SELECTED_MESSAGE
              : IJP_REJECTED_MESSAGE
          }
          pointSection={jobsDetailData?.intervalPopup ? true : false}
          points={
            jobsDetailData?.points > 0
              ? "+" + jobsDetailData?.points + "pts"
              : jobsDetailData?.points
          }
          //subtitle={converted_status ? "Your job application has been successfully submitted!" : ""}
          nextBtnText={CONTINUE}
          nextBtnOnClick={() => onClickContinue(converted_status)}
          customClass={converted_status === "selected" ? "detail-ijp" : ""}
        />
      )}
    </>
  );
};

export default OpportunitiesDetail;
