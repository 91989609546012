import { createSlice } from "@reduxjs/toolkit";
import { DRAFT, FAILURE, SUCCESS } from "../../constants";
import {
  extractHourMinitue,
  timeSheetCalculation,
  timeSheetFormat,
  timeSheetHourCalculation,
  timeSheetNewRowFormat,
  timeSheetProjectFormat,
  timeSheetUpdateRowFormat,
} from "../../components/utils/util";
import {
  getDetailTimeSheet,
  getLandingTimeSheet,
  getMyActivities,
  postCreateTimesheet,
  getManagerTimesheet,
  getGroupByWeek,
  getPreviewManager,
  previewTimesheet,
  putApproveManager,
  putRejectManager,
  getSearchManager,
  getManagerLanding,
  saveTimesheet,
  deleteMyActivities,
  cleareApproveTimesheetData,
  cleareRejectTimesheetData,
  putBulkApproval,
  clearBulkApproveTimesheet,
  getDownloadTimesheet,
  postExportTimesheet,
  sendReminderNotification,
  clearManagerLandingScreenData,
  getCloneTimeSheet,
  getDownloadManagerTimesheet,
  postExportManagerTimesheet,
  getAdminExportData,
  postAdminExportData,
  postEmployeeTimesheetData,
  getAdminFinanceLanding,
  adminPersonaEmployeePreview,
  adminPersonaEmployeeTimesheets,
  adminPersonaDownloadData,
  revertTimesheetAdmin,
  approveTimesheetAdmin,
} from "../actions/timesheet";
import dayjs from "dayjs";

type timesheetDetailState = {
  details: any;
  configuration: any;
  myActivities: any;
  postCreateTimesheet: any;
  managerTimesheet: any;
  groupByWeekTsm: any;
  previewSelected: any;
  approvalMananger: any;
  rejectManager: any;
  searchManager: any;
  managerLanding: any;
  bulkApproval: any;
  adminFinanceLanding: any;
  loadingTimesheet: boolean;
  loading: boolean;
  loadingActivities: boolean;
  managerLoading: boolean;
  loadingGroupByWeek: boolean;
  loadingPreview: boolean;
  loadingApproval: boolean;
  loadingRejection: boolean;
  loadingSearch: boolean;
  loadingLanding: boolean;
  loadingBulkApproval: boolean;
  loadingAdminFinanceLanding: boolean;
  loadingLandingDownloadExport: boolean;
  loadingLandingDownloadExportPost: boolean;
  error: null;
  errorActivities: boolean;
  errorTimesheet: boolean;
  errorManager: boolean;
  errorGroupByWeek: boolean;
  errorPreview: boolean;
  errorApproval: boolean;
  errorRejection: boolean;
  errorSearch: boolean;
  errorLanding: boolean;
  errorBulkApproval: boolean;
  errorAdminFinanceLanding: boolean;
  errorPostLanding: boolean;
  sendReminder: any;
  loadingSendReminder: boolean;
  cloneTimesheetDetails: any;
  isCloneTimesheetEnabled: boolean;
  cloneSelectedWeek: any;
  disableCloneTimesheetBtn: boolean;
  cloneTimesheetDetailsSubHeaderMsg: string;
  adminEmployeePreviewData: any;
  adminEmployeePreviewDataLoading: boolean;
  adminPersonaEmployeeTimesheetsData: any;
  adminPersonaEmployeeTimesheetsLoading: boolean;
  adminPersonaDownloadDataLoading: boolean;
  errorDownloadLanding: boolean;
  errorLandingDownload: boolean;
  loadingLandingDownload: boolean;
  errorLandingPagePost: boolean;
  loadingLandingPagePost: boolean;
  selectedWeekStart: any;
  selectedWeekEnd: any;
  revertTimesheetAdminLoading: boolean;
  revertTimesheetAdminData: any;
  originalTimesheets: any;
};

const initialDetailState: timesheetDetailState = {
  details: {},
  configuration: {
    isShowNotification: false,
    isModal: false,
    isEnabled: true,
    alertMessage: { type: "warning", message: "" },
    loading: false,
  },
  myActivities: [],
  postCreateTimesheet: [],
  managerTimesheet: [],
  groupByWeekTsm: {},
  previewSelected: {},
  approvalMananger: {},
  rejectManager: {},
  searchManager: {},
  managerLanding: {},
  bulkApproval: {},
  adminFinanceLanding: {},
  loadingTimesheet: false,
  loading: false,
  loadingActivities: false,
  managerLoading: false,
  loadingGroupByWeek: false,
  loadingPreview: false,
  loadingApproval: false,
  loadingRejection: false,
  loadingSearch: false,
  loadingLanding: false,
  loadingBulkApproval: false,
  loadingAdminFinanceLanding: false,
  loadingLandingDownloadExport: false,
  loadingLandingDownloadExportPost: false,
  error: null,
  errorActivities: false,
  errorTimesheet: false,
  errorManager: false,
  errorGroupByWeek: false,
  errorPreview: false,
  errorApproval: false,
  errorRejection: false,
  errorSearch: false,
  errorLanding: false,
  errorBulkApproval: false,
  errorPostLanding: false,
  errorAdminFinanceLanding: false,
  sendReminder: {},
  loadingSendReminder: false,
  cloneTimesheetDetails: [],
  isCloneTimesheetEnabled: false,
  cloneSelectedWeek: { cloneWeekStart: "", cloneWeekEnd: "" },
  disableCloneTimesheetBtn: false,
  cloneTimesheetDetailsSubHeaderMsg: "",
  adminEmployeePreviewData: {},
  adminEmployeePreviewDataLoading: false,
  adminPersonaEmployeeTimesheetsData: {},
  adminPersonaEmployeeTimesheetsLoading: false,
  adminPersonaDownloadDataLoading: false,
  errorDownloadLanding: false,
  errorLandingDownload: false,
  loadingLandingDownload: false,
  errorLandingPagePost: false,
  loadingLandingPagePost: false,
  selectedWeekEnd: "",
  selectedWeekStart: "",
  revertTimesheetAdminLoading: false,
  revertTimesheetAdminData: {},
  originalTimesheets: [],
};

export const detail: any = createSlice({
  name: "detail",
  initialState: initialDetailState,
  reducers: {
    notificationAction: (state, action) => {
      const { isShowNotification, message, type, additionalInfo } =
        action.payload;
      state.configuration.isShowNotification = isShowNotification;
      state.configuration.alertMessage = { type, message, additionalInfo };
    },
    payloadAction: (state, action) => {
      const { payload } = action.payload;
      state.details.payload = payload;
    },
    timesheetRemoveRowAction: (state, action) => {
      const { parentindex } = action.payload;
      const timeSheetList = state?.details?.timesheets || [];
      const listFilter = timeSheetList.filter(
        (item, index) => index !== parentindex
      );
      const {
        format_day1,
        format_day2,
        format_day3,
        format_day4,
        format_day5,
        format_day6,
        format_day7,
        totalHours,
        checkListFilter,
      } = timeSheetCalculation(listFilter);
      state.details.day1 = format_day1;
      state.details.day2 = format_day2;
      state.details.day3 = format_day3;
      state.details.day4 = format_day4;
      state.details.day5 = format_day5;
      state.details.day6 = format_day6;
      state.details.day7 = format_day7;
      state.details.totalHours = totalHours;
      state.details.timesheets = checkListFilter;
      //state.details.timesheets = listFilter
    },
    timesheetAddRowAction: (state, action) => {
      const { recordIndex, projIndex, timesheets } = action.payload;
      let emptyObj = {
        task: "Billed",
        hours: 0,
        minutes: 0,
        working_hours: dayjs("00:00", "HH:mm"),
      };
      let mainList = timesheets?.map((item, index) => {
        let recoList = item?.recordsList?.map((recItem, recIndex) => {
          let taskArr = [...recItem?.task];
          if (recordIndex === recIndex && projIndex === index) {
            taskArr?.push(emptyObj);
          }
          return { ...recItem, task: taskArr };
        });
        return { ...item, recordsList: recoList };
      });
      const { listFilterResponse, totalHours } =
        timeSheetHourCalculation(mainList);
      state.details.timesheets = listFilterResponse;
      const { hours, minutes } = extractHourMinitue(totalHours, ":");
      state.details.totalHours = { hours, minutes };
    },
    updateWeekendStatus: (state, action) => {
      const { projectIndex, type, value, timesheets } = action.payload;
      let listFilter = timesheets.map((item, rowindex) => {
        if (rowindex === projectIndex) {
          return { ...item, [type]: value };
        } else {
          return { ...item };
        }
      });

      if (type === "enabledWeekend") {
        const filterIndex = [5, 6];
        const finalFilter = listFilter.map((item, rowindex) => {
          if (projectIndex === rowindex) {
            const inner = item.recordsList.map((subitem, subrowindex) => {
              if (filterIndex.indexOf(subrowindex) > -1) {
                if (!value && subitem?.task?.length > 1) {
                  let tempVar = [subitem?.task[0]];
                  return { ...subitem, task: tempVar };
                }
                const taskInnArr = subitem?.task?.map((taskItem, taskIdx) => {
                  return {
                    ...taskItem,
                    hours: "00",
                    minutes: "00",
                    working_hours: dayjs("00:00", "HH:mm"),
                  };
                });
                return { ...subitem, task: taskInnArr };
              } else {
                return { ...subitem };
              }
            });
            return { ...item, recordsList: inner };
          } else {
            return { ...item };
          }
        });
        const { listFilterResponse, totalHours } =
          timeSheetHourCalculation(finalFilter);
        state.details.timesheets = listFilterResponse;
        const { hours, minutes } = extractHourMinitue(totalHours, ":");
        state.details.totalHours = { hours, minutes };
      } else {
        state.details.timesheets = listFilter;
      }
    },
    setTimesheetClone: (state, action) => {
      state.isCloneTimesheetEnabled = action.payload || false;
    },
    setSelectedWeekStart: (state, action) => {
      state.selectedWeekStart = action.payload || "";
    },
    setSelectedWeekEnd: (state, action) => {
      state.selectedWeekEnd = action.payload || "";
    },
    setCloneSelectedWeek: (state, action) => {
      state.cloneSelectedWeek = action.payload || {
        cloneWeekStart: "",
        cloneWeekEnd: "",
      };
    },
    setDisableCloneTimesheetBtn: (state, action) => {
      state.disableCloneTimesheetBtn = action.payload || false;
    },
    timesheetUpdateRowAction: (state, action) => {
      const {
        value,
        index,
        type,
        parentindex,
        timesheets,
        originalTimesheets = [],
        recordListIndex,
      } = action.payload;
      const listFilter = timesheets.map((item, rowindex) => {
        if (parentindex === rowindex) {
          const inner = item.recordsList.map((subitem, subrowindex) => {
            if (type === "task_delete") {
              subitem?.task?.splice(index, 1);
            }
            let updatedValue = value;

            const taskInner = subitem?.task?.map((taskItem, taskINdex) => {
              if (index === taskINdex && recordListIndex == subrowindex) {
                const workingHours = dayjs(value).format("HH:mm");
                const splitHours = workingHours.toString().split(":");
                const [hours = "00", minutes = "00"] = splitHours;
                let working_hours =
                  type === "working_hours"
                    ? updatedValue
                    : timesheets[parentindex]?.recordsList?.[recordListIndex]
                        ?.task[index]?.working_hours;
                if (type === "task") {
                  working_hours =
                    originalTimesheets[parentindex]?.recordsList?.[
                      recordListIndex
                    ]?.task[index]?.working_hours || dayjs("00:00", "HH:mm");
                  if (
                    updatedValue === "Client Holiday" ||
                    updatedValue === "Holiday"
                  ) {
                    working_hours = dayjs("00:00", "HH:mm");
                  }
                }
                if (type === "working_hours") {
                  return {
                    ...taskItem,
                    hours: parseInt(hours),
                    minutes: parseInt(minutes),
                    working_hours,
                  };
                } else {
                  return { ...taskItem, [type]: updatedValue, working_hours };
                }
              } else {
                return { ...taskItem };
              }
            });
            if (index == subrowindex && type === "comments") {
              return { ...subitem, [type]: updatedValue };
            } else {
              return { ...subitem, task: taskInner };
            }
          });
          return { ...item, recordsList: inner };
        } else {
          return { ...item };
        }
      });

      const { listFilterResponse, totalHours } =
        timeSheetHourCalculation(listFilter);
      state.details.timesheets = listFilterResponse;
      const { hours, minutes } = extractHourMinitue(totalHours, ":");
      state.details.totalHours = { hours, minutes };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getCloneTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCloneTimeSheet.fulfilled, (state, action) => {
      state.loading = false;
      const { data = [], subHeadermsg = "" } = action.payload;
      state.cloneTimesheetDetails = data;
      state.cloneTimesheetDetailsSubHeaderMsg = subHeadermsg || "";
      state.disableCloneTimesheetBtn = Boolean(subHeadermsg);
    });
    builder.addCase(getCloneTimeSheet.rejected, (state: any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: FAILURE,
        message: "error saved",
      };
      state.error = action.payload;
    });
    builder.addCase(getLandingTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLandingTimeSheet.fulfilled, (state, action) => {
      state.loading = false;
      const { status, msg, error, data } = action.payload;
      //state.configuration.isShowNotification = true;
      //   if(status===SUCCESS){
      //     state.configuration.alertMessage={type:SUCCESS,message:custommessage};
      //   }else{
      //     state.configuration.alertMessage={type:FAILURE,message};
      //   }
      // const baseFormat = timeSheetFormat("2024-09-16");
      state.details = data;
      // state.details.timesheets = [baseFormat];
      // state.details.day1="00:00";
      // state.details.day2="00:00";
      // state.details.day3="00:00";
      // state.details.day4="00:00";
      // state.details.day5="00:00";
      // state.details.day6="00:00";
      // state.details.day7="00:00";
      // state.details.totalHours="00:00"
    });
    builder.addCase(getLandingTimeSheet.rejected, (state: any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: FAILURE,
        message: "error saved",
      };
      state.error = action.payload;
    });
    builder.addCase(getDetailTimeSheet.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDetailTimeSheet.fulfilled, (state, action) => {
      state.loading = false;
      const { status, msg, error, data, weekStartDate } = action.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        const {
          empty,
          totalTime,
          tsProjects,
          displayWeek,
          projectTitle,
          icons,
          weekStartDate,
          weekEndDate,
          shiftType,
          defaultHoursAndMinutes,
        } = data;
        //state.configuration.isShowNotification = true;
        // const baseFormat = empty?timeSheetProjectFormat(projects,weekStartDate):timeSheetUpdateRowFormat(data?.tsProjects[0]?.
        //   recordsList
        //   ||[])
        const baseFormat = timeSheetProjectFormat(tsProjects) || [];
        const { listFilterResponse, totalHours } =
          timeSheetHourCalculation(baseFormat);
        state.details.originalTimesheets = listFilterResponse;
        state.details.timesheets = listFilterResponse;
        state.details.totalHours = totalTime;
        state.details.displayWeek = displayWeek;
        state.details.projectTitle = projectTitle;
        state.details.icons = icons;
        state.details.weekStartDate = weekStartDate;
        state.details.weekEndDate = weekEndDate;
        state.details.shiftType = shiftType;
        state.details.defaultHoursAndMinutes = defaultHoursAndMinutes;
      } else {
        const { errorMessage, errorHeaderMsg } = error;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          message: errorHeaderMsg,
          additionalInfo: errorMessage,
        };
        state.error = action.payload;
        state.details.timesheets = [];
        state.details.totalHours = {};
      }
    });
    builder.addCase(getDetailTimeSheet.rejected, (state: any, action) => {
      state.loading = false;
      state.configuration.isShowNotification = true;
      state.configuration.alertMessage = {
        type: FAILURE,
        message: "error saved",
      };
      state.error = action.payload;
    });
    builder.addCase(getMyActivities.pending, (state) => {
      state.loadingActivities = true;
      state.errorActivities = false;
    });
    builder.addCase(getMyActivities.fulfilled, (state: any, action) => {
      state.loadingActivities = false;
      state.myActivities = action?.payload;
    });
    builder.addCase(getMyActivities.rejected, (state: any, action) => {
      state.loadingActivities = false;
      state.errorActivities = true;
    });
    builder.addCase(deleteMyActivities.pending, (state) => {
      state.loadingActivities = true;
      state.errorActivities = false;
    });
    builder.addCase(deleteMyActivities.fulfilled, (state: any, action) => {
      state.loadingActivities = false;
      state.deleteActivities = action?.payload;
    });
    builder.addCase(deleteMyActivities.rejected, (state: any, action) => {
      state.loadingActivities = false;
      state.errorActivities = true;
    });
    builder.addCase(postCreateTimesheet.pending, (state) => {
      state.loading = true;
      state.loadingTimesheet = true;
      state.errorActivities = false;
    });
    builder.addCase(postCreateTimesheet.fulfilled, (state: any, action) => {
      state.loadingTimesheet = false;
      state.loading = false;
      state.postCreateTimeSheet = action?.payload;
      const { error, status, msg, headerMsg, subHeadermsg } = action?.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: SUCCESS,
          message: headerMsg,
          additionalInfo: subHeadermsg,
        };
      } else {
        state.configuration.isShowNotification = true;
        const { errorMessage, errorHeaderMsg } = error;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          message: errorMessage,
        };
      }
    });
    builder.addCase(postCreateTimesheet.rejected, (state: any, action) => {
      state.loadingTimesheet = false;
      state.errorTimesheet = true;
      state.loading = false;
    });
    builder.addCase(previewTimesheet.pending, (state) => {
      state.loading = true;
      state.loadingTimesheet = true;
      state.errorActivities = false;
    });
    builder.addCase(previewTimesheet.fulfilled, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      const { status, msg, error, data } = action?.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        const { empty, projects, tsProjects } = data;
        state.details.previewInfo = data;
      }
    });
    builder.addCase(previewTimesheet.rejected, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      state.errorTimesheet = true;
    });
    builder.addCase(getManagerTimesheet.pending, (state) => {
      state.managerLoading = true;
      state.errorManager = false;
    });
    builder.addCase(getManagerTimesheet.fulfilled, (state: any, action) => {
      state.managerLoading = false;
      state.managerTimesheet = action?.payload;
    });
    builder.addCase(getManagerTimesheet.rejected, (state: any, action) => {
      state.managerLoading = false;
      state.errorManager = true;
    });
    builder.addCase(getGroupByWeek.pending, (state) => {
      state.loadingGroupByWeek = true;
      state.errorGroupByWeek = false;
    });
    builder.addCase(getGroupByWeek.fulfilled, (state: any, action) => {
      state.loadingGroupByWeek = false;
      state.groupByWeekTsm = action?.payload;
    });
    builder.addCase(getGroupByWeek.rejected, (state: any, action) => {
      state.loadingGroupByWeek = false;
      state.errorGroupByWeek = true;
    });
    builder.addCase(getPreviewManager.pending, (state) => {
      state.loadingPreview = true;
      state.errorPreview = false;
    });
    builder.addCase(getPreviewManager.fulfilled, (state: any, action) => {
      state.loadingPreview = false;
      state.previewSelected = action?.payload;
    });
    builder.addCase(getPreviewManager.rejected, (state: any, action) => {
      state.loadingPreview = false;
      state.errorPreview = true;
    });
    builder.addCase(putApproveManager.pending, (state) => {
      state.loadingApproval = true;
      state.errorApproval = false;
    });
    builder.addCase(putApproveManager.fulfilled, (state: any, action) => {
      state.loadingApproval = false;
      state.approvalMananger = action?.payload;
    });
    builder.addCase(putApproveManager.rejected, (state: any, action) => {
      state.loadingApproval = false;
      state.errorApproval = true;
      state.approvalMananger = {};
      state.sendReminder = {};
    });
    builder.addCase(putRejectManager.pending, (state) => {
      state.loadingRejection = true;
      state.errorRejection = false;
    });
    builder.addCase(putRejectManager.fulfilled, (state: any, action) => {
      state.loadingRejection = false;
      state.rejectManager = action?.payload;
    });
    builder.addCase(putRejectManager.rejected, (state: any, action) => {
      state.loadingRejection = false;
      state.errorRejection = true;
    });
    builder.addCase(getSearchManager.pending, (state) => {
      state.loadingSearch = true;
      state.errorSearch = false;
    });
    builder.addCase(getSearchManager.fulfilled, (state: any, action) => {
      state.loadingSearch = false;
      state.searchManager = action?.payload;
    });
    builder.addCase(getSearchManager.rejected, (state: any, action) => {
      state.loadingSearch = false;
      state.errorSearch = true;
    });
    builder.addCase(getManagerLanding.pending, (state) => {
      state.loadingLanding = true;
      state.errorLanding = false;
    });
    builder.addCase(getManagerLanding.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.managerLanding = action?.payload;
    });
    builder.addCase(getManagerLanding.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLanding = true;
    });
    builder.addCase(saveTimesheet.pending, (state) => {
      state.loading = true;
      state.loadingTimesheet = true;
      state.errorActivities = false;
    });
    builder.addCase(saveTimesheet.fulfilled, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      const { status, msg, error, data, headerMsg, subHeadermsg } =
        action?.payload;
      const statusConvertion = status.toLowerCase();
      if (statusConvertion === SUCCESS) {
        const { status: savedStatus } = data;
        const savedStatusConvertion = savedStatus.toUpperCase();
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: savedStatusConvertion === DRAFT ? "info" : SUCCESS,
          message: headerMsg,
          additionalInfo: subHeadermsg,
        };
      } else {
        state.configuration.isShowNotification = true;
        const { errorMessage } = error;
        state.configuration.isShowNotification = true;
        state.configuration.alertMessage = {
          type: FAILURE,
          //message: errorMessage,
          additionalInfo: msg,
        };
      }
    });
    builder.addCase(saveTimesheet.rejected, (state: any, action) => {
      state.loading = false;
      state.loadingTimesheet = false;
      state.errorTimesheet = true;
    });

    builder.addCase(
      cleareApproveTimesheetData.fulfilled,
      (state: any, action) => {
        state.approvalMananger = {};
        state.sendReminder = {};
      }
    );
    builder.addCase(
      cleareRejectTimesheetData.fulfilled,
      (state: any, action) => {
        state.rejectManager = {};
        state.loadingRejection = false;
      }
    );
    builder.addCase(putBulkApproval.pending, (state) => {
      state.loadingBulkApproval = true;
      state.errorBulkApproval = false;
    });
    builder.addCase(putBulkApproval.fulfilled, (state: any, action) => {
      state.loadingBulkApproval = false;
      state.bulkApproval = action?.payload;
    });
    builder.addCase(putBulkApproval.rejected, (state: any, action) => {
      state.loadingBulkApproval = false;
      state.errorBulkApproval = true;
    });
    builder.addCase(
      clearBulkApproveTimesheet.fulfilled,
      (state: any, action) => {
        state.bulkApproval = {};
      }
    );

    builder.addCase(getDownloadTimesheet.pending, (state) => {
      state.loadingLandingDownload = true;
      state.errorLandingDownload = false;
    });
    builder.addCase(getDownloadTimesheet.fulfilled, (state: any, action) => {
      state.loadingLandingDownload = false;
      state.ExportTimesheet = action?.payload;
    });
    builder.addCase(getDownloadTimesheet.rejected, (state: any, action) => {
      state.loadingLandingDownload = false;
      state.errorLandingDownload = true;
    });
    builder.addCase(postExportTimesheet.pending, (state) => {
      state.loadingLanding = true;
      state.errorLandingPagePost = false;
    });
    builder.addCase(postExportTimesheet.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.ExportReportTimesheet = action?.payload;
    });
    builder.addCase(postExportTimesheet.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLandingPagePost = true;
    });

    builder.addCase(sendReminderNotification.pending, (state) => {
      state.loadingSendReminder = true;
      state.errorLanding = false;
    });
    builder.addCase(
      sendReminderNotification.fulfilled,
      (state: any, action) => {
        state.loadingSendReminder = false;
        state.sendReminder = action?.payload;
      }
    );
    builder.addCase(sendReminderNotification.rejected, (state: any, action) => {
      state.loadingSendReminder = false;
      state.errorLanding = true;
    });
    builder.addCase(
      clearManagerLandingScreenData.fulfilled,
      (state: any, action) => {
        state.managerTimesheet = [];
      }
    );
    builder.addCase(getDownloadManagerTimesheet.pending, (state) => {
      state.loadingLandingDownloadExport = true;
      state.errorLanding = false;
    });
    builder.addCase(
      getDownloadManagerTimesheet.fulfilled,
      (state: any, action) => {
        state.loadingLandingDownloadExport = false;
        state.ManagerExportTimesheet = action?.payload;
      }
    );
    builder.addCase(
      getDownloadManagerTimesheet.rejected,
      (state: any, action) => {
        state.loadingLandingDownloadExport = false;
        state.errorLanding = true;
      }
    );
    builder.addCase(postExportManagerTimesheet.pending, (state) => {
      state.loadingLandingDownloadExportPost = true;
      state.errorLanding = false;
    });
    builder.addCase(
      postExportManagerTimesheet.fulfilled,
      (state: any, action) => {
        state.loadingLandingDownloadExportPost = false;
        state.ExportManagerReportTimesheet = action?.payload;
      }
    );
    builder.addCase(
      postExportManagerTimesheet.rejected,
      (state: any, action) => {
        state.loadingLandingDownloadExportPost = false;
        state.errorLanding = true;
      }
    );
    builder.addCase(getAdminExportData.pending, (state) => {
      state.loadingLanding = true;
      state.errorLanding = false;
    });
    builder.addCase(getAdminExportData.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.AdminExportDownloadData = action?.payload;
    });
    builder.addCase(getAdminExportData.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLanding = true;
    });
    builder.addCase(postAdminExportData.pending, (state) => {
      state.loadingLanding = true;
      state.errorLanding = false;
    });
    builder.addCase(postAdminExportData.fulfilled, (state: any, action) => {
      state.loadingLanding = false;
      state.AdminExportDataProfile = action?.payload;
    });
    builder.addCase(postAdminExportData.rejected, (state: any, action) => {
      state.loadingLanding = false;
      state.errorLanding = true;
    });
    builder.addCase(postEmployeeTimesheetData.pending, (state) => {
      state.loadingLanding = true;
      state.errorPostLanding = false;
    });
    builder.addCase(
      postEmployeeTimesheetData.fulfilled,
      (state: any, action) => {
        state.loadingLanding = false;
      }
    );
    builder.addCase(
      postEmployeeTimesheetData.rejected,
      (state: any, action) => {
        state.loadingLanding = false;
        state.errorPostLanding = true;
      }
    );
    builder.addCase(getAdminFinanceLanding.pending, (state) => {
      state.loadingAdminFinanceLanding = true;
      state.errorAdminFinanceLanding = false;
    });
    builder.addCase(getAdminFinanceLanding.fulfilled, (state: any, action) => {
      state.loadingAdminFinanceLanding = false;
      state.adminFinanceLanding = action?.payload;
    });
    builder.addCase(getAdminFinanceLanding.rejected, (state: any, action) => {
      state.loadingAdminFinanceLanding = false;
      state.errorAdminFinanceLanding = true;
    });

    builder.addCase(adminPersonaEmployeePreview.pending, (state) => {
      state.adminEmployeePreviewDataLoading = true;
      state.errorLanding = false;
    });
    builder.addCase(
      adminPersonaEmployeePreview.fulfilled,
      (state: any, action) => {
        state.adminEmployeePreviewDataLoading = false;
        state.adminEmployeePreviewData = action?.payload;
      }
    );
    builder.addCase(
      adminPersonaEmployeePreview.rejected,
      (state: any, action) => {
        state.adminEmployeePreviewDataLoading = false;
        state.errorLanding = true;
      }
    );

    builder.addCase(adminPersonaEmployeeTimesheets.pending, (state) => {
      state.adminPersonaEmployeeTimesheetsLoading = true;
      state.errorLanding = false;
    });
    builder.addCase(
      adminPersonaEmployeeTimesheets.fulfilled,
      (state: any, action) => {
        state.adminPersonaEmployeeTimesheetsLoading = false;
        state.adminPersonaEmployeeTimesheetsData = action?.payload;
      }
    );
    builder.addCase(
      adminPersonaEmployeeTimesheets.rejected,
      (state: any, action) => {
        state.adminPersonaEmployeeTimesheetsLoading = false;
        state.errorLanding = true;
      }
    );

    builder.addCase(adminPersonaDownloadData.pending, (state) => {
      state.adminPersonaDownloadDataLoading = true;
      state.errorDownloadLanding = false;
    });
    builder.addCase(
      adminPersonaDownloadData.fulfilled,
      (state: any, action) => {
        state.adminPersonaDownloadDataLoading = false;
      }
    );
    builder.addCase(adminPersonaDownloadData.rejected, (state: any, action) => {
      state.adminPersonaDownloadDataLoading = false;
      state.errorDownloadLanding = true;
    });

    builder.addCase(revertTimesheetAdmin.pending, (state) => {
      state.revertTimesheetAdminLoading = true;
      state.errorDownloadLanding = false;
    });
    builder.addCase(revertTimesheetAdmin.fulfilled, (state: any, action) => {
      state.revertTimesheetAdminLoading = false;
      state.revertTimesheetAdminData = action.payload;
    });
    builder.addCase(revertTimesheetAdmin.rejected, (state: any, action) => {
      state.revertTimesheetAdminLoading = false;
      state.errorDownloadLanding = true;
    });
    builder.addCase(approveTimesheetAdmin.pending, (state) => {
      state.revertTimesheetAdminLoading = true;
      state.errorDownloadLanding = false;
    });
    builder.addCase(approveTimesheetAdmin.fulfilled, (state: any, action) => {
      state.revertTimesheetAdminLoading = false;
      state.revertTimesheetAdminData = action.payload;
    });
    builder.addCase(approveTimesheetAdmin.rejected, (state: any, action) => {
      state.revertTimesheetAdminLoading = false;
      state.errorDownloadLanding = true;
    });
  },
});

export default detail.reducer;
export const {
  payloadAction,
  updateWeekendStatus,
  notificationAction,
  timesheetAddRowAction,
  timesheetRemoveRowAction,
  timesheetUpdateRowAction,
  setTimesheetClone,
  setSelectedWeekStart,
  setSelectedWeekEnd,
  setCloneSelectedWeek,
  setDisableCloneTimesheetBtn,
} = detail.actions;
