import "./index.css";
import { HolidayCalendarCard } from "../../calendar/HolidayCalendar";
import { Carousel, Input } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import React, { useEffect, useState } from "react";
import ManHoldingLaptop from "../../../themes/assets/images/svg/leave_management/manHoldingLaptop.svg";
import StaticCalendar from "./StaticCalendar";
import moment from "moment";
import {
  LEAVE_ERROR,
  NO_DATA_LEAVE_MANAGEMENT,
  NO_DATA_LEAVE_TXT,
  UPCOMING_HOLIDAY_TITLE,
  VIEW_ALL_LINK,
} from "../../../constants";
import ErrorImage from "../../../themes/assets/images/adult-content.png";
import { useAppSelector } from "../../../redux/store/redux-hooks";
import { LeaveForm } from "./LeaveForm";
import { Link, useNavigate } from "react-router-dom";
import { setPreviewData } from "../../../redux/reducers/leavemanagement";
import FileAttachmentIcon from "../../../themes/assets/images/svg/leave_management/FileAttachmentIcon.svg";
import ExcelAttachmentIcon from "../../../themes/assets/images/svg/leave_management/excel.png";
import PdfAttachmentIcon from "../../../themes/assets/images/svg/leave_management/PdfIcon.svg";
import PptAttachmentIcon from "../../../themes/assets/images/svg/leave_management/ppt.png";
import DocAttachmentIcon from "../../../themes/assets/images/svg/leave_management/doc.png";
import ImageAttachmentIcon from "../../../themes/assets/images/svg/leave_management/jpg-png-gif.png";
import CloseIcon from "../../../themes/assets/images/svg/leave_management/CrossIcon.svg";
import Iicon from "../../../themes/assets/images/svg/leave_management/iIcon.svg";
import NoResponseImage from "../../../themes/assets/images/svg/no-response.svg";
import { FilePdfOutlined } from "@ant-design/icons";
import { getLoggedInUserData } from "../../utils/util";

interface UploadedFile {
  name: string;
  size: string;
  type: any;
}

const EmptyLeaveData = (props) => {
  return (
    <div className="emptyleave_data">
      <img className="emptyleave-pending" src={NoResponseImage} alt="" />
      <p>{props?.headerMsg ? props?.headerMsg : NO_DATA_LEAVE_MANAGEMENT}</p>
      {props?.description ? <span>{NO_DATA_LEAVE_TXT}</span> : null}
    </div>
  );
};

export const LeaveTypeBalanceCard = ({ leaveBalanceList }) => {
  const LeaveTypeBalanceDetails = (props: any) => {
    const { image, title, noOfDays, unit } = props;
    return (
      <div id="leave-type-balance-card" className="leave-type-balance-card">
        <img
          id="leave-type-image"
          className="leave-type-image"
          src={image}
          alt=""
        />
        <div
          id="leave-type-balance-card-text-container"
          className="leave-type-balance-card-text-container"
        >
          <div id="leave-type-title" className="leave-type-title">
            {title}
          </div>
          <div id="leave-type-balance-days" className="leave-type-balance-days">
            <span>{noOfDays} </span>
            <span
              id="leave-type-balance-days-text"
              className="leave-type-balance-days-text"
            >
              {unit.toLowerCase()}
            </span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div
      id="leave-type-balance-card-container"
      className="leave-type-balance-card-container"
    >
      {leaveBalanceList.map((item: any, index: number) => {
        return (
          <>
            <LeaveTypeBalanceDetails
              image={item?.resourceurl}
              title={item.leaveTypeDisplayName}
              noOfDays={item.leaveBalance}
              unit={item.timeUnit}
            />
            {index != leaveBalanceList.length - 1 && (
              <div
                id="leave-type-balance-card-divider"
                className="leave-type-balance-card-divider"
              />
            )}
          </>
        );
      })}
    </div>
  );
};

export const ErrorComponent = () => {
  return (
    <div id="leave_error_container" className="leave_error_container">
      <img
        id="leave_error_image"
        className="leave_error_image"
        src={ErrorImage}
        alt=""
      />
      <div id="leave_error_title" className="leave_error_title">
        {LEAVE_ERROR}
      </div>
    </div>
  );
};

export const HolidayQuoteCard = ({ staticLeaveBanner }) => {
  const staticBody = staticLeaveBanner?.[0]?.contentdetails?.body;
  const staticImage = staticLeaveBanner?.[0]?.contentdetails?.referenceUrl?.url;
  return (
    <div
      id="holiday-quote-card-container"
      className="holiday-quote-card-container"
    >
      <div
        id="holiday-quote-card-background"
        className="holiday-quote-card-background"
      >
        <div id="holiday-quote-card-image" className="holiday-quote-card-image">
          {staticImage && (
            <img
              id="manholdinglapotop"
              className="manholdinglapotop"
              src={staticImage}
              alt=""
            />
          )}
        </div>
        {staticBody && (
          <h1 id="holiday-quote-card-text" className="holiday-quote-card-text">
            "{staticBody}"
          </h1>
        )}
      </div>
    </div>
  );
};

export const HolidayCarousel = ({ floaterBannerList, clickOnFloaterBaner }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <div
      id="holiday-carosuel-main-container"
      className="holiday-carosuel-main-container"
    >
      <Carousel
        autoplay
        id="carousel-container"
        className="carousel-container"
        afterChange={(current: number) => {
          setActiveSlide(current);
        }}
        autoplaySpeed={4000}
        dots={false}
      >
        {floaterBannerList.map((item: any, index: number) => (
          <div
            key={index}
            id="holiday-carousel-card"
            className="holiday-carousel-card"
            onClick={() => clickOnFloaterBaner(item)}
          >
            <LazyLoadImage
              src={item?.resourceUrl}
              className="carousel-bg-img"
              alt="banner"
              effect="blur"
            />
          </div>
        ))}
      </Carousel>
      <div id="indicators-container" className="indicators-container">
        {floaterBannerList.map((item: any, index: any) => {
          return (
            <div
              key={index}
              className={`indicator ${
                index === activeSlide
                  ? "active-indicator"
                  : "inactive-indicator"
              }`}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export const AbsenceRequestsCard = ({ absenceList, clickOnPriview }) => {
  const landingpageData = useAppSelector((state: any) => state.leavemanagement);
  const LeaveError = landingpageData?.error;
  const navigate = useNavigate();

  const HolidayDetails = (props: any) => {
    const { leaveTypeDisplayName, endDate, startDate, workflowSteps } =
      props?.data;
    function changeDateFormat(date: string) {
      return moment(date)?.format("MMM DD, YYYY");
    }

    return (
      <div
        id="absence-request-holiday-details-card-container"
        className="absence-request-holiday-details-card-container"
        onClick={() => clickOnPriview(props.data)}
      >
        {/* holiday type and dates */}
        <div
          id="absence-request-holiday-details-card-text-container"
          className="absence-request-holiday-details-card-text-container"
        >
          <div
            id="absence-request-holiday-details-card-holiday-type-text"
            className="absence-request-holiday-details-card-holiday-type-text"
          >
            {leaveTypeDisplayName}
          </div>
          <div
            id="absence-request-dates-text"
            className="absence-request-dates-text"
          >
            {changeDateFormat(startDate)} - {changeDateFormat(endDate)}
          </div>
        </div>

        <div
          id="absence-request-leave-type-status-text"
          className="absence-request-leave-type-status-text"
          style={{
            color: workflowSteps?.colorCode,
            backgroundColor: workflowSteps?.bgColor,
          }}
        >
          {workflowSteps?.displayProperties?.displayNameForEmployee}
        </div>
      </div>
    );
  };

  return (
    <div
      id="absence-requests-card-container"
      className="absence-requests-card-container"
    >
      <div id="space-between-header" className="space-between-header">
        <div id="side-heading" className="side-heading">
          Absence Request
        </div>
        {absenceList?.length > 0 ? (
          <div
            id="view-all-button"
            className="view-all-button"
            onClick={() => {
              navigate("absent-request");
            }}
          >
            View All
          </div>
        ) : null}
      </div>
      <div
        id="absence-requests-card-holiday-details-conatiner"
        className="absence-requests-card-holiday-details-conatiner"
      >
        {LeaveError ? (
          <ErrorComponent />
        ) : absenceList?.length > 0 ? (
          absenceList
            ?.filter((item: any, index: number) => index < 2)
            .map((item: any, index: number) => {
              return (
                <React.Fragment>
                  <HolidayDetails data={item} />
                  {index !== 1 && (
                    <div
                      id="absence-request-card-divider"
                      className="absence-request-card-divider"
                    />
                  )}
                </React.Fragment>
              );
            })
        ) : (
          <EmptyLeaveData headerMsg="No Absence Request found" />
        )}
      </div>
    </div>
  );
};

export const UpcomingHolidaysCard = () => {
  const upcomingLeavesData = useAppSelector(
    (state: any) => state?.leavemanagement
  );
  const CalendarData = upcomingLeavesData?.upcomingLeaves?.body;
  if (CalendarData && Array.isArray(CalendarData)) {
    return (
      <div
        id="upcoming-holidays-main-card-container"
        className="upcoming-holidays-main-card-container"
      >
        <div id="space-between-header" className="space-between-header">
          <div id="side-heading" className="side-heading">
            {UPCOMING_HOLIDAY_TITLE}
          </div>
          <Link
            to="/web/holiday_calendar"
            id="view-all-button"
            className="view-all-button"
          >
            {VIEW_ALL_LINK}
          </Link>
        </div>
        <div
          id="upcoming-holidays-cards-container"
          className="upcoming-holidays-cards-container"
        >
          {CalendarData?.slice(0, 3)?.map((item) => {
            return (
              <div
                id="upcoming-holidays-cards-container-calendar-card"
                className="upcoming-holidays-cards-container-calendar-card"
              >
                <HolidayCalendarCard data={item} key={item} />
              </div>
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export const LeaveRequestCard = ({
  calendarData,

  leaveType,
  leavetypeId,
  setLeaveType,
  clickOnPriview,
  editable,
}) => {
  interface labelItem {
    displayName: string;
    colorCode: string;
  }

  const landingpageData = useAppSelector(
    (state: any) => state?.leavemanagement
  );

  const LeaveError = landingpageData?.error;

  const labelData: labelItem[] = calendarData.map(
    (item: any) => item.workflowSteps
  );
  const [mandatoryCheck, setMandatoryCheck] = useState({
    initialMandatoryList: [],
    isMandatory: true,
  });

  const [previewObjData, setPreviewObjData] = useState({});
  const [previewCommonObjData, setPreviewCommonObjData] = useState({});
  const { leaveTemplate } = useAppSelector(
    (state: any) => state.leavemanagement
  );
  const { value } = leaveTemplate?.validations?.backEndValidations?.[0] || {};
  const previewDisabled = value === "MALE" || false;

  // useEffect(() => {
  //   console.log("previewObjData", previewObjData, mandatoryCheck?.isMandatory);
  //   console.log("previewCommonObjData", previewCommonObjData);
  // }, [mandatoryCheck?.isMandatory, previewObjData]);

  return (
    <div
      id="leave-request-card-container"
      className="leave-request-card-container"
    >
      <div id="side-heading" className="side-heading">
        Leave Request
      </div>
      <div id="leave-request-card-body" className="leave-request-card-body">
        {LeaveError || calendarData?.length === 0 ? (
          <ErrorComponent />
        ) : (
          <>
            <div
              id="leave-request-card-body-header"
              className="leave-request-card-body-header"
            >
              <div
                id="leave-request-card-calendar-view"
                className="leave-request-card-calendar-view"
              >
                <div
                  id="leave-request-card-calendar-view-top"
                  className="leave-request-card-calendar-view-top"
                >
                  <StaticCalendar calendarData={calendarData} />
                </div>

                {/* labels */}
                <div
                  id="leave-request-card-legends-container"
                  className="leave-request-card-legends-container"
                >
                  {labelData.map((item) => (
                    <div
                      id="leave-request-card-legends-text-container"
                      className="leave-request-card-legends-text-container"
                    >
                      <div
                        id="leave-request-card-legend-color"
                        className="leave-request-card-legend-color"
                        style={{ backgroundColor: item.colorCode }}
                      ></div>
                      <div>{item.displayName}</div>
                    </div>
                  ))}
                </div>
              </div>

              <LeaveForm
                leavetypeId={leavetypeId}
                route=""
                mandatoryCheck={mandatoryCheck}
                setMandatoryCheck={setMandatoryCheck}
                setPreviewObjData={setPreviewObjData}
                setPreviewCommonObjData={setPreviewCommonObjData}
              />
            </div>
            <div className="leave-request-card-footer-container">
              {/* <div className="clear-all-btn">Clear All</div> */}
              <div
                onClick={() =>
                  clickOnPriview(
                    previewObjData,
                    previewCommonObjData,
                    !mandatoryCheck?.isMandatory ? true : false
                  )
                }
                id="inactive-preview-btn"
                className={`inactive-preview-btn`}
                style={{
                  backgroundColor: mandatoryCheck?.isMandatory
                    ? "#D9D9D9"
                    : "#0642B5",
                  color: mandatoryCheck?.isMandatory ? "#454545" : "#fff",
                  cursor: "pointer",
                }}
                aria-disabled={mandatoryCheck?.isMandatory}
              >
                Preview & Submit
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const getFileIcon = (type: any) => {
  if (type?.[0]?.includes("pdf")) return PdfAttachmentIcon;
  if (type?.[0]?.includes("text/plain")) return FileAttachmentIcon;
  if (type?.[0]?.includes("spreadsheet")) return ExcelAttachmentIcon;
  if (type?.[0]?.includes("presentation")) return PptAttachmentIcon;
  if (
    ["image/jpeg", "image/jpg", "image/png", "image/gif"]?.includes(type?.[0])
  )
    return ImageAttachmentIcon;
  if (type?.[0]?.includes(".document")) return DocAttachmentIcon;
  return "";
};

export const FileUpload = ({
  title = "",
  mandatory,
  onUpload,
  selectedLeaveType,
  tooltipText,
}: any) => {
  const showAttachmentInfo = selectedLeaveType?.leaveTypeId === "PA_CAN";
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [error, setError] = useState<string>("");
  const userData = getLoggedInUserData();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files && event?.target?.files?.length > 0) {
      const file = event?.target?.files[0];
      if (file) {
        const reader: any = new FileReader();
        reader.onload = () => {
          const sizeInKB = (file.size / 1024).toFixed(2);
          const sizeInMB: any = (file.size / (1024 * 1024)).toFixed(2);
          const fileType = file.type.split("/"[1]);
          if (sizeInMB > 5) {
            setError("File size should be less than 5 mb");
            return null;
          }
          setError("");

          const payload = {
            userId: userData?.userId,
            fileName: file.name,
            fileContent: reader.result.split(",")[1],
          };
          const uploadFile = {
            ...payload,
            name: file.name,
            size: `${sizeInKB} kb`,
            type: fileType,
          };
          setUploadedFile(uploadFile);
          onUpload(payload, uploadFile);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleDelete = () => {
    setUploadedFile(null);
    onUpload({});
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div id="form-item-container" className="form-item-container">
      <div id="form-item-text" className="form-item-text">
        {title} {mandatory && <span className="mandatory">*</span>}
        {showAttachmentInfo && (
          <span>
            <div className="tooltip-container">
              <span className="info-icon">
                <img src={Iicon} />
              </span>
              <div className="tooltip">{tooltipText}</div>
            </div>
          </span>
        )}
      </div>
      {!uploadedFile ? (
        <div
          className="attachment-main"
          onClick={() => document.getElementById("fileInput")?.click()}
        >
          <input
            id="fileInput"
            type="file"
            className="attachment-input"
            onChange={handleFileChange}
          />
          <img src={FileAttachmentIcon} alt="Miscarriage" />
          <p className="no-attachment-text">No attachments uploaded</p>
          <p className="add-attachment-text">To add a file, click here!</p>
        </div>
      ) : (
        <div className="after-attachment-main">
          <div className="after-attachment-sub">
            <div className="attachment-file-icon-div">
              <img
                src={getFileIcon(uploadedFile.type)}
                className="attachment-file-icon"
                alt="Miscarriage"
              />
            </div>
            <div className="attached-file-main">
              <div className="attached-file-name">{uploadedFile.name}</div>
              <div className="attached-file-size">{uploadedFile.size}</div>
            </div>
            <div className="close-cross-icon">
              <img src={CloseIcon} onClick={handleDelete} alt="closeIcon" />
            </div>
          </div>
        </div>
      )}
      {error && <span className="file-attachment-size-restrict">{error}</span>}
    </div>
  );
};
