import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={121}
    height={121}
    fill="none"
    {...props}
  >
    <path
      fill="#5E6175"
      d="M106.157 97.465c-1.571 0-2.813-1.266-2.813-2.836 0-1.57 1.266-2.813 2.836-2.813.727 0 1.453.282 1.969.797a2.816 2.816 0 0 1 .047 3.985 2.8 2.8 0 0 1-2.039.867Zm0-4.242c-.774 0-1.407.633-1.407 1.43 0 .773.633 1.406 1.43 1.406.774 0 1.406-.633 1.406-1.43-.023-.773-.632-1.406-1.429-1.406Zm-15.211 11.812c-.024-1.195.96-2.18 2.156-2.203 1.195-.023 2.18.961 2.203 2.156 0 .586-.21 1.125-.633 1.547a2.158 2.158 0 0 1-3.07.024 2.114 2.114 0 0 1-.656-1.524Zm2.18-.797a.78.78 0 0 0-.774.774.78.78 0 0 0 .773.773.78.78 0 0 0 .774-.773.765.765 0 0 0-.774-.774Z"
    />
    <path
      fill="#474747"
      d="M12.875 19.184a3.831 3.831 0 0 0 3.844-3.844 3.831 3.831 0 0 0 3.843 3.844 3.831 3.831 0 0 0-3.843 3.843 3.846 3.846 0 0 0-3.844-3.843ZM27.4 48.524h-6.705V38.99c0-13.036 10.615-23.65 23.613-23.65h9.162v6.704h-9.162c-9.348 0-16.909 7.598-16.909 16.946v9.534Z"
    />
    <path
      fill="#474747"
      d="m24.009 56.532-10.54-12.067h21.043L24.009 56.532ZM91.452 64.837h6.704v9.535c0 13.035-10.614 23.65-23.612 23.65h-9.162v-6.704h9.162c9.348 0 16.908-7.598 16.908-16.946v-9.535Z"
    />
    <path fill="#474747" d="m94.843 56.83 10.54 12.067H84.34l10.503-12.068Z" />
    <path
      fill="#3E73D8"
      stroke="#fff"
      strokeWidth={1.5}
      d="M42.719 83.022c0 7.87-6.38 14.25-14.25 14.25s-14.25-6.38-14.25-14.25 6.38-14.25 14.25-14.25 14.25 6.38 14.25 14.25Z"
    />
    <path
      fill="#fff"
      d="M35.348 87.784a1.496 1.496 0 0 1 0 2.115 1.502 1.502 0 0 1-2.106 0l-4.763-4.763-4.77 4.763a1.493 1.493 0 0 1-2.107-2.115l4.762-4.762-4.762-4.763a1.486 1.486 0 0 1 0-2.106 1.493 1.493 0 0 1 2.106-.009l4.771 4.771 4.763-4.77a1.502 1.502 0 0 1 2.106 0 1.496 1.496 0 0 1 0 2.114l-4.762 4.763 4.762 4.762Z"
    />
    <path
      fill="#3E73D8"
      d="M90.38 44.61c7.88 0 14.27-6.388 14.27-14.27 0-7.88-6.39-14.27-14.27-14.27-7.882 0-14.27 6.39-14.27 14.27 0 7.882 6.388 14.27 14.27 14.27Z"
    />
    <path
      fill="#fff"
      d="M90.383 45.34a15 15 0 1 1 15-15 15.016 15.016 0 0 1-15 15Zm0-28.54a13.54 13.54 0 1 0 13.54 13.54 13.555 13.555 0 0 0-13.54-13.54Z"
    />
    <path
      fill="#fff"
      d="m88.03 37.423-5.882-5.88 2.408-2.407 3.474 3.47 8.561-8.561L99 26.453l-10.97 10.97Z"
    />
  </svg>
);
export default SvgComponent;
