import React, { useState } from "react";
import "./TSMProgressTracker.css";
const TSMProgressTracker = (props: any) => {
  return (
    <>
      <div className="action-status-main-view" id="action-status-main-view">
        <div className="action-status-text-title" id="action-status-text-title">
          Progress Tracker
        </div>
        {props?.actions?.map((item, index) => {
          return (
            <div
              className="tracker-row-view"
              id="tracker-row-view"
              key={`pregress_key${index}`}
            >
              <div className={"action-status-text"} id="action-status-text">
                {item?.actionDate} :
                <div
                  className="action-status-text-grey"
                  id="action-status-text-grey"
                >
                  {item?.actionType}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default TSMProgressTracker;
