import { useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import "./ApprovalsModal.css";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Input, Divider, Skeleton } from "antd";
import {
  MY_ACTIVITIES,
  APPROVE_TIMESHEET,
  REJECT_TIMESHEET,
  CANCEL,
  TIMESHEET_POPUPS,
  CONFIRM,
} from "../../constants";
import TIMESHEET_IMG from "../../themes/assets/images/timesheet/Timemanagement.png";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import {
  getPreviewManager,
  putRejectManager,
  putApproveManager,
  cleareRejectTimesheetData,
  cleareApproveTimesheetData,
  putBulkApproval,
  clearBulkApproveTimesheet,
} from "../../redux/actions/timesheet";
// import { getPreviewManager } from "../../redux/actions/timesheet";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";
import RevertTMIcon from "../../components/common/revertTimesheetIcon";
import { dailyComment } from "../utils/util";
import TSMProgressTracker from "../TSMProgressTracker";

export const ApprovalModal = (props) => {
  const dispatch = useAppDispatch();
  const { bulkApproval, loadingBulkApproval } = useAppSelector(
    (state: any) => state.timesheet
  );
  const [visible, setVisible] = useState(false);
  const approveSuccess = !bulkApproval?.error;
  const handleBulkApprove = () => {
    if (props?.isRevert) {
      props?.clickOnConfirm();
    } else {
      const bulkApprovalBody = {
        tsDetails: props?.selectedUsersData,
        comments: "",
      };
      dispatch(putBulkApproval(bulkApprovalBody));
      setVisible(true);
    }
    props?.close();
  };
  const handleClose = () => {
    setVisible(false);
    dispatch(clearBulkApproveTimesheet({}));
  };

  return (
    <>
      <Modal
        className="approval-tsm"
        open={props?.open}
        footer={false}
        closeIcon={false}
        centered={true}
      >
        <div id="approval-popup-modal" className="approval-popup-modal">
          {props?.isRevert ? (
            <RevertTMIcon />
          ) : (
            <img
              id="timesheetimg"
              className="timesheetimg"
              src={TIMESHEET_IMG}
            />
          )}

          <div id="timesheet-head" className="timesheet-head">
            {props?.isRevert
              ? TIMESHEET_POPUPS?.REVERT_TITLE
              : TIMESHEET_POPUPS?.APPROVE_TITLE}
          </div>
          {/* <img></img> */}
          {props?.isRevert ? (
            <div id="timesheet-body" className="timesheet-body">
              Are you sure you want to revert this timesheets?
            </div>
          ) : (
            <div id="timesheet-body" className="timesheet-body">
              Are you sure you want to approve {props?.selectedUsers} timesheets
            </div>
          )}

          <div id="tsm-buttons" className="tsm-buttons">
            <button
              id="cancel-button"
              className="cancel-button"
              onClick={props?.close}
            >
              {CANCEL}
            </button>
            <button
              id="confirm-button"
              className="confirm-button"
              onClick={handleBulkApprove}
            >
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
      {visible && !loadingBulkApproval && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            approveSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={approveSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            approveSuccess
              ? bulkApproval?.headerMsg
              : bulkApproval?.error?.errorHeaderMsg
          }
          toastMsgPara={
            approveSuccess
              ? bulkApproval?.subHeadermsg
              : bulkApproval?.error?.errorMessage
          }
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
    </>
  );
};

export const RejecetedModal = (props) => {
  const dispatch = useAppDispatch();
  const { rejectManager } = useAppSelector((state: any) => state.timesheet);
  const rejectMessage = !rejectManager?.error;
  const [visible, setVisible] = useState(false);
  const [closeReject, setCloseReject] = useState(false);
  const rejectDetails = props?.data;
  const { TextArea } = Input;
  const [comment, setIsComment] = useState("");
  const handleConfirm = () => {
    let rejectBody = {
      tsDetails: [props?.data],
      comments: comment,
    };
    if (props?.isAdmin) {
      props.data["adminRejectcomments"] = comment;
      rejectBody = props?.data;
    }
    dispatch(putRejectManager({ rejectBody, isAdmin: props?.isAdmin }));
    // setVisible(!visible);
    setCloseReject(props?.close);
    setIsComment("");
  };
  const handleClose = () => {
    setIsComment("");
    props?.close();
  };
  useEffect(() => {
    if (Object.keys(rejectManager)?.length > 0) {
      setVisible(!visible);
      if (rejectMessage) {
        props?.isRejectSuccess(true);
      }
    }
  }, [rejectManager]);

  const closeToastMsg = () => {
    setVisible(false);
    dispatch(cleareRejectTimesheetData({}));
  };
  const [isMax, setIsMax] = useState("");
  const maxLength = 200;
  return (
    <div>
      <Modal
        className="reject-tsm"
        open={props?.open}
        footer={false}
        closeIcon={false}
        centered={true}
      >
        <div className="reject-popup">
          <div id="timesheet-head" className="timesheet-head">
            {TIMESHEET_POPUPS?.REJECT_TITLE}
          </div>
          <div id="timesheet-body" className="timesheet-body">
            {TIMESHEET_POPUPS?.REJECT_CONTENT}
          </div>
          <div className="give_reason_tsm">
            {TIMESHEET_POPUPS?.TSM_GIVE_REASON}
            <span style={{ color: "#CC0000" }}>{"*"}</span>
          </div>
          <TextArea
            placeholder="Enter Here"
            autoSize={{ minRows: 4 }}
            value={comment}
            onChange={(e) => setIsComment(e.target.value)}
            maxLength={maxLength}
          />
          {comment.length > maxLength - 1 && (
            <div style={{ color: "red" }}>Maximum length exceeded!</div>
          )}
          <div id="tsm-buttons" className="tsm-buttons">
            <button
              id="cancel-button"
              className="cancel-button"
              onClick={handleClose}
            >
              {CANCEL}
            </button>
            <button
              id="confirm-button"
              className="confirm-button"
              onClick={handleConfirm}
              disabled={comment ? false : true}
            >
              {CONFIRM}
            </button>
          </div>
        </div>
      </Modal>
      <div className="z-index-outer-div">
        {visible && (
          <ToastMessage
            closeAction={closeToastMsg}
            customToastArea={
              rejectMessage
                ? "custom-toast-area skills-extracted"
                : "custom-toast-area no-skills-extracted"
            }
            customToastLeftArea={"custom-left-area"}
            icon={rejectMessage ? ToastInfo : ToastError}
            toastMsgTitle={
              rejectMessage
                ? rejectManager?.headerMsg
                : rejectManager?.error?.errorHeaderMsg
            }
            toastMsgPara={
              rejectMessage
                ? rejectManager?.subHeadermsg
                : rejectManager?.error?.errorMessage
            }
            customToastRightArea={"close-btn-area"}
            closeIcon={ToastClose}
          />
        )}
      </div>
    </div>
  );
};

export const PreviewApprovals = (props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const preview = props?.userInformation;
    dispatch(getPreviewManager(preview));
  }, []);
  const { previewSelected, loadingPreview, errorPreview, approvalMananger } =
    useAppSelector((state: any) => state.timesheet);
  const previewData = previewSelected;
  const previewShow = previewData?.data;
  const { openPreview, closePreview, pageName, closePreviewIcon } = props;
  const [pageState, setPageState] = useState(false);
  const [onClose, setOnClose] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const approveSuccess = !approvalMananger?.error;

  useEffect(() => {
    if (pageName === MY_ACTIVITIES) {
      setPageState(!pageState);
    }
  }, [pageName]);

  const handelReject = () => {
    setOnClose(props?.closePreview);
    props?.onRejectChange(props?.rejectPreview);
  };
  const handleApprove = () => {
    const approveBody = {
      tsDetails: [props?.approvePreview],
      comments: "",
    };
    dispatch(putApproveManager(approveBody));
    props?.approvepreviewClose();
  };
  const handleClose = () => {
    setPreviewVisible(false);
    dispatch(cleareApproveTimesheetData({}));
  };

  const CurrentUserPreviewSkeleton = () => {
    return (
      <div>
        <Skeleton active={true} />
        <Skeleton active={true} />
        <Skeleton active={true} />
      </div>
    );
  };
  return (
    <div>
      <Modal
        open={openPreview}
        onCancel={closePreviewIcon}
        footer={false}
        closeIcon={true}
        centered={true}
        className="preview_tsm_modal"
      >
        {loadingPreview ? (
          <CurrentUserPreviewSkeleton />
        ) : (
          <div>
            <div className="preview_timesheet">
              <div>{previewShow?.projectTitle}</div>
              <div className="preview_time_module">
                <div className="preview_timesheet_time">
                  {previewShow?.displayWeek}
                </div>
                <div
                  className={`preview_display_status ${previewShow?.status}`}
                >
                  {previewShow?.status}
                </div>
              </div>
            </div>
            <div className="preview-tsm-borderbox">
              {previewShow?.tsProjects?.map((preview, index) => (
                <div className="preview-project-division">
                  <div className="preview_company_title">
                    <div>
                      {index + 1}. {preview?.projectName}
                    </div>
                    <div>{`${preview?.hours
                      .toString()
                      .padStart(2, "0")}:${preview?.minutes
                      .toString()
                      .padStart(2, "0")} hrs`}</div>
                  </div>
                  <div className="preview_work_display">
                    {preview?.recordsList?.map((dates) => {
                      return (
                        <>
                          {dates?.dayTotalHour > 0 ||
                          dates?.dayTotalMinutes > 0 ? (
                            <div className="preview_dual_work_timesheet">
                              <div className="leave-holiday-preview-view">
                                <div>{dates?.dayOfWeek}</div>
                                {dates?.leaveApplied || dates?.halfDay ? (
                                  <div className="leave-holiday-preview-text">{`(${dates?.leaveLabel})`}</div>
                                ) : null}
                                {dates?.holiday ? (
                                  <div className="leave-holiday-preview-text">{`(${dates?.leaveLabel})`}</div>
                                ) : null}
                              </div>

                              <div>{`${dates?.dayTotalHour
                                ?.toString()
                                .padStart(2, "0")}:${dates?.dayTotalMinutes
                                ?.toString()
                                .padStart(2, "0")} hrs`}</div>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                  <>
                    {previewShow?.tsProjects?.length === 1 && (
                      <div className="preview_total_tsm">
                        <div>Total</div>
                        <div>
                          {" "}
                          {`${previewShow?.totalTime?.hours
                            .toString()
                            .padStart(2, "0")}:${previewShow?.totalTime?.minutes
                            .toString()
                            .padStart(2, "0")} hrs`}
                        </div>
                      </div>
                    )}
                  </>
                  {dailyComment(preview?.recordsList) && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_total_tsm">Daily Comment</div>
                      {preview?.recordsList?.map(
                        (comment) =>
                          comment?.comments && (
                            <>
                              <div className="preview_day_daily">
                                <span className="preview_daily_day">
                                  <span>{comment.dayOfWeek}</span> :{" "}
                                  <span className="comments">
                                    {comment.comments}
                                  </span>
                                </span>
                              </div>
                            </>
                          )
                      )}
                    </>
                  )}
                  {preview.overallComments && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_overall_tsm">
                        <div className="preview_total_tsm overall">
                          Overall Comment
                        </div>
                        <div className="preview_total_tsm_content">
                          {preview.overallComments}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))}
              {previewShow?.tsProjects?.length > 1 && (
                <div className="preview_total_tsm">
                  <div>Total</div>
                  <div>
                    {" "}
                    {`${previewShow?.totalTime?.hours
                      .toString()
                      .padStart(2, "0")}:${previewShow?.totalTime?.minutes
                      .toString()
                      .padStart(2, "0")} hrs`}
                  </div>
                </div>
              )}
              {props?.selectedStatus === "Rejected" && (
                <>
                  <Divider className="tsm_preview_divider" />
                  <div className="preview-manager-box">
                    <div className="preview-manager-comments">
                      Manager Comments
                    </div>
                    <div className="preview-manager-box-comments">
                      {previewShow?.managerComments}
                    </div>
                  </div>
                </>
              )}
            </div>
            {previewShow?.actions != null ? (
              <TSMProgressTracker actions={previewShow?.actions} />
            ) : null}
            {props?.selectedStatus === "Pending" && (
              <div className="preview_buttons_timesheet">
                <>
                  <button className="add_manager" onClick={handelReject}>
                    <CloseOutlined /> {REJECT_TIMESHEET}
                  </button>
                  <button className="timesheet_submit" onClick={handleApprove}>
                    <CheckOutlined /> {APPROVE_TIMESHEET}
                  </button>
                </>
              </div>
            )}
          </div>
        )}
      </Modal>
      <div className="z-index-outer-div">
        {previewVisible && (
          <ToastMessage
            closeAction={handleClose}
            customToastArea={
              approveSuccess
                ? "custom-toast-area skills-extracted"
                : "custom-toast-area no-skills-extracted"
            }
            customToastLeftArea={"custom-left-area"}
            icon={approveSuccess ? ToastInfo : ToastError}
            toastMsgTitle={
              approveSuccess
                ? approvalMananger?.headerMsg
                : approvalMananger?.error?.errorHeaderMsg
            }
            toastMsgPara={
              approveSuccess
                ? approvalMananger?.subHeadermsg
                : approvalMananger?.error?.errorMessage
            }
            customToastRightArea={"close-btn-area"}
            closeIcon={ToastClose}
          />
        )}
      </div>
    </div>
  );
};
