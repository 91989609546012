import "./HolidayCalendar.css";
import { dateSplit } from "../utils/util";
import moment from "moment";
import SkeletonImage from "../layouts/SkeletonImage";

export const HolidayCalendarCard = ({ data }) => {
  const { holidayClass = "" } = data;
  const fixed = holidayClass?.toLowerCase() === "fixed";
  const holidayDate = dateSplit(data?.date, "date");
  const holidayMonth = dateSplit(data?.date, "month");
  const holidayImageLoading = data?.loading;
  const floaterDay = moment(data?.date).format("dddd");

  const featureMySpaceItem = { menuImg: data?.referenceUrl?.url };

  return (
    <div
      className={`calendar-card ${
        fixed ? "calendar-card-fixed" : "calendar-card-floater"
      }`}
    >
      <div className="date-container">
        <div className={fixed ? "month-text" : "month-text month-text-fixed"}>
          {holidayMonth}
        </div>
        <div className={fixed ? "date-text" : "date-text  date-text-fixed"}>
          {holidayDate}
        </div>
      </div>
      <div className="holiday-details-container">
        <div
          className={
            fixed ? "holiday-title" : "holiday-title holiday-title-fixed "
          }
        >
          {data?.name}
        </div>
        <div
          className={`${
            fixed ? "day-text" : "day-text day-text-fixed"
          } day-text-floater`}
        >
          {floaterDay} | {data?.holidayClass}
        </div>
      </div>
      <div className="img-container">
        {/* <img src={data?.referenceUrl?.url} className="holiday-img" alt="url" /> */}
        <SkeletonImage
          featureMySpaceItem={featureMySpaceItem}
          className="holiday-img"
        />
      </div>
    </div>
  );
};
