import "./index.css";
import { LEAVE_MANAGEMENT } from "../../constants";
import {
  LeaveTypeBalanceCard,
  HolidayQuoteCard,
  HolidayCarousel,
  AbsenceRequestsCard,
  UpcomingHolidaysCard,
  LeaveRequestCard,
} from "./components";
import {
  getLMLandingPage,
  getLeaveTemplate,
  getLeaveTypes,
  getStaticLeaveManagement,
  getUpcomingLeaves,
  submitLeaveRequest,
  clearLeaveSubmitData,
  deleteLeave,
  createLeaveForLbFloater,
} from "../../redux/actions/leavemanagement";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import { Skeleton } from "antd";
import {
  PreviewPopup,
  GamificationSuccessPopup,
  CancelLeaveModal,
} from "./components/Modals";
import { Spin } from "antd";
import ToastMessage from "../common/ToastMsg/toastMessage";
import ToastClose from "../../themes/assets/images/performance/svg/ToastClose.svg";
import ToastInfo from "../../themes/assets/images/performance/svg/ToastInfo.svg";
import ToastError from "../../themes/assets/images/performance/svg/ToastError.svg";
import { getLoggedInUserData } from "../utils/util";
import { isEmpty } from "lodash";

const LeaveManagement = () => {
  const dispatch = useAppDispatch();
  const {
    landingpageData,
    leaveTemplate,
    leaveTypesData,
    upcomingLeaves,
    staticLeaveBanner,
    templateObj,
    submitLeaveLoadnig,
    submitLeaveObj,
    loadingList,
    leaveTemplateLoading,
    previewData,
    checkedHolidays,
    requestingdays,
    deleteLeaveLoading,
    uploadedFile,
    deleteLeaveData,
  } = useAppSelector((state: any) => state?.leavemanagement);
  const [leaveType, setLeaveType] = useState<{
    displayName: string;
    leaveTypeId: string;
  }>();
  const [isPreviewPopupVisible, setIsPreviewPopupVisible] = useState(false);
  const [previewObjData, setPreviewObjData] = useState<any>({});
  const [previewCommonObjData, setPreviewCommonObjData] = useState({});
  const [visibleReminder, setVisibleReminder] = useState(false);
  const [visibleCancel, setVisibleCancel] = useState(false);
  const [showGamificationPopup, setShowGamificationPopup] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const reminderSuccess = submitLeaveObj?.status !== "ERROR";
  const cancelApproved = deleteLeaveData?.status !== "ERROR";
  const userData = getLoggedInUserData();
  const handleClose = () => {
    setVisibleReminder(false);
    dispatch(clearLeaveSubmitData({}));
    setShowGamificationPopup(false);
    if (reminderSuccess) {
      dispatch(getLMLandingPage({}));
      dispatch(getLeaveTypes({}));
      dispatch(getUpcomingLeaves({}));
      dispatch(getStaticLeaveManagement({}));
    }
  };

  const {
    abcenseList,
    leaveBalanceList,
    leaveManagementCalendarViewList,
    floaterBannerList,
  } = landingpageData;
  const templateId = leaveType?.leaveTypeId ?? leaveTypesData?.[0]?.leaveTypeId;
  useEffect(() => {
    dispatch(getLMLandingPage({}));
    dispatch(getLeaveTypes({}));
    dispatch(getUpcomingLeaves({}));
    dispatch(getStaticLeaveManagement({}));
  }, []);
  useEffect(() => {
    if (templateId)
      dispatch(
        getLeaveTemplate({
          templateId: templateId,
        })
      );
  }, [templateId]);

  useEffect(() => {
    if (Object.keys(submitLeaveObj)?.length > 0) {
      if (reminderSuccess) {
        setShowGamificationPopup(true);
      } else {
        setVisibleReminder(true);
      }
    }
  }, [submitLeaveObj]);

  useEffect(() => {
    if (Object.keys(deleteLeaveData)?.length > 0) {
      setVisibleCancel(true);
      dispatch(getLMLandingPage({}));
    }
  }, [deleteLeaveData]);

  const handleCancelClose = () => {
    setCancelOpen(false);
  };
  const handleSuccessClose = () => {
    setVisibleCancel(false);
    dispatch(clearLeaveSubmitData({}));
  };

  const SkeletonLeave = () => (
    <Skeleton.Node
      style={{
        width: "100%",
        height: "100%",
      }}
      children={false}
      active={true}
    />
  );
  const SkeletonLeaveRequest = () => (
    <Skeleton.Node
      style={{
        width: "100%",
        height: "80%",
      }}
      children={false}
      active={true}
    />
  );

  const showFloaterBalance = leaveBalanceList?.length
    ? leaveBalanceList.find(
      ({ timeAccountType }) => timeAccountType === "FLOAT_HOL_TCT"
    )?.leaveBalance
    : false;

  const clickOnPriview = (previewObjData, previewCommonObjData, value) => {
    setPreviewObjData({
      ...previewObjData,
      showFloater:
        previewObjData?.leaveTypeDisplayName
          ?.toLowerCase()
          ?.includes("floater") && showFloaterBalance === "0",
    });
    setIsPreviewPopupVisible(value);
    setPreviewCommonObjData(previewCommonObjData);
  };

  const apiObj = () => {
    const filterJsonkeyObj = previewObjData?.questionListArray
      ?.map((item) => {
        if (item?.jsonKey) {
          if (typeof item?.answerList === "object") {
            return {
              [item?.jsonKey]: !item?.answerList?.attachmentId
                ? item?.answerList
                : null,
            };
          } else {
            return { [item?.jsonKey]: item?.answerList };
          }
        } else {
          return null;
        }
      })
      .filter((i) => i != null)
      .flat();
    return filterJsonkeyObj;
  };

  const clickOnSubmit = () => {
    try {
      if (previewObjData?.quickApply) {
        let obj = {
          leaveTypeId: previewObjData?.leaveTypeId,
          startEndDate: `${previewObjData?.startDate},${previewObjData?.endDate}`,
          userId: userData?.userId,
        };
        dispatch(submitLeaveRequest(obj));
      } else {
        const mergedObj = [previewCommonObjData, ...apiObj()];
        const obj = Object.assign({}, ...mergedObj);
        obj.displayRequesting = previewObjData?.displayRequesting || obj?.displayRequesting || ""
        dispatch(submitLeaveRequest(obj));
        if (!isEmpty(checkedHolidays)) {
          const fh: any =
            Object.values(checkedHolidays).reduce((acc: any, item: any) => {
              if (item.checked) {
                return [
                  ...acc,
                  {
                    leaveTypeId: item.leaveTypeId,
                    startEndDate: `${item.holidayDate},${item.holidayDate}`,
                  },
                ];
              }
              return acc;
            }, []) || [];
          if (fh?.length) {
            const createLeaveLbFloaterPayload = {
              leaveTypeId: obj.leaveTypeId,
              fh,
              startEndDate: obj.startEndDate,
              reason: obj.reason,
              comment: obj.comment,
              displayRequesting:
                obj?.displayRequesting || requestingdays?.result?.requesting,
            };
            dispatch(createLeaveForLbFloater(createLeaveLbFloaterPayload));
          }
        }
      }
      setIsPreviewPopupVisible(false);
    } catch (error) { }
  };

  const handleCancelConfirm = () => {
    const leaveId = previewObjData?.leaveId;
    const statusLeave =
      previewObjData?.workflowSteps?.displayProperties?.displayNameForEmployee;
    setCancelOpen(false);
    dispatch(deleteLeave(leaveId));
  };
  const handleEditable = () => {
    setIsEditable(true);
    setIsPreviewPopupVisible(false);
  };

  const clickOnFloaterBaner = (floater) => {
    const floaterDetails = {
      leaveTypeId: floater?.leaveTypeId,
      startDate: floater?.holidayDate,
      endDate: floater?.holidayDate,
      displayRequesting: "1 day",
      leaveTypeDisplayName: floater?.type,
      displayName: floater?.displayName,
      showFloater: showFloaterBalance === "0",
      workflowSteps: {
        displayProperties: {
          displayNameForEmployee: floater?.type,
        },
      },
      errorMessage: floater?.errorMessage,
      quickApply: true,
    };
    setPreviewObjData(floaterDetails);
    setIsPreviewPopupVisible(true);
  };

  return (
    <div
      id="leave-management-top-container"
      className="leave-management-top-container"
    >
      <h1
        id="leave-management-main-heading"
        className="leave-management-main-heading"
      >
        {LEAVE_MANAGEMENT}
      </h1>
      <div
        id="leave-management-body-container"
        className="leave-management-body-container"
      >
        <div
          id="leave-management-left-container"
          className="leave-management-left-container"
        >
          <div
            id="leave-management-top-left-conatiner"
            className="leave-management-top-left-conatiner"
          >
            <div
              id="leave-management-leave-balance-card"
              className="leave-management-leave-balance-card"
            >
              {loadingList ? (
                <SkeletonLeave />
              ) : (
                <LeaveTypeBalanceCard
                  leaveBalanceList={leaveBalanceList || []}
                />
              )}
            </div>
            <div
              id="leave-management-holiday-quotecard"
              className="leave-management-holiday-quotecard"
            >
              {loadingList ? (
                <SkeletonLeave />
              ) : (
                <HolidayQuoteCard staticLeaveBanner={staticLeaveBanner} />
              )}
            </div>
          </div>
          <div
            id="leave-management-leave-request-card"
            className="leave-management-leave-request-card"
          >
            {loadingList ? (
              <SkeletonLeaveRequest />
            ) : (
              <LeaveRequestCard
                calendarData={leaveManagementCalendarViewList || []}
                leaveType={leaveType || {}}
                setLeaveType={setLeaveType}
                leavetypeId={templateId}
                clickOnPriview={(data, previewCommonObjData, value) =>
                  clickOnPriview(data, previewCommonObjData, value)
                }
                editable={isEditable ? previewObjData : null}
              />
            )}
          </div>
        </div>
        <div
          id="leave-management-right-container"
          className="leave-management-right-container"
        >
          {floaterBannerList?.length > 0 && (
            <div
              id="leave-management-holiday-carousel-card"
              className="leave-management-holiday-carousel-card"
            >
              {loadingList ? (
                <SkeletonLeave />
              ) : floaterBannerList?.length ? (
                <HolidayCarousel
                  floaterBannerList={floaterBannerList || []}
                  clickOnFloaterBaner={(item) => clickOnFloaterBaner(item)}
                />
              ) : (
                ""
              )}
            </div>
          )}
          {/* <div
            id="leave-management-upcoming-holidays-card"
            className="leave-management-upcoming-holidays-card"
          > */}
          {loadingList ? <SkeletonLeave /> : <UpcomingHolidaysCard />}
          {/* </div> */}
          <div
            id="leave-management-absence-request-card"
            className="leave-management-absence-request-card"
          >
            {loadingList ? (
              <SkeletonLeaveRequest />
            ) : (
              <AbsenceRequestsCard
                absenceList={abcenseList || []}
                clickOnPriview={(prevObj) => {
                  const tempQuestionListArray =
                    prevObj?.preview?.questionList?.map((questionItem) => {
                      if (
                        questionItem?.type === "DROPDOWN" ||
                        questionItem?.type === "RADIO_DROPDOWN" ||
                        questionItem?.type === "RADIO"
                      ) {
                        return {
                          ...questionItem,
                          answer:
                            prevObj?.floaterName !== null
                              ? prevObj?.floaterName
                              : questionItem?.jsonKey ===
                                "fractionQuantityTimeSpan"
                                ? prevObj[`fractionQuantityName`]
                                : prevObj[`${questionItem?.jsonKey}DisplayName`],
                        };
                      } else {
                        return {
                          ...questionItem,
                          answer: prevObj[questionItem?.jsonKey],
                        };
                      }
                    });
                  const leaveData = {
                    ...prevObj,
                    questionListArray: tempQuestionListArray,
                  };
                  setPreviewObjData(leaveData);
                  setIsPreviewPopupVisible(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <PreviewPopup
        isVisible={isPreviewPopupVisible}
        clickOnClose={() => {
          setIsPreviewPopupVisible(false);
          setCancelOpen(true);
        }}
        clickOnCloseIcon={() => setIsPreviewPopupVisible(false)}
        templateObj={templateObj}
        previewData={previewObjData}
        clickOnSubmit={() => clickOnSubmit()}
        previewCommonObjData={previewCommonObjData}
        previewPage={"Landing-Page"}
        checkedHolidays={checkedHolidays}
        editable={handleEditable}
        uploadedFile={uploadedFile}
      />
      <Spin spinning={submitLeaveLoadnig} fullscreen />
      {visibleReminder && (
        <ToastMessage
          closeAction={handleClose}
          customToastArea={
            reminderSuccess
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={reminderSuccess ? ToastInfo : ToastError}
          toastMsgTitle={
            reminderSuccess ? submitLeaveObj?.title : submitLeaveObj?.title
          }
          toastMsgPara={submitLeaveObj?.genericMessage}
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      {visibleCancel && (
        <ToastMessage
          closeAction={handleSuccessClose}
          customToastArea={
            cancelApproved
              ? "custom-toast-area skills-extracted"
              : "custom-toast-area no-skills-extracted"
          }
          customToastLeftArea={"custom-left-area"}
          icon={cancelApproved ? ToastInfo : ToastError}
          toastMsgTitle={!cancelApproved ? "FAILED" : deleteLeaveData?.message}
          toastMsgPara={deleteLeaveData?.genericMessage}
          customToastRightArea={"close-btn-area"}
          closeIcon={ToastClose}
        />
      )}
      <GamificationSuccessPopup
        isVisible={showGamificationPopup}
        handleClose={handleClose}
        // points={`${submitLeaveObj?.points}`}
        // title={submitLeaveObj?.title}
        // description={submitLeaveObj?.genericMessage}
        submitLeaveObj={submitLeaveObj}
        leaveType={previewData?.leaveType?.leaveTypeId}
      />
      <CancelLeaveModal
        openCancel={cancelOpen}
        closeCancel={handleCancelClose}
        closeCancelConfirm={handleCancelConfirm}
      />
    </div>
  );
};

export default LeaveManagement;
