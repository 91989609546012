import { createAsyncThunk } from "@reduxjs/toolkit";
import leavemanagement from "../services/dex";

//get leavemanagement landingpage action
export const getLMLandingPage = createAsyncThunk(
  "getLMLandingPage",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api/v1/dex/leave/landing-page`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLeaveTypes = createAsyncThunk(
  "getLeaveTypes",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api/v1/dex/leave/types`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLeaveTemplate = createAsyncThunk(
  "getLeaveTemplate",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api/v1/dex/leave/leave_template?categoryName=${args.templateId}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUpcomingLeaves = createAsyncThunk(
  "getUpcomingLeaves",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api/v1/dex/leave/holiday-calendar/v2?tag=UPCOMING&countryCode=&locationCode=&isWEB=TRUE`;

    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const dynamicApiCall = createAsyncThunk(
  "dynamicApiCall",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api${args.url}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const dynamicApiCallReason = createAsyncThunk(
  "dynamicApiCallReason",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api${args.url}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const dynamicApiCallLeave = createAsyncThunk(
  "dynamicApiCallLeave",
  async (leaveBalanceCheck: any, { rejectWithValue }) => {
    const url = `leavemanagement/api${leaveBalanceCheck?.leaveBalanceCheck?.link}${leaveBalanceCheck?.leaveBalanceCheck?.templateID}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const dynamicApiCallRelation = createAsyncThunk(
  "dynamicApiCallRelation",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api${args.url}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRequestingDays = createAsyncThunk(
  "getRequestingDays",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api${args.url}${args.startDate}&leaveTypeId=${args.leaveTypeId}&endDate=${args.endDate}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getStaticLeaveManagement = createAsyncThunk(
  "getStaticLeaveManagement",
  async (args: any, { rejectWithValue }) => {
    const url =
      "leavemanagement/api/v1/dex/leave/static-contents?category=EMPLOYEE_LANDING_PAGE&subCategory=LM_WEB_LANDING_PAGE";
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAbsentRequestLanding = createAsyncThunk(
  "getAbsentRequestLanding",
  async (landingData: any, { rejectWithValue }) => {
    const { status, start, count } = landingData;
    const url = `leavemanagement/api/v1/leave/web/view-all-emp?status=${status}&start=${start}&count=${count}`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteLeave = createAsyncThunk(
  "deleteLeave",
  async (leaveId: any, { rejectWithValue }) => {
    const url = `leavemanagement/api/v1/dex/leave/${leaveId}`;
    try {
      const response = await leavemanagement.delete(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const submitLeaveRequest = createAsyncThunk(
  "submitLeaveRequest",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api/v1/dex/leave`;
    try {
      const response = await leavemanagement.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Clear leave Submit Data
export const clearLeaveSubmitData = createAsyncThunk(
  "clearLeaveSubmitData",
  async (args: any, { rejectWithValue }) => {
    return {};
  }
);

//Create Leave For Lb Floater on Submit Data
export const createLeaveForLbFloater = createAsyncThunk(
  "createLeaveForLbFloater",
  async (args: any, { rejectWithValue }) => {
    const url = `leavemanagement/api/v1/dex/leave/createLeaveForLbFloater`;
    try {
      const response = await leavemanagement.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Approve and reject leave
export const approveRejectLeave = createAsyncThunk(
  "approveRejectLeave",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-approval/api/v1/leave/action`;
    try {
      const response = await leavemanagement.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
//Clear clearedToastMsgPopup
export const clearedToastMsgPopup = createAsyncThunk(
  "clearedToastMsgPopup",
  async (args: any, { rejectWithValue }) => {
    return {};
  }
);

//search userList
export const getSearchUserListLM = createAsyncThunk(
  "getSearchUserListLM",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-approval/api/v1/leave/search-users?data=${args}&isPagination=false`;
    try {
      const response = await leavemanagement.get(url);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Approve and reject leave with group actio
export const approveRejectLeaveGroup = createAsyncThunk(
  "approveRejectLeaveGroup",
  async (args: any, { rejectWithValue }) => {
    const url = `dex-approval/api/v1/leave/groupAction`;
    try {
      const response = await leavemanagement.post(url, args);
      const result = response.data;
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
