import React from "react";
import { Popover } from "antd";
import { useAppDispatch } from "../../../redux/store/redux-hooks";
import { reactions } from "../../common/svg/svg";
import amplitude from "amplitude-js";

const ReactionsPopover = (props: any) => {
  //const webLanding = useAppSelector((state:any) => state.webLanding)
  //const {web_landing_details} = webLanding
  //const reactions = web_landing_details?.webIconInfos?.reactionIcon || []
  const { activeReaction, data } = props;
  const contentId = data?.contentId;

  /*const commentHandler = (cardindex:any) =>{
          dispatch(isCommentShow({index:cardindex}))
        }*/

  /*const [activeReaction,setActiveReaction] = useState({
          icon:thumbs,
          text:"Like",
          color:"#000"
        })*/

  //const [comment,setComment] = useState('');

  /*const likesHandler = (cardindex:any,item:any) =>{
          if(activeReaction?.text === item?.iconLabel){
            setActiveReaction({
              icon:thumbs,
              text:"Like",
              color:"#000",
            })
          }else{
            setActiveReaction({
              icon:item?.activeImageUrl,
              text:item?.iconLabel,
              color:"green"
            })
          }
         dispatch(likesAction({index:cardindex}))
        }*/

  const onLikes = (item: any) => {
    props.likes(item, contentId);
  };

  /* const renderComment = (input:any)=>{
          if(input == 1) return `${input} Comment`;
          return `${input} Comments`;
        }*/

  const reactionsRender = (
    <div className="reactions">
      {reactions?.map((item: any, index: any) => (
        <a
          key={index}
          onClick={(e) => {
            amplitude.getInstance().logEvent("Reactions", {
              reaction_Property: props?.data?.type,
            });
            e.stopPropagation();
            onLikes(item);
          }}
        >
          <div className="reaction-sub">
            <img src={item?.activeImageUrl} />
            <p>{item.iconLabel}</p>
          </div>
        </a>
      ))}
    </div>
  );

  return (
    <li className="float-left comment-list">
      <Popover content={reactionsRender} placement="bottomLeft">
        <a
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onLikes(reactions?.[0]);
          }}
          className="like"
        >
          <img src={activeReaction?.icon} className="like-icon" alt="like" />
          {activeReaction?.text}
        </a>
      </Popover>
    </li>
  );
};

export default ReactionsPopover;
