import { useEffect, useState } from "react";
import Modal from "antd/es/modal/Modal";
import "./PreviewSheet.css";
import { PlusOutlined } from "@ant-design/icons";
import { Divider, Spin } from "antd";
import amplitude from "amplitude-js";
import {
  DELETE,
  EDIT_TITLE,
  MY_ACTIVITIES,
  MY_SPACE,
  SUBMIT,
  SURVEY_TABLE_EDIT_BTN,
  START,
  TIMESHEET_BREADCRUMB,
} from "../../../constants";
import { useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import { postCreateTimesheet } from "../../../redux/actions/timesheet";
import { dailyComment } from "../../utils/util";
import TSMProgressTracker from "../../TSMProgressTracker";

const PreviewSheet = (props) => {
  const dispatch = useAppDispatch();
  const timesheet = useAppSelector((state: any) => state.timesheet);
  const { previewInfo, payload } = timesheet?.details || {};
  const { loading } = timesheet;
  const previewData = {
    msg: "",
    data: {
      status: "Pending",
      projectTitle: "Client/Project Hours - Visa, Ford",
      displayWeek: "Aug 5 - Aug 11, 2024",
      tsProjects: [
        {
          projectName: "Visa",
          hours: 41,
          minutes: 0,
          recordsList: [
            {
              workedOn: "2024-08-05",
              dayOfWeek: "Mon",
              hours: 9,
              minutes: 20,
              leaveApplied: false,
            },
            {
              workedOn: "2024-08-06",
              dayOfWeek: "Tue",
              hours: 4,
              minutes: 10,
              leaveApplied: false,
            },
            {
              workedOn: "2024-08-07",
              dayOfWeek: "Wed",
              hours: 9,
              minutes: 10,
              leaveApplied: false,
            },
            {
              workedOn: "2024-08-08",
              dayOfWeek: "Thu",
              hours: 9,
              minutes: 10,
              leaveApplied: false,
            },
            {
              workedOn: "2024-08-09",
              dayOfWeek: "Fri",
              hours: 9,
              minutes: 10,
              leaveApplied: false,
            },
          ],
        },
        {
          projectName: "Ford",
          hours: 5,
          minutes: 10,
          recordsList: [
            {
              workedOn: "2024-08-06",
              dayOfWeek: "Tue",
              hours: 5,
              minutes: 10,
              leaveApplied: false,
            },
          ],
        },
      ],
      dailyComments: {
        Mon: "Woked on 9 hrs",
        Tue: "Woked on Both visa and ford",
        Wed: "Woked on 9 hrs",
        Thu: "Woked on 9 hrs",
        Fri: "Woked on 9 hrs",
      },
      overallComment: "Time Sheet submitted for 5 Aug week.",
    },
    error: null,
    status: "SUCCESS",
  };
  const navigate = useNavigate();
  const previewShow = previewData?.data;
  const {
    openPreview,
    closePreview,
    pageName,
    deleteHandlerPreview,
    editHandler,
  } = props;
  const [pageState, setPageState] = useState(false);
  useEffect(() => {
    if (pageName === MY_ACTIVITIES) {
      setPageState(!pageState);
    }
  }, [pageName]);
  const DailyComments = Object.entries(previewData?.data || {}).map(
    ([day, comment]) => (
      <div>
        <span className="preview_daily_day">{day} </span>
        {/* <span className="preview_daily_day_comment">: {comment}</span> */}
      </div>
    )
  );

  const handleSubmit = () => {
    try {
      amplitude.getInstance().logEvent("TimeSheet Created");
      dispatch(postCreateTimesheet({ payload }))
        .unwrap()
        .then(() => {
          amplitude.getInstance().logEvent("Successful Timesheet Creation");
          navigate(`/${START}/${MY_SPACE}/${TIMESHEET_BREADCRUMB}`);
        });
    } catch (error) {
      amplitude.getInstance().logEvent("UnSuccessful Timesheet Creation");
      return;
    }
  };
  const {
    displayWeek = "",
    projectTitle = "",
    tsProjects = [],
    totalTime = { hours: "", minutes: "" },
  } = previewInfo || {};
  console.log("previewInfo", previewInfo);
  return (
    <Modal
      open={openPreview}
      onCancel={closePreview}
      footer={false}
      closeIcon={true}
      centered={true}
      className="preview_tsm_modal"
    >
      <Spin spinning={loading}>
        <div>
          <div className="preview_timesheet">
            <div>{projectTitle}</div>
            <div className="preview_time_module">
              <div className="preview_timesheet_time">{displayWeek}</div>
              {pageState && (
                <div
                  className={`preview_display_status ${previewShow?.status}`}
                >
                  {previewShow?.status}
                </div>
              )}
            </div>
          </div>
          <div className="preview-tsm-borderbox">
            {
              // tsProjects?.length > 0
              //   ?
              tsProjects?.map((preview, index) => (
                <div>
                  <div className="preview_company_title">
                    <div>
                      {index + 1}. {preview?.projectName}
                    </div>
                    <div>{`${preview?.hours
                      ?.toString()
                      .padStart(2, "0")}:${preview?.minutes
                      ?.toString()
                      .padStart(2, "0")} hrs`}</div>
                  </div>
                  <div className="preview_work_display">
                    {preview?.recordsList?.map((dates, index) => {
                      let obj = dates?.task?.find((o) => o.task === "PTO");
                      return (
                        <>
                          {dates?.dayTotalHour <= 0 &&
                          dates?.dayTotalMinutes <= 0 &&
                          (index === 5 || index === 6) ? null : (
                            <div className="preview_dual_work_timesheet">
                              <div className="leave-holiday-preview-view">
                                <div>{dates?.dayOfWeek}</div>
                                {dates?.leaveApplied || dates?.halfDay ? (
                                  <div className="leave-holiday-preview-text">{`(${dates?.leaveLabel})`}</div>
                                ) : obj !== undefined && obj?.task === "PTO" ? (
                                  <div className="leave-holiday-preview-text-pto">{`(Yet to Apply)`}</div>
                                ) : null}
                                {dates?.holiday ? (
                                  <div className="leave-holiday-preview-text">{`(${dates?.holidayName})`}</div>
                                ) : null}
                              </div>

                              <div>{`${dates?.dayTotalHour
                                ?.toString()
                                .padStart(2, "0")}:${dates?.dayTotalMinutes
                                ?.toString()
                                .padStart(2, "0")} hrs`}</div>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>

                  {dailyComment(preview?.recordsList) && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_total_tsm">Daily Comment</div>
                      {preview?.recordsList?.map(
                        (comment) =>
                          comment?.comments && (
                            <>
                              <div className="preview_day_daily">
                                <span className="preview_daily_day">
                                  <span>{comment.dayOfWeek}</span> :{" "}
                                  <span className="comments">
                                    {comment.comments}
                                  </span>
                                </span>
                              </div>
                            </>
                          )
                      )}
                    </>
                  )}
                  {preview.overallComments && (
                    <>
                      <Divider className="tsm_preview_divider" />
                      <div className="preview_overall_tsm">
                        <div className="preview_total_tsm overall">
                          Overall Comment
                        </div>
                        <div className="preview_total_tsm_content">
                          {preview.overallComments}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))
            }
          </div>
          <div className="preview_total_tsm">
            <div>Total</div>
            <div>
              {" "}
              {`${totalTime?.hours
                ?.toString()
                .padStart(2, "0")}:${totalTime?.minutes
                ?.toString()
                .padStart(2, "0")} hrs`}
            </div>
          </div>
          <div>
            {timesheet?.actions != null ? (
              <TSMProgressTracker actions={timesheet?.actions} />
            ) : null}
          </div>
          <div className="preview_buttons_timesheet">
            {pageState ? (
              <>
                <button
                  onClick={deleteHandlerPreview}
                  className="add_manager_delete"
                >
                  {DELETE}
                </button>
                <button className="timesheet_submit_edit" onClick={editHandler}>
                  {SURVEY_TABLE_EDIT_BTN}
                </button>
              </>
            ) : (
              <>
                {/* <button className="add_manager">
                <PlusOutlined /> Additional Manager
              </button> */}
                <button className="timesheet_submit-delete-transparent" />
                <button className="timesheet_submit" onClick={handleSubmit}>
                  {SUBMIT}
                </button>
              </>
            )}
          </div>
        </div>
      </Spin>
    </Modal>
  );
};
export default PreviewSheet;
