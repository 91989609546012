// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Button, Modal, Spin, Popover, Dropdown, Input, Avatar } from "antd";
import "./CommentSection.css";
import options from "../../themes/assets/images/svg/options.svg";
import { useAppDispatch, useAppSelector } from "../../redux/store/redux-hooks";
import {
  deleteComments,
  editCommentAction,
  getComments,
  likeComment,
  postComments,
  unlikeComment,
} from "../../redux/actions/card_details";
import graySend from "../../themes/assets/images/svg/graySend.svg";
import {
  formatTimeDifference,
  getDateFor,
  getFromLocalStorage,
  getLoggedInUserData,
  get_time_difference,
  get_time_difference_mm,
} from "../utils/util";
import PapperPlaneTilt from "../../themes/assets/images/svg/PaperPlaneTilt.svg";
import { addReply, commentAction } from "../../redux/reducers/card_details";
import likeIconBlue from "../../themes/assets/images/svg/likeIconBlue.svg";
import {
  LOGGEDINUSERDETAILS,
  hourDateString,
  minsDateString,
  secondsDateString,
} from "../../constants";
import AvatarProfile from "../Avatar/AvatarProfile";
import amplitude from "amplitude-js";

const { TextArea } = Input;

const OptionPopUp = (props: any) => {
  const items = [
    { key: "1", label: "Edit" },
    { key: "2", type: "divider" },
    { key: "3", label: "Delete" },
  ];
  const handleMoreClick = (e: any) => {
    if (e["key"] === "1") {
      props?.onClickEdit();
    } else if (e["key"] === "3") {
      props?.onClickDelete();
    }
  };
  const menuProps = {
    items: items,
    onClick: handleMoreClick,
  };

  const OptionContent = (
    <>
      <div onClick={props?.onClickEdit} className="editBox">
        <div className="optionText">Edit</div>
      </div>
      <div onClick={props?.onClickDelete} className="deleteBox">
        <div className="optionText">Delete</div>
      </div>
    </>
  );

  return (
    <Dropdown
      // open={props.isOptionOpen}
      // onOpenChange={props?.onOpenOption}
      overlayClassName={"optionModal"}
      menu={menuProps}
      placement="bottomRight"
      trigger={["click"]}
    >
      <img
        onClick={props?.onClickOption}
        src={options}
        alt="options"
        className="optionsClass"
      />
    </Dropdown>
    // <Popover
    //   // open={props.isOptionOpen}
    //   // onOpenChange={props?.onOpenOption}
    //   overlayClassName={"optionModal"}
    //   placement="bottomRight"
    //   content={OptionContent}
    //   trigger="click"
    // >

    //   <img
    //     onClick={props?.onClickOption}
    //     src={options}
    //     alt="options"
    //     className="optionsClass"
    //   />
    // </Popover>
  );
};

const CommentCard = (props: any) => {
  const dispatch = useAppDispatch();
  const { data } = props;
  const [replyActive, setReplyActive] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [editActive, setEditActive] = useState(false);
  const [editText, setEditText] = useState(data?.comment);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const commentCard = props?.indented
    ? "commentCard paddingLeft bottomBorder"
    : props?.isLast
    ? "commentCard"
    : "commentCard";

  const onClickEdit = () => {
    setEditActive(true);
    setEditText(data?.comment);
  };
  const onClickCancelEdit = () => {
    setEditActive(false);
  };

  const onClickDelete = () => {
    setDeletePopUp(true);
  };

  const onClickCancelDelete = () => {
    setDeletePopUp(false);
  };

  const onClickSubmitReply = () => {
    if (data?.comment === editText) return;
    dispatch(
      editCommentAction({
        id: data?.id,
        category: data?.category,
        comment: editText,
        level: data?.level,
        index: props?.index,
        parentIndex: props?.parentIndex,
      })
    )
      .unwrap()
      .then((s) => {
        setEditActive(false);
      });
  };

  const onClickViewAllReplies = () => {
    dispatch(
      getComments({
        contentId: data?.id,
        category: "COMMENT",
        index: props?.index,
      })
    )
      .unwrap()
      .then((s) => {
        props?.setShowViewAll(false);
      });
  };

  const onClickSendReply = () => {
    if (replyText?.length <= 0) return;
    dispatch(
      postComments({
        category: "COMMENT",
        comment: replyText,
        level: "2",
        referenceId: props?.data?.id,
      })
    )
      .unwrap()
      .then((success: any) => {
        setReplyText("");
        setReplyActive(false);
        dispatch(addReply({ response: success, index: props?.index }));
        props?.setShowViewAll(false);
      });
  };

  const onClickConfirmDelete = () => {
    dispatch(
      deleteComments({
        data: data,
        index: props?.index,
        parentIndex: props?.parentIndex,
      })
    )
      .unwrap()
      .then((e) => {
        setDeletePopUp(false);
      });
  };

  const onClickReply = () => {
    setReplyActive(true);
  };

  const onClickLike = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (data?.likeIndicator == true) {
      if (props?.indented) {
        dispatch(
          unlikeComment({
            category: "COMMENT",
            referenceId: data?.id,
            index: props?.index,
            parentIndex: props?.parentIndex,
          })
        );
      } else
        dispatch(
          unlikeComment({
            category: "COMMENT",
            referenceId: data?.id,
            index: props?.index,
          })
        );
    } else {
      if (props?.indented) {
        dispatch(
          likeComment({
            category: "COMMENT",
            referenceId: data?.id,
            index: props?.index,
            parentIndex: props?.parentIndex,
          })
        );
      } else
        dispatch(
          likeComment({
            category: "COMMENT",
            referenceId: data?.id,
            index: props?.index,
          })
        );
    }
  };

  const onClickHideReplies = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    props?.setShowViewAll(true);
  };
  const onClickCancelReply = () => {
    setReplyActive(false);
    setReplyText("");
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={commentCard}
      >
        <div className="profile">
          <AvatarProfile
            profilePic={data?.mediaresponse?.url}
            name={data?.userName}
            size={40}
          />
        </div>
        {!editActive ? (
          <div className="commentContent">
            <div className="userinfo">
              <div className="name">{data?.userName}</div>
              <div className="seperatorDiv"></div>
              <div className="time">
                {get_time_difference(data?.postedOn) < 24
                  ? get_time_difference(data?.postedOn) <= 0
                    ? get_time_difference_mm(data?.postedOn) <= 1
                      ? secondsDateString
                      : get_time_difference_mm(data?.postedOn) + minsDateString
                    : get_time_difference(data?.postedOn) + hourDateString
                  : getDateFor(data?.postedOn || "")}
              </div>
            </div>
            <div className="content">{data?.comment}</div>
            <div className="statistics">
              {data?.likesCount > 0 && (
                <div className="likecard">
                  <img src={likeIconBlue} className="likeIcon" />
                  <div className="likeText">{data?.likesCount}</div>
                </div>
              )}
              <div onClick={onClickLike} className="likecard">
                <div
                  className={
                    data?.likeIndicator ? "likeTextActive" : "likeText"
                  }
                >
                  Like
                </div>
              </div>
              {!props?.indented && (
                <div onClick={onClickReply} className="likecard">
                  <div className="likeText">Reply</div>
                </div>
              )}
            </div>
            {data?.commentsCount > 0 && !props?.indented ? (
              <div
                onClick={
                  props?.showViewAll
                    ? onClickViewAllReplies
                    : onClickHideReplies
                }
                className="viewAll"
              >
                {props?.showViewAll
                  ? `View All ${data?.commentsCount} Replies`
                  : `Hide All ${data?.commentsCount} Replies`}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="commentContent">
            <div className="userinfo">
              <div className="name">{data?.userName}</div>
              <div className="seperatorDiv"></div>
              <div className="time">
                {get_time_difference(data?.postedOn) < 24
                  ? get_time_difference(data?.postedOn) <= 0
                    ? get_time_difference_mm(data?.postedOn) <= 1
                      ? secondsDateString
                      : get_time_difference_mm(data?.postedOn) + minsDateString
                    : get_time_difference(data?.postedOn) + hourDateString
                  : getDateFor(data?.postedOn || "")}
              </div>
            </div>
            <div className="text_area-reply">
              <TextArea
                placeholder={`Edit your comment`}
                name="comment-box"
                rows={1}
                cols={100}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                value={editText}
                onChange={(data: any) => {
                  setEditText(data?.target?.value);
                }}
                autoSize
              ></TextArea>
            </div>
            <div className="editButtons">
              <Button
                className="submitButton"
                onClick={onClickSubmitReply}
                size={"middle"}
              >
                Save Changes
              </Button>
              <Button
                className="cancelButton"
                onClick={onClickCancelEdit}
                size={"middle"}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
        {!editActive && (
          <div className="commentOptions">
            {props?.userData?.userId === props?.data?.postedBy && (
              <OptionPopUp
                onClickDelete={onClickDelete}
                onClickEdit={onClickEdit}
              />
            )}
          </div>
        )}
      </div>
      {replyActive && (
        <div className="replyCard">
          <div className="profile-pic">
            <AvatarProfile
              name={props?.userData?.displayName}
              size={40}
              profilePic={""}
            />
          </div>
          {/* <img src={CommentProfile} alt="profile-pic" className="profile-pic" /> */}

          <div className="replyContents">
            <div className="replyName">{props?.userData?.displayName}</div>
            <div className="text_area-reply">
              <TextArea
                placeholder={`Replying to ${data?.userName}`}
                name="comment-box"
                className="comment-box"
                rows={2}
                cols={100}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                value={replyText}
                autoSize
                onChange={(data: any) => setReplyText(data?.target?.value)}
              ></TextArea>
              <img
                onClick={onClickSendReply}
                className="sendReply"
                src={replyText?.length > 0 ? PapperPlaneTilt : graySend}
              />
            </div>
            <Button
              className="cancelButton"
              onClick={onClickCancelReply}
              size={"middle"}
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      <Modal
        className="deleteModal"
        title="Delete Comment?"
        open={deletePopUp}
        onCancel={onClickCancelDelete}
        onOk={onClickConfirmDelete}
        okText={"Confirm"}
      >
        <div className="modalTitleDivider"></div>
        <div className="deleteConfirmText">
          Are you sure you want to delete your comment? All likes and replies on
          this comment will also be removed.
        </div>
      </Modal>
    </>
  );
};

const CommentCardSection = (props: any) => {
  const {
    index,
    indented,
    data,
    isLast,
    setPostCommentBody,
    message,
    userData,
  } = props;
  const [showViewAll, setShowViewAll] = useState(true);
  return (
    <div className="bottomBorder">
      <CommentCard
        key={index}
        index={index}
        indented={indented}
        data={data}
        isLast={isLast}
        setPostCommentBody={setPostCommentBody}
        message={message}
        showViewAll={showViewAll}
        setShowViewAll={setShowViewAll}
        userData={userData}
      />
      {data?.children?.length > 0 &&
        !showViewAll &&
        data?.children?.map((child: any, i: any, childArr: any) => {
          return (
            <CommentCard
              key={i}
              index={i}
              parentIndex={index}
              indented={child?.level !== 1}
              data={child}
              isLast={false}
              setPostCommentBody={setPostCommentBody}
              message={message}
              userData={userData}
            />
          );
        })}
    </div>
  );
};

const CommentSection = (props: any) => {
  const dispatch = useAppDispatch();
  const [textBoxData, setTextBoxData] = useState("");
  const [isCommentFocused, setIsCommentFocused] = useState(false);
  const userData = getLoggedInUserData();
  const [postCommentBody, setPostCommentBody] = useState({
    category: "CONTENT",
    comment: textBoxData,
    level: "1",
    referenceId: props?.data?.contentId,
    userName: "",
  });
  const commentsData: any = useAppSelector((state) => state?.cardDetails);
  const { comments, commentsLoading, commentsError } = commentsData;

  useEffect(() => {
    dispatch(
      getComments({
        contentId: props?.data?.contentId,
        category: "CONTENT",
      })
    );
  }, []);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter" && isCommentFocused) {
        event.preventDefault();
        onClickSend();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const onClickSend = () => {
    if (textBoxData.length > 0) {
      amplitude.getInstance().logEvent("Comments", {
        comment_Property: props?.data?.type,
      });
      setPostCommentBody({
        category: "CONTENT",
        comment: textBoxData,
        level: "1",
        referenceId: props?.data?.contentId,
        userName: "",
      });
      dispatch(
        postComments({
          category: "CONTENT",
          comment: textBoxData,
          level: "1",
          referenceId: props?.data?.contentId,
        })
      )
        .unwrap()
        .then((success: any) => {
          setTextBoxData("");
        });
      if (props?.index !== null && props?.index !== undefined) {
        dispatch(commentAction({ index: props?.index }));
      }
    }
  };
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        className="comments_popup"
        title="Comments"
        open={props.isModalOpen}
        onCancel={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.handleCancel();
        }}
      >
        <Spin spinning={commentsLoading}>
          <div className="panels">
            {comments?.map((item: any, index: any, arr: any) => {
              return (
                <CommentCardSection
                  key={index}
                  index={index}
                  indented={item?.level !== 1}
                  data={item}
                  isLast={index === arr?.length - 1}
                  setPostCommentBody={setPostCommentBody}
                  message={textBoxData}
                  userData={userData}
                />
              );
            })}
          </div>
        </Spin>
        <div className="commentBar">
          <AvatarProfile
            name={userData?.displayName}
            size={40}
            profilePic={""}
          />
          {/* //Add Profile pic */}
          <div className="text_area-otr">
            <TextArea
              className="textBox"
              onFocus={() => {
                setIsCommentFocused(true);
              }}
              onBlur={() => setIsCommentFocused(false)}
              placeholder={
                postCommentBody.category === "COMMENT"
                  ? `Replying to ${postCommentBody?.userName}`
                  : "Write a comment"
              }
              name="comment-box"
              rows={2}
              cols={100}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
              value={textBoxData}
              autoSize
              onChange={(data: any) => setTextBoxData(data?.target?.value)}
            ></TextArea>
            <img
              onClick={onClickSend}
              className="sendComment"
              src={textBoxData?.length > 0 ? PapperPlaneTilt : graySend}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CommentSection;
