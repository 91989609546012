import moment from "moment";
import { useSelector } from "react-redux";

let errMsg = "",
  validationPassed = true,
  validatingItem;

export const Validate = (qObject, validations, noOfDays) => {
  try {
    console.log("inside the validation engine");

    console.log(qObject);
    console.log(validations);

    errMsg = "";
    validationPassed = true;
    validatingItem = null;

    qObject?.map((item) => {
      validations?.map((vItem) => {
        if (vItem?.questionId === item?.questionId && validationPassed) {
          validatingItem = vItem;
          switch (vItem?.type) {
            case "NULL_CHECK":
              NullCheck(item?.answer);
              break;
            case "DATE_CHECK":
              DateCheck(item?.answer, noOfDays, vItem?.rule);
              break;
            case "DATE_RANGE":
              DateRange(item?.answer, noOfDays, vItem?.rule);
              break;
            case "TEXT_CHECK":
              TextCheck(item?.answer, vItem?.rule, vItem?.valueArr);
              break;

            case "NUMBER_CHECK":
              NumberCheck(noOfDays, vItem?.rule);
              break;

            case "POL_CHECK":
              PollCheck(
                item?.answerList,
                vItem?.rule,
                vItem?.valueArr,
                noOfDays
              );
              break;

            default:
              errMsg = "";
              validationPassed = true;
              break;
          }
        }
      });
    });

    if (validationPassed) {
      validations?.map((vItem) => {
        if (vItem?.type === "MULTI_RULES" && validationPassed) {
          validatingItem = vItem;
          MultiTypeCheck(qObject, vItem, noOfDays);
        }
      });
    }

    return { errMsg, validationPassed };
  } catch (error) {
    console.log(error);
    return {
      errMsg: "Something went wrong in validations",
      validationPassed: false,
    };
  }
};

export const ComponentLevelValidate = (
  answer,
  questionId,
  noOfDays,
  validationCheck
) => {
  // const validationCheck = []
  try {
    errMsg = "";
    validationPassed = true;
    validatingItem = null;

    validationCheck?.map((vItem) => {
      console.log("check", vItem?.questionId, questionId);
      if (vItem?.questionId === questionId && validationPassed) {
        validatingItem = vItem;
        console.log("type", vItem?.type);
        switch (vItem?.type) {
          case "NULL_CHECK":
            NullCheck(answer);
            break;
          case "DATE_CHECK":
            DateCheck(answer, noOfDays, vItem?.rule);
            break;
          case "DATE_RANGE":
            DateRange(answer, noOfDays, vItem?.rule);
            break;
          case "TEXT_CHECK":
            TextCheck(answer, vItem?.rule, vItem?.valueArr);
            break;

          case "NUMBER_CHECK":
            NumberCheck(noOfDays, vItem?.rule);
            break;

          case "POL_CHECK":
            PollCheck(answer, vItem?.rule, vItem?.valueArr, noOfDays);
            break;

          default:
            errMsg = "";
            validationPassed = true;
            break;
        }
      }
    });

    if (validationPassed) {
      validationCheck?.map((vItem) => {
        if (vItem?.type === "MULTI_RULES" && validationPassed) {
          validatingItem = vItem;
          MultiTypeCheck({}, vItem, noOfDays);
        }
      });
    }

    return { errMsg, validationPassed };
  } catch (error) {
    console.log(error);
    return {
      errMsg: "Something went wrong in validations",
      validationPassed: false,
    };
  }
};

//VALIDATION FUNCTION

function NullCheck(answer) {
  if (answer == null) {
    errMsg = validatingItem?.errorMessage;
    validationPassed = false;
  } else {
    validationPassed = true;
  }
}

function DateCheck(date, noOfDays, rule) {
  DateRange(date, noOfDays, rule);
}

function DateRange(date, noOfDays, rule) {
  const splittedDate = date.split(",");

  if (splittedDate?.length > 1) {
    let startDate = moment(splittedDate[0]);
    let endDate = moment(splittedDate[1]);
    switch (getOperatorType(rule)) {
      case ">":
        if (getDigits(rule)) {
          if (getDifTwoDateNumber(rule, startDate, endDate) > getDigits(rule)) {
            errMsg = validatingItem?.errorMessage;
            validationPassed = false;
          } else {
            console.log("validation passed");

            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case ">=":
        if (getDigits(rule)) {
          if (
            getDifTwoDateNumber(rule, startDate, endDate) >= getDigits(rule)
          ) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "<":
        if (getDigits(rule)) {
          if (getDifTwoDateNumber(rule, startDate, endDate) < getDigits(rule)) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "<=":
        if (getDigits(rule)) {
          if (
            getDifTwoDateNumber(rule, startDate, endDate) <= getDigits(rule)
          ) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "==":
        if (getDigits(rule)) {
          if (
            getDifTwoDateNumber(rule, startDate, endDate) === getDigits(rule)
          ) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "!=":
        if (getDigits(rule)) {
          if (
            getDifTwoDateNumber(rule, startDate, endDate) != getDigits(rule)
          ) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      default:
        errMsg = "";
        validationPassed = true;
        break;
    }
  } else {
    switch (getOperatorType(rule)) {
      case ">":
        if (getDigits(rule)) {
          if (getDateNumber(rule, splittedDate[0]) > getDigits(rule)) {
            errMsg = validatingItem?.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case ">=":
        if (getDigits(rule)) {
          if (getDateNumber(rule, splittedDate[0]) >= getDigits(rule)) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "<":
        if (getDigits(rule)) {
          if (getDateNumber(rule, splittedDate[0]) < getDigits(rule)) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "<=":
        if (getDigits(rule)) {
          if (getDateNumber(rule, splittedDate[0]) <= getDigits(rule)) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "==":
        if (getDigits(rule)) {
          if (getDateNumber(rule, splittedDate[0]) === getDigits(rule)) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      case "!=":
        if (getDigits(rule)) {
          if (getDateNumber(rule, splittedDate[0]) != getDigits(rule)) {
            errMsg = validatingItem.errorMessage;
            validationPassed = false;
          } else {
            validationPassed = true;
          }
        } else {
          validationPassed = true;
        }
        break;

      default:
        errMsg = "";
        validationPassed = true;
        break;
    }

    //
  }
}

function NumberCheck(noOfDays, rule) {
  switch (getOperatorType(rule)) {
    case ">":
      if (getDigits(rule)) {
        if (getDigits(noOfDays) > getDigits(rule)) {
          errMsg = validatingItem?.errorMessage;
          validationPassed = false;
        } else {
          validationPassed = true;
        }
      } else {
        validationPassed = true;
      }
      break;

    case ">=":
      if (getDigits(rule)) {
        if (getDigits(noOfDays) >= getDigits(rule)) {
          errMsg = validatingItem.errorMessage;
          validationPassed = false;
        } else {
          validationPassed = true;
        }
      } else {
        validationPassed = true;
      }
      break;

    case "<":
      if (getDigits(rule)) {
        if (getDigits(noOfDays) < getDigits(rule)) {
          errMsg = validatingItem.errorMessage;
          validationPassed = false;
        } else {
          validationPassed = true;
        }
      } else {
        validationPassed = true;
      }
      break;

    case "<=":
      if (getDigits(rule)) {
        if (getDigits(noOfDays) <= getDigits(rule)) {
          errMsg = validatingItem.errorMessage;
          validationPassed = false;
        } else {
          validationPassed = true;
        }
      } else {
        validationPassed = true;
      }
      break;

    case "==":
      if (getDigits(rule)) {
        if (getDigits(noOfDays) === getDigits(rule)) {
          errMsg = validatingItem.errorMessage;
          validationPassed = false;
        } else {
          validationPassed = true;
        }
      } else {
        validationPassed = true;
      }
      break;
    case "!=":
      if (getDigits(rule)) {
        if (getDigits(noOfDays) != getDigits(rule)) {
          errMsg = validatingItem.errorMessage;
          validationPassed = false;
        } else {
          validationPassed = true;
        }
      } else {
        validationPassed = true;
      }
      break;

    default:
      errMsg = "";
      validationPassed = true;
      break;
  }
}

function TextCheck(ans, rule, arr) {
  switch (getOperatorType(rule)) {
    case "==":
      if (arr.includes(ans)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }

      break;
    case "!=":
      if (!arr.includes(ans)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }

      break;

    default:
      errMsg = "";
      validationPassed = true;
      break;
  }

  console.log("coming inside");
}

function PollCheck(ans, rule, valArr, noOfDays) {
  let arr = valArr[0];
  switch (getOperatorType(rule)) {
    case "=":
      if (getDigits(rule) === getDigits(ans)) {
        checkDays(arr, noOfDays);
      } else {
        validationPassed = true;
      }
      break;
    case "==":
      if (getDigits(rule) === getDigits(ans)) {
        checkDays(arr, noOfDays);
      } else {
        validationPassed = true;
      }
      break;
    case "!=":
      if (getDigits(rule) != getDigits(ans)) {
        checkDays(arr, noOfDays);
      } else {
        validationPassed = true;
      }
      break;

    case ">=":
      if (getDigits(rule) >= getDigits(ans)) {
        checkDays(arr, noOfDays);
      } else {
        validationPassed = true;
      }
      break;

    case ">":
      if (getDigits(rule) > getDigits(ans)) {
        checkDays(arr, noOfDays);
      } else {
        validationPassed = true;
      }
      break;

    case "<":
      if (getDigits(rule) < getDigits(ans)) {
        checkDays(arr, noOfDays);
      } else {
        validationPassed = true;
      }
      break;

    case "<=":
      if (getDigits(rule) <= getDigits(ans)) {
        checkDays(arr, noOfDays);
      } else {
        validationPassed = true;
      }
      break;

    default:
      errMsg = "";
      validationPassed = true;
      break;
  }
}

function checkDays(rule, noOfDays) {
  console.log("coming inside", getOperatorType(rule));

  switch (getOperatorType(rule)) {
    case "==":
      if (getDigits(rule) === getDigits(noOfDays)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }
      break;
    case "!=":
      if (getDigits(rule) != getDigits(noOfDays)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }
      break;

    case ">=":
      if (getDigits(noOfDays) >= getDigits(rule)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }
      break;

    case ">":
      if (getDigits(noOfDays) > getDigits(rule)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }
      break;

    case "<":
      if (getDigits(noOfDays) < getDigits(rule)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }
      break;

    case "<=":
      if (getDigits(noOfDays) <= getDigits(rule)) {
        errMsg = validatingItem.errorMessage;
        validationPassed = false;
      } else {
        validationPassed = true;
      }
      break;

    default:
      errMsg = "";
      validationPassed = true;
      break;
  }
}

const operators = {
  ">": (a, b) => a > b,
  ">=": (a, b) => a >= b,
  "<": (a, b) => a < b,
  "<=": (a, b) => a <= b,
  "==": (a, b) => a === b,
  "!=": (a, b) => a !== b,
  "=": (a, b) => a === b,
};

function MultiTypeCheck(qObject, vItem, noOfDays) {
  const questionIDList = vItem.questionId.split(",");
  const ruleList = vItem.rule.split(",");
  let arr = vItem.valueArr[0];

  const operator1 = operators[getOperatorType(ruleList[0])];
  const operator2 = operators[getOperatorType(ruleList[1])];

  if (
    operator1(
      getDigits(ruleList[0]),
      getDigits(getAns(qObject, questionIDList[0]))
    ) &&
    operator2(
      getDigits(ruleList[1]),
      getDigits(getAns(qObject, questionIDList[1]))
    )
  ) {
    console.log("comgin inside ");
    checkDays(arr, noOfDays);
  } else {
    validationPassed = true;
  }
}

//UTIL FUNCTION

const getDigits = (input) => {
  if (+input.match(/\d+/)[0]) {
    return +input.match(/\d+/)[0];
  } else {
    return null;
  }
};

export const getOperatorType = (input) => {
  if (input.match(/>=|>|<=|<|==|!=|=/)[0]) {
    return input.match(/>=|>|<=|<|==|!=|=/)[0];
  } else {
    return null;
  }
};

const getDateNumber = (input, userSelectedDate) => {
  let checkCalendar = input.match(/[a-zA-Z]/);

  let noOfDays = 1;

  switch (checkCalendar[0]) {
    case "D":
      noOfDays = 1 * moment().diff(userSelectedDate, "days");
      break;
    case "W":
      noOfDays = 7 * moment().diff(userSelectedDate, "days");
      break;
    case "M":
      noOfDays = calcDaysInMonth(userSelectedDate);
      break;
    case "Y":
      noOfDays = calcDaysInYear(userSelectedDate);
      break;
    default:
      noOfDays = 1;
      break;
  }

  return noOfDays;
};

const getDifTwoDateNumber = (
  input,
  userSelectedStartDate,
  userSelectedEndDate
) => {
  let checkCalendar = input.match(/[a-zA-Z]/);

  let noOfDays = 1;

  switch (checkCalendar[0]) {
    case "D":
      noOfDays =
        1 * moment(userSelectedStartDate).diff(userSelectedEndDate, "days");
      break;
    case "W":
      noOfDays =
        7 * moment(userSelectedStartDate).diff(userSelectedEndDate, "days");
      break;
    case "M":
      noOfDays = calcDaysInMonth(userSelectedStartDate, userSelectedEndDate);
      break;
    case "Y":
      noOfDays = calcDaysInYear(userSelectedStartDate, userSelectedEndDate);
      break;
    default:
      noOfDays = 1;
      break;
  }

  return noOfDays;
};

const calcDaysInMonth = (userSelectedStartDate, userSelectedEndDate = "") => {
  if (userSelectedEndDate === "") {
    var given = moment(userSelectedStartDate, "YYYY-MM-DD");
    var current = moment().startOf("day");

    //Difference in number of days
    return Math.abs(given.diff(current, "months", true));
    // console.log(prevMonthDate);
  } else {
    var given = moment(userSelectedStartDate, "YYYY-MM-DD");
    var current = moment(userSelectedEndDate).startOf("day");

    //Difference in number of days
    return Math.abs(given.diff(current, "months", true));
    // console.log(prevMonthDate);
  }
};

const calcDaysInYear = (userSelectedStartDate, userSelectedEndDate = "") => {
  if (userSelectedEndDate === "") {
    var given = moment(userSelectedStartDate, "YYYY-MM-DD");
    var current = moment().startOf("day");

    //Difference in number of days
    return Math.abs(given.diff(current, "years", true));
    // console.log(prevMonthDate);
  } else {
    var given = moment(userSelectedStartDate, "YYYY-MM-DD");
    var current = moment(userSelectedEndDate).startOf("day");

    //Difference in number of days
    return Math.abs(given.diff(current, "years", true));
    // console.log(prevMonthDate);
  }
};

const getAns = (qObj, qId) => {
  let ans = "";

  qObj.map((item) => {
    if (item.questionId === qId) {
      ans = item.answerList;
    }
  });

  return ans;
};
