import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import n from "../navigation/Route.Names";
import "./PrivateLayout.css";
import MyWork from "../MyWork/my_work";
import Loading from "../common/loading/loading";
import ViewGoal from "../Performance/ViewGoal";
import PerformancePreview from "../Performance/Preview";
import SepationMainScreen from "../separation/Separation.Main";
import Clearance from "../separation/clearance-detail/Clearance";
import Certification from "../certifications/Certification";
import FinalHolidayCalendar from "../HolidayCalendar";
import AddCertification from "../certification_form/AddCertification";
import EditCertification from "../certification_form/EditCertification";
import EmployeeDetailPerformance from "../Performance/ManagerView/EmployeeDetails";
import Preview from "../certifications/Preview";
import "./Header.css";
import AsopMainPage from "../Asop";
import EbookIntroduction from "../EBooks/EbookIntroduction";
import EBookDetail from "../EBooks/EbookDetail";
import TeamPerformance from "../Performance/ManagerView";
import TeamsQuarterlyCheckin from "../Performance/ManagerView/QuarterlyCheckin";
import SmartGoals from "../Performance/SmartGoals";
import ChallengingGoal from "../Performance/contributeGoals";

import GoalEvaluation from "../Performance/ASPForm/GoalEvaluation";
import CompetencyEvaluation from "../Performance/ASPForm/CompetencyEvaluation";
import ManagerGoalEvaluation from "../Performance/ManagerView/EmployeeDetails/ASPForm/ManagerGoalEvaluation";
import ManagerCompetencyEvaluation from "../Performance/ManagerView/EmployeeDetails/ASPForm/ManagerCompetencyEvaluation";
import PotentialRating from "../Performance/ManagerView/EmployeeDetails/ASPForm/PotentialRating";
import CascadingEditGoal from "../Performance/CascadingGoals/CascadingEditGoals";
import CascadingViewGoal from "../Performance/CascadingGoals/CascadingViewGoal";
import Faq from "../Asop/Faq";
import UserGuide from "../Asop/UserGuide";
import HolidayCalendar from "../HolidayCalendar";
import GamificationProfile from "../gamification/components/LeaderBoard/LeaderBoardLanding/Profile";
import LockedBadges from "../gamification/components/LeaderBoard/Badges/LockedBadges";
import LockedBadgesDetail from "../gamification/components/LeaderBoard/Badges/LockedBadgesDetail";
import PointsDashboard from "../gamification/components/Points/PointsDashboard/PointsDashboard";
import amplitude from "amplitude-js";
import { useLocation } from "react-router-dom";

import CopyGoal from "../../components/Performance/CopyGoal/index";

import CascadeGoals from "../Performance/ManagerView/EmployeeDetails/CascadeGoals";
import ActiveIconDetail from "../gamification/components/LeaderBoard/Badges/ActiveIconDetail";
import {
  ASP_FORM,
  BADGES_URL,
  BARTLE_TEST_URL,
  BUSINESS_CHALLENGE_DETAIL,
  CHALLENGES,
  CREATE_BUSINESS_CHALLENGE,
  CREATE_IDEA,
  CREATE_PROGRAM,
  EARNED_BADGES,
  EDIT_BUSINESS_CHALLENGE,
  GAMIFICATION_URL,
  HOLIDAY_CALENDAR_URL,
  IDEA_DETAIL,
  IDEA_LANDING,
  INNOVATION,
  LEADERBOARD_URL,
  LOCKED_BADGES,
  MANAGAER_CASCADE_GOAL_URL,
  MY_ACTIVITIES_INNOVATION,
  POINTS_DASHBOARD_URL,
  PREVIEW_BUSINESS_CHALLENGE,
  PREVIEW_IDEA,
  PREVIEW_PROGRAM,
  START,
  TEAM_PERFORMANCE,
  TEAMS_PERFORMANCE,
  ORCHESTRATOR,
  ORCHESTRATOR_,
} from "../../constants";
import LeaveManagement from "../leave-management";
import {
  ADD_CERTIFICATION_URL,
  CERTIFICATION_URL,
  EDIT_CERTIFICATION_URL,
} from "../../constants";
import SmartGoalPreview from "../Performance/SmartGoalsPreview";
import SmartGame from "../Performance/SmartGame";
import QuarterlyCheckInManager from "../Performance/ManagerView/EmployeeDetails/quarterly-checkin-manager";
import QuarterlyCheckInGoals from "../Goals/QuarterlyCheckInGoals";
import QuarterlyCheckIn from "../Performance/quarterly-checkin/employee-flow/quarterly-checkin";
import AspForm from "../Performance/ASPForm";
import ManagerASP from "../Performance/ManagerView/ASP";
import Timesheet from "../TimeSheet/index";
import Approvals from "../Approvals/index";
import CreateTimesheet from "../TimeSheet/components/create/CreateTimeSheet";
import MyActivities from "../TimeSheet/components/MyActivities";
import EmployeeSignature from "../Performance/ASPForm/FormView/EmployeeSignature";
import ViewOverAllFormRating from "../Performance/ManagerView/ASP/ViewOverAllFormRating";
import CreateProgram from "../Innovation/Programs/CreateProgram";
import InnovationLanding from "../Innovation/InnovationLanding";
import OrchestratorLanding from "../Innovation/OrchestratorLanding";
import Challenges from "../Innovation/Challenges";
import PreviewProgram from "../Innovation/Programs/PreviewProgram";
import IdeaLanding from "../Innovation/Idea/IdeaLanding";
import BusinessChallengeDetail from "../Innovation/BusinessChallenge/BusinessChallengeDetail";
import IdeaCreate from "../Innovation/Idea/IdeaCreate";
import IdeaPreview from "../Innovation/Idea/IdeaPreview";
import IdeaDetail from "../Innovation/Idea/IdeaDetail";
import BusinessChallengeCreate from "../Innovation/BusinessChallenge/BusinessChallengeCreate";
import BusinessChallengePreview from "../Innovation/BusinessChallenge/BusinessChallengePreview";
import InnovationMyActivities from "../Innovation/MyActivities";
import Opportunities from "../opportunities";

import OpportunitiesDetail from "../opportunities/detail";
import ApplicationPreview from "../opportunities/application/ApplicationPreview";
import ApplicationForm from "../opportunities/application/applicationForm";
import Upload from "../opportunities/upload";
import AdminPanel from "../Approvals/AdminPanel";
import AdminEmployeeTimesheet from "../Approvals/AdminEmployeeTM";
import AdminPreview from "../Approvals/AdminEmployeePreview";
import Learning from "../Learning/Learning";
import AbsentRequest from "../leave-management/components/absent-request/absent-request";
// import ApplicationPreview from "../opportunities/application/ApplicationPreview";
// import ApplicationForm from "../opportunities/application/applicationForm";
// import Upload from "../opportunities/upload";

const ArchivedGoal = lazy(() => import("../Performance/ArchivedGoal/index"));
const RootLayout = lazy(() => import("../altiverse/main/main"));
const NoMatch = lazy(() => import("../common/noMatch/noMatch"));
const Home = lazy(() => import("../home/home"));
const Detail = lazy(() => import("../Detail"));
const SuccessStoryDetail = lazy(() => import("../Detail/successStoryDetail"));
const MySpace = lazy(() => import("../my_space/my_space"));
const Discover = lazy(() => import("../DiscoverDex"));
const PolicyDetail = lazy(() => import("../PolicyHub/PolicyDetail"));
const AltimetrikPhysicalAccessManagementPolicy = lazy(
  () =>
    import("../PolicyHub/StaticFiles/AltimetrikPhysicalAccessManagementPolicy")
);
const PolicyHub = lazy(() => import("../../components/PolicyHub/index"));

const Performance = lazy(() => import("../Performance"));
const NewGoal = lazy(() => import("../Performance/NewGoal"));
const NewMilestone = lazy(() => import("../Performance/NewMilestone"));
const ViewAspForm = lazy(() => import("../Performance/ASPForm/FormView/index"));
const BartleTest = lazy(() => import("../bartle_test/index"));
const GamificationHomePage = lazy(() => import("../gamification/index"));

if (
  process.env.REACT_APP_DEX_WEB_DEV_URL ===
  "https://dex-api.altimetrik.com/dexapi/"
) {
  amplitude.getInstance().init("cee88f1d55c077089d009ad11479e704");
} else {
  amplitude.getInstance().init("f9ebc2c690959d6c91bfaeaeaf097624");
}

function PrivateLayout() {
  return (
    <Routes>
      <Route
        path={`/${n.START}/`}
        element={
          <Suspense fallback={<Loading />}>
            <RootLayout />
          </Suspense>
        }
      >
        <Route
          index
          path=""
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={`${CERTIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <Certification />
            </Suspense>
          }
        />
        <Route
          path={`${HOLIDAY_CALENDAR_URL}`}
          element={<FinalHolidayCalendar />}
        />
        <Route
          path={`${ADD_CERTIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <AddCertification />
            </Suspense>
          }
        />
        <Route
          path={`${EDIT_CERTIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <EditCertification />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}`}
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:page`}
          element={
            <Suspense fallback={<Loading />}>
              <Home />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:contentType/detail/:id`}
          element={
            <Suspense fallback={<Loading />}>
              <Detail />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/detail/:id`}
          element={
            <Suspense fallback={<Loading />}>
              <Detail />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/successStorydetail`}
          element={
            <Suspense fallback={<Loading />}>
              <SuccessStoryDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:page/INTRODUCTION/:code`}
          element={
            <Suspense fallback={<Loading />}>
              <EbookIntroduction />
            </Suspense>
          }
        />
        <Route
          path={`${n.ALTIVERSE}/:page/DETAILS/:code`}
          element={
            <Suspense fallback={<Loading />}>
              <EBookDetail />
            </Suspense>
          }
        />
        <Route
          path={n.MYWORK_URL}
          element={
            <Suspense fallback={<Loading />}>
              <MyWork />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/:page`}
          element={
            <Suspense fallback={<Loading />}>
              <MyWork />
            </Suspense>
          }
        />
        <Route
          path="discover"
          element={
            <Suspense fallback={<Loading />}>
              <Discover />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}`}
          element={
            <Suspense fallback={<Loading />}>
              <MySpace />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/learning`}
          element={
            <Suspense fallback={<Loading />}>
              <Learning />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/:page`}
          element={
            <Suspense fallback={<Loading />}>
              <MySpace />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/policy_hub/:name`}
          element={
            <Suspense fallback={<Loading />}>
              <PolicyDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/${n.HOLIDAY_CALENDAR_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <HolidayCalendar />
            </Suspense>
          }
        />
        {/* <Route
          path={`${n.MY_SPACE}/ASOP`}
          element={
            <Suspense fallback={<Loading />}>
              <AsopMainPage />
            </Suspense>
          }
        /> */}
        <Route
          path={`${n.MY_SPACE}/ASOP/Faq`}
          element={
            <Suspense fallback={<Loading />}>
              <Faq />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/ASOP/userGuide`}
          element={
            <Suspense fallback={<Loading />}>
              <UserGuide />
            </Suspense>
          }
        />
        {/*<Route path={n.MY_SPACE_CONTENTS} element={<Suspense fallback={<Loading/>}><Content/></Suspense>} />
            <Route path={n.My_SPACE_EDITPOP} element={<Suspense fallback={<Loading/>}><EditPopUp/></Suspense>} />
            <Route path={`/${n.TABLE_DATA}`} element={<Survey />} />*/}
        <Route
          path={`${n.MY_SPACE}`}
          element={
            <Suspense fallback={<Loading />}>
              <NoMatch isCode={false} message="Mywork Page" />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/leave`}
          element={
            <Suspense fallback={<Loading />}>
              <LeaveManagement />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/leave/absent-request`}
          element={
            <Suspense fallback={<Loading />}>
              <AbsentRequest />
            </Suspense>
          }
        />
        <Route
          path={`performance`}
          element={
            <Suspense fallback={<Loading />}>
              <Performance />
            </Suspense>
          }
        />
        <Route
          // path={`approvals`}
          path={`${n.MYWORK_URL}/approvals`}
          element={
            <Suspense fallback={<Loading />}>
              <Approvals />
            </Suspense>
          }
        />
        <Route
          // path={`approvals`}
          path={`${n.MYWORK_URL}/AdminPanel`}
          element={
            <Suspense fallback={<Loading />}>
              <AdminPanel />
            </Suspense>
          }
        />
        <Route
          // path={`approvals`}
          path={`${n.MYWORK_URL}/AdminPanel/EmployeeTimesheet/:id`}
          element={
            <Suspense fallback={<Loading />}>
              <AdminEmployeeTimesheet />
            </Suspense>
          }
        />
        <Route
          // path={`approvals`}
          path={`${n.MYWORK_URL}/AdminPanel/EmployeePreview/:timesheetId/:weekStartDate/:weekEndDate`}
          element={
            <Suspense fallback={<Loading />}>
              <AdminPreview />
            </Suspense>
          }
        />
        <Route
          // path={`approvals`}
          path={`${n.MYWORK_URL}/approvals/:tab`}
          element={
            <Suspense fallback={<Loading />}>
              <Approvals />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/Policy_Hub/`}
          element={
            <Suspense fallback={<Loading />}>
              <PolicyHub />
            </Suspense>
          }
        />

        <Route
          path={`${n.MY_SPACE}/performance/newgoal/:year`}
          element={
            <Suspense fallback={<Loading />}>
              <NewGoal />
            </Suspense>
          }
        />

        <Route
          path={`${n.MY_SPACE}/performance/editgoal/:year/:goalId`}
          element={
            <Suspense fallback={<Loading />}>
              <NewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/goalPlans/:year`}
          element={
            <Suspense fallback={<Loading />}>
              <CopyGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/milestone/:year`}
          element={
            <Suspense fallback={<Loading />}>
              <NewMilestone />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/milestone/:year/:goalId`}
          element={
            <Suspense fallback={<Loading />}>
              <NewMilestone />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/smartgoal`}
          element={
            <Suspense fallback={<Loading />}>
              <SmartGoals />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/goalEvaluation/detail/:appraisalId/:year?`}
          element={
            <Suspense fallback={<Loading />}>
              <GoalEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/goalEvaluation/:reporteeId/:year?`}
          element={
            <Suspense fallback={<Loading />}>
              <GoalEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/AspForm/view_asp/:appraisalId/:year?`}
          element={
            <Suspense fallback={<Loading />}>
              <EmployeeSignature />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/competencyevaluation/detail/:year/:appraisalId?`}
          element={
            <Suspense fallback={<Loading />}>
              <CompetencyEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/competencyevaluation/:reporteeId/:year?`}
          element={
            <Suspense fallback={<Loading />}>
              <CompetencyEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/view_asp/:currentStep?/:apprisalId/:year?`}
          element={
            <Suspense fallback={<Loading />}>
              <ViewAspForm />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/ChallengingGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <ChallengingGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/CascadingEditGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <CascadingEditGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/CascadingViewGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <CascadingViewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/${n.QUARTERLY_CHECKIN_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <QuarterlyCheckIn year={2024} />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/${n.ASP_FORM_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <AspForm year={2024} />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/:reporteeId`}
          element={
            <Suspense fallback={<Loading />}>
              <AspForm />
            </Suspense>
          }
        />
        <Route
          path={`newgoal`}
          element={
            <Suspense fallback={<Loading />}>
              <NewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/smartgoal/${n.SMART_GOALS_PREVIEW_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <ViewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/previewgoal/:year`}
          element={
            <Suspense fallback={<Loading />}>
              <PerformancePreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${TEAM_PERFORMANCE}/detail/:employeeId/previewgoal/:year`}
          element={
            <Suspense fallback={<Loading />}>
              <PerformancePreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${TEAM_PERFORMANCE}/${MANAGAER_CASCADE_GOAL_URL}/previewgoal/:year`}
          element={
            <Suspense fallback={<Loading />}>
              <PerformancePreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/performance/previewgoal/:year/:goalId`}
          element={
            <Suspense fallback={<Loading />}>
              <PerformancePreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/previewgoal/:year/:goalId`}
          element={
            <Suspense fallback={<Loading />}>
              <PerformancePreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/viewgoal/:year/:goalId`}
          element={
            <Suspense fallback={<Loading />}>
              <ViewGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/ViewGoal`}
          element={
            <Suspense fallback={<Loading />}>
              <SmartGoalPreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/detail/AltimetrikPhysicalAccessManagementPolicy`}
          element={
            <Suspense fallback={<Loading />}>
              <AltimetrikPhysicalAccessManagementPolicy />
            </Suspense>
          }
        />
        <Route
          path={`${LEADERBOARD_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <GamificationProfile />
            </Suspense>
          }
        />
        <Route
          path={`${BADGES_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <LockedBadges />
            </Suspense>
          }
        />
        <Route
          path={LOCKED_BADGES}
          element={
            <Suspense fallback={<Loading />}>
              <LockedBadgesDetail />
            </Suspense>
          }
        />
        <Route
          path={POINTS_DASHBOARD_URL}
          element={
            <Suspense fallback={<Loading />}>
              <PointsDashboard />
            </Suspense>
          }
        />
        <Route
          path={EARNED_BADGES}
          element={
            <Suspense fallback={<Loading />}>
              <ActiveIconDetail />
            </Suspense>
          }
        />
        <Route
          path={`team_performance`}
          element={
            <Suspense fallback={<Loading />}>
              <TeamPerformance />
            </Suspense>
          }
        />
        <Route
          path={n.TEAM_QUARTERLY_CHECKIN}
          element={
            <Suspense fallback={<Loading />}>
              <TeamsQuarterlyCheckin />
            </Suspense>
          }
        />
        <Route
          path={`/web/my_work/${"team_performance"}/${
            n.TEAM_QUARTERLY_CHECKIN_DETAIL
          }/:reporteeId`}
          element={
            <Suspense fallback={<Loading />}>
              <QuarterlyCheckInManager />
            </Suspense>
          }
        />
        <Route
          path={`/web/my_work/${"team_performance"}/asp`}
          element={
            <Suspense fallback={<Loading />}>
              <ManagerASP />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/manager_goal_evaluation`}
          element={
            <Suspense fallback={<Loading />}>
              <ManagerGoalEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/manager_competency_evaluation`}
          element={
            <Suspense fallback={<Loading />}>
              <ManagerCompetencyEvaluation />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/potential_rating/:reporteeId/:year?`}
          element={
            <Suspense fallback={<Loading />}>
              <PotentialRating />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/team_performance/cascadegoals`}
          element={
            <Suspense fallback={<Loading />}>
              <CascadeGoals />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/${n?.ARCHIVED_GOAL_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <ArchivedGoal />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/team_performance/cascadegoals`}
          element={
            <Suspense fallback={<Loading />}>
              <CascadeGoals />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/:page/detail/:reporteeId`}
          element={
            <Suspense fallback={<Loading />}>
              <EmployeeDetailPerformance />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/:page/detail/:activeTab?/:reporteeId`}
          element={
            <Suspense fallback={<Loading />}>
              <EmployeeDetailPerformance />
            </Suspense>
          }
        />
        <Route path={`${n.SEPARATION_DETAIL_URL}`} element={<Clearance />} />
        <Route path={`${n.CERTIFICATION_PREVIEW_URL}`} element={<Preview />} />
        <Route
          path={`${n.ADD_CERTIFICATION_URL}`}
          element={<AddCertification />}
        />
        <Route
          path={`${n.EDIT_CERTIFICATION_URL}`}
          element={<EditCertification />}
        />

        {/* <Route path={`${n.SEPARATION_URL}`} element={<SepationMainScreen />}>
          <Route path={`${n.CERTIFICATION_URL}`} element={<Certification />} />
          <Route
            path={`${n.CERTIFICATION_PREVIEW_URL}`}
            element={<Preview />}
          />
          <Route
            path={`${n.ADD_CERTIFICATION_URL}`}
            element={<AddCertification />}
          />
          <Route
            path={`${n.EDIT_CERTIFICATION_URL}`}
            element={<EditCertification />}
          />
          <Route path={`${n.LOGOUT_URL}`} element={<></>} />
          <Route path="*" element={<NoMatch />} />
        </Route> */}
        <Route
          path="*"
          element={
            <Suspense fallback={<Loading />}>
              <NoMatch isCode={true} message="Oops! Page Not Found" />
            </Suspense>
          }
        />
        <Route
          index
          path={`${BARTLE_TEST_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <BartleTest />
            </Suspense>
          }
        />
        <Route
          index
          path={`${GAMIFICATION_URL}`}
          element={
            <Suspense fallback={<Loading />}>
              <GamificationHomePage />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/performance/smartgame`}
          element={
            <Suspense fallback={<Loading />}>
              <SmartGame />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/timesheet`}
          element={
            <Suspense fallback={<Loading />}>
              <Timesheet />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/timesheet/createtimesheet/:shift/:week/:timesheetId?`}
          element={
            <Suspense fallback={<Loading />}>
              <CreateTimesheet />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/timesheet/myactivities`}
          element={
            <Suspense fallback={<Loading />}>
              <MyActivities />
            </Suspense>
          }
        />
        <Route
          path={`${n.MY_SPACE}/timesheet/myactivities/:status`}
          element={
            <Suspense fallback={<Loading />}>
              <MyActivities />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${TEAM_PERFORMANCE}/${ASP_FORM}/YearEndEvaluation/:currentStep/:reporteeId/:year?`}
          element={
            <Suspense fallback={<Loading />}>
              <ViewOverAllFormRating />
            </Suspense>
          }
        />
        {/* innovation */}
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}`}
          element={
            <Suspense fallback={<Loading />}>
              <InnovationLanding />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/opportunities/:tab?`}
          element={
            <Suspense fallback={<Loading />}>
              <Opportunities />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/opportunities/resume`}
          element={
            <Suspense fallback={<Loading />}>
              <Upload />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/opportunities/detail`}
          element={
            <Suspense fallback={<Loading />}>
              <OpportunitiesDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/opportunities/detail/application/preview/:id`}
          element={
            <Suspense fallback={<Loading />}>
              <ApplicationPreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/opportunities/detail/application/:id`}
          element={
            <Suspense fallback={<Loading />}>
              <ApplicationForm />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${ORCHESTRATOR_}`}
          element={
            <Suspense fallback={<Loading />}>
              <OrchestratorLanding />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${CREATE_PROGRAM}`}
          element={
            <Suspense fallback={<Loading />}>
              <CreateProgram />
            </Suspense>
          }
        />

        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${CREATE_PROGRAM}/${CHALLENGES}`}
          element={
            <Suspense fallback={<Loading />}>
              <Challenges />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${CREATE_PROGRAM}/${PREVIEW_PROGRAM}`}
          element={
            <Suspense fallback={<Loading />}>
              <PreviewProgram />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${IDEA_LANDING}`}
          element={
            <Suspense fallback={<Loading />}>
              <IdeaLanding />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${BUSINESS_CHALLENGE_DETAIL}/:responseId`}
          element={
            <Suspense fallback={<Loading />}>
              <BusinessChallengeDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${IDEA_LANDING}/${BUSINESS_CHALLENGE_DETAIL}/${IDEA_DETAIL}`}
          element={
            <Suspense fallback={<Loading />}>
              <IdeaDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${CREATE_IDEA}`}
          element={
            <Suspense fallback={<Loading />}>
              <IdeaCreate />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${CREATE_IDEA}/${PREVIEW_IDEA}`}
          element={
            <Suspense fallback={<Loading />}>
              <IdeaPreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${BUSINESS_CHALLENGE_DETAIL}`}
          element={
            <Suspense fallback={<Loading />}>
              <BusinessChallengeDetail />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${CREATE_BUSINESS_CHALLENGE}`}
          element={
            <Suspense fallback={<Loading />}>
              <BusinessChallengeCreate />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${EDIT_BUSINESS_CHALLENGE}/:responseId`}
          element={
            <Suspense fallback={<Loading />}>
              <BusinessChallengeCreate />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${CREATE_BUSINESS_CHALLENGE}/${PREVIEW_BUSINESS_CHALLENGE}`}
          element={
            <Suspense fallback={<Loading />}>
              <BusinessChallengePreview />
            </Suspense>
          }
        />
        <Route
          path={`${n.MYWORK_URL}/${INNOVATION}/${MY_ACTIVITIES_INNOVATION}/:tab`}
          element={
            <Suspense fallback={<Loading />}>
              <InnovationMyActivities />
            </Suspense>
          }
        />
      </Route>
    </Routes>
  );
}

export default PrivateLayout;
