import moment from "moment";
import {
  DRAFT,
  PRIVATE,
  PUBLIC,
  USERJWT,
  WEIGHTAGE_EXCEEDING,
} from "../../constants";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { CERTIFICATION_DATE_FORMAT } from "../../constants";
import dayjs from "dayjs";

export const isValidDate = (dateString: any) => {
  let newdateString = dateString;
  if (typeof dateString !== "string") newdateString = dateString["$d"];
  return moment(newdateString).format(CERTIFICATION_DATE_FORMAT);
};

export const leadingZero = (number: any) => {
  return number > 9 ? number : `0${number}`;
};

export const dateFormat = (data: any) => {
  let requestDate = new Date(data["$d"]);
  let format_date = requestDate.getDate();
  let format_month = requestDate.getMonth() + 1;
  let format_year = requestDate.getFullYear();
  return `${format_year}-${leadingZero(format_month)}-${leadingZero(
    format_date
  )}`;
};

export const replaceString = (
  str: string,
  sString: string,
  rString: string
) => {
  return str.replace(sString, rString);
};

export const isRadioButtonChecked = (list: any, type: string) => {
  if (Array.isArray(list)) {
    const data = [...list];
    let isSlectedCard = data.filter((item) => {
      return item.clearanceStatus !== "" && item.clearanceStatus !== null;
    });
    if (type === "change") {
      if (list.length === isSlectedCard.length) {
        const previousStatus = checkPreviousStatus(isSlectedCard);
        return previousStatus.length > 0 ? false : true;
      }
      return list.length === isSlectedCard.length ? false : true;
    } else {
      if (isSlectedCard.length === 0) {
        return true;
      } else if (list.length === isSlectedCard.length) {
        const previousStatus = checkPreviousStatus(isSlectedCard);
        return previousStatus.length > 0 ? false : true;
      }
      return list.length === isSlectedCard.length ? false : true;
    }
  } else {
    return false;
  }
};

export const checkPreviousStatus = (selectedCard: any) => {
  const previousStatus = selectedCard.filter((item: any) => {
    return item.clearanceStatus !== item.tempclearanceStatus;
  });
  return previousStatus;
};

export const getLoggedInUserDetails = () => {
  let details: any = localStorage.getItem("loggedInUserDetails");
  if (details) {
    return JSON.parse(details);
  } else {
    return null;
  }
};

export const setInLocalStroage = (keyName: string, value: any) => {
  return localStorage.setItem(keyName, value);
};

export const getFromLocalStorage = (keyName: string) => {
  return localStorage.getItem(keyName);
};

export const removeFromLocalStorage = (keyValue: string) => {
  return localStorage.removeItem(keyValue);
};

export function formatTimeDifference(inputTime: string): string {
  const currentTime: Date = new Date();
  const inputDate: Date = new Date(inputTime);
  const timeDifference: number = currentTime.getTime() - inputDate.getTime();
  const minutesDifference: number = Math.floor(timeDifference / (60 * 1000));

  if (minutesDifference === 1) {
    return "1 min ago";
  } else if (minutesDifference < 60) {
    return `${minutesDifference} mins ago`;
  } else if (minutesDifference === 60) {
    return "1 hr ago";
  } else if (minutesDifference < 60 * 24) {
    const hoursDifference: number = Math.floor(minutesDifference / 60);
    return `${hoursDifference} hrs ago`;
  } else {
    const dd: string = String(inputDate.getDate()).padStart(2, "0");
    const mm: string = String(inputDate.getMonth() + 1).padStart(2, "0");
    const yy: string = String(inputDate.getFullYear()).slice(-2);
    return `${dd}/${mm}/${yy}`;
  }
}
export const getWordLetter = (name: string) => {
  if (!name) return "";
  const avatarName = name
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();
  return avatarName?.length != 1
    ? avatarName.substring(1, 2) === "."
      ? avatarName.substring(0, 1)
      : avatarName.substring(0, 2)
    : avatarName + avatarName;
};

export const DateFormat = (date: any) => {
  return moment(date).format("DD MMM YYYY");
};

export const getLoggedInUserData = () => {
  const authJWTResponse: any = localStorage.getItem(USERJWT);
  const userData: any = authJWTResponse
    ? jwtDecode<JwtPayload>(authJWTResponse)
    : "";
  return userData;
};

export const maxBy = (arr, fn) =>
  Math.max(...arr.map(typeof fn === "function" ? fn : (val) => val[fn]));

export const newPageOptions = (totalItems, value) =>
  Array.from({ length: Math.ceil(totalItems / value) }, (_, index) =>
    (index + 1).toString()
  );

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export const get_time_difference = (recentDate: any) => {
  try {
    var serverDate = new Date(recentDate);
    var localDate = new Date();

    var diff = localDate.getTime() - serverDate.getTime();

    var msec = diff;
    var hh: any = Math.floor(msec / 1000 / 60 / 60);
    msec -= hh * 1000 * 60 * 60;
    var mm = Math.floor(msec / 1000 / 60);
    msec -= mm * 1000 * 60;
    var ss = Math.floor(msec / 1000);
    msec -= ss * 1000;

    return hh;
  } catch (e) {
    console.log(e);
  }
};

export const get_time_difference_mm = (recentDate: any) => {
  try {
    var serverDate = new Date(recentDate);
    var localDate = new Date();

    var diff = localDate.getTime() - serverDate.getTime();
    if (diff > 0) {
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm: any = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000;

      return mm;
    } else {
      return 0;
    }
  } catch (e) {
    console.log(e);
  }
};

export function getDateFor(timeStamp, formatType = "DD MMM YYYY") {
  const currentTimestamp = moment().format();
  const oneSecond = 1; //seconds
  const oneMinute = 60; //seconds
  const oneHour = 3600; //seconds
  const oneDay = 86400; //seconds
  const oneWeek = 604800; //seconds
  if (timeStamp && currentTimestamp) {
    const diffInSeconds = moment(currentTimestamp).diff(timeStamp, "seconds");
    const diffInMinutes = moment(currentTimestamp).diff(timeStamp, "minutes");
    const diffInHours = moment(currentTimestamp).diff(timeStamp, "hours");
    const diffInDays = moment(currentTimestamp).diff(timeStamp, "days");

    if (diffInSeconds <= oneMinute) {
      return "just now";
    } else if (diffInSeconds <= oneHour) {
      return diffInMinutes === 1
        ? `${diffInMinutes} min ago`
        : `${diffInMinutes} mins ago`;
    } else if (diffInSeconds <= oneDay) {
      return diffInHours === 1
        ? `${diffInHours} hour ago`
        : `${diffInHours} hours ago`;
    } else if (diffInSeconds <= oneWeek) {
      return diffInDays === 1
        ? `${diffInDays} day ago`
        : `${diffInDays} days ago`;
    } else if (diffInSeconds > oneWeek) {
      return moment(timeStamp).format(formatType);
    } else {
      return "";
    }
  }
}

export default function capitalizeFirstLetter(sentence) {
  var words = sentence.split(" ");
  var capitalizedWords = words.map(function (word) {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  var capitalizedSentence = capitalizedWords.join(" ");

  return capitalizedSentence;
}

export const skillArrayToobject = (skills: any) => {
  let rv: any = {};
  for (let i = 0; i < skills.length; ++i) rv["skill" + (i + 1)] = skills[i].key;
  return rv;
};

export const getBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export function getBase64asString(file) {
  return new Promise((resolve, reject) => {
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
      if (encoded.length % 4 > 0) {
        encoded += "=".repeat(4 - (encoded.length % 4));
      }
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });
}

export const base64ToFile = (base64: any, fileName: any, mimeType: any) => {
  var mime = mimeType,
    bstr = atob(base64),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let base = new File([u8arr], fileName, { type: mime });
  return [base];
};

export const formatCertificate = (listCertifications: any, listskills: any) => {
  const formattedCertifications = listCertifications.map((item: any) => {
    const { name, institution, description, startDate, endDate } = item;
    let basic: any = {
      name: name ? name : "",
      institution: institution ? institution : "",
      description: description ? description : "",
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null,
      upload: [],
      isNew: false,
      fileName: "",
      certificateId: item.certificateId,
    };
    const skills = [];
    if (item?.skill1) {
      const skill1: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill1
      );
      if (skill1.length > 0) {
        skills.push(skill1[0]);
      }
    }
    if (item?.skill2) {
      const skill2: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill2
      );
      if (skill2.length > 0) {
        skills.push(skill2[0]);
      }
    }
    if (item?.skill3) {
      const skill3: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill3
      );
      if (skill3.length > 0) {
        skills.push(skill3[0]);
      }
    }
    if (item?.skill4) {
      const skill4: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill4
      );
      if (skill4.length > 0) {
        skills.push(skill4[0]);
      }
    }
    if (item?.skill5) {
      const skill5: never[] = listskills.filter(
        (ele: any) => ele.key === item?.skill5
      );
      if (skill5.length > 0) {
        skills.push(skill5[0]);
      }
    }
    basic["skill"] = skills;
    if (item.sfAttachment && item.sfAttachment?.fileContent) {
      const { fileContent, fileName, mimeType, attachmentId } =
        item.sfAttachment;
      const sfAttachment = {
        fileName: fileName ? fileName : "",
        //upload: fileContent?base64ToFile(fileContent,fileName,mimeType):[],
        isNew: false,
        fileContent: fileContent ? fileContent : "",
        attachmentId: fileContent ? attachmentId : "",
        mimeType: mimeType,
        upload: [
          {
            name: fileName ? fileName : "",
            status: "done",
          },
        ],
        bckupload: [
          {
            name: fileName ? fileName : "",
            status: "done",
          },
        ],
      };
      basic = { ...basic, ...sfAttachment };
    }
    return basic;
  });
  return formattedCertifications;
};

export const isValidForm = (certificates: any) => {
  let status = false;
  certificates.filter((item: any) => {
    if (item === undefined || item?.name === "" || item?.name === undefined) {
      status = true;
      return item;
    }
  });
  return status;
};

export const dateSplit = (date: any, text: string) => {
  let label = "";
  let dateSplit = moment(date).format("DD-MMM-ddd").split("-");
  if (text === "date") {
    label = dateSplit[0];
  } else if (text === "month") {
    label = dateSplit[1]?.toLocaleUpperCase();
  } else if (text === "day") {
    label = dateSplit[2]?.toLocaleUpperCase();
  } else if (text === "dayname") {
    label = moment(date).format("dddd");
  }
  return label;
};

export function convertDateToMMYY(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth();
  const monthNames = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };
  return `${year}-${monthNames[month]}`;
}

export function isChecked(list, text) {
  var searchResult = list.filter((item) => item.label === text);
  return searchResult.length > 0 ? true : false;
}

export function formObject(list) {
  let listMilestone: any = [];
  let listViewMilestone: any = [];
  let listMilestoneStatus: any = [];
  list?.forEach((section: any) => {
    let rowObject = {};
    let rowViewObject = {};
    let rowMilestoneStatus =
      section?.milestoneStatusDetails?.milestoneStatusIcon;
    section.questionList?.forEach((question: any) => {
      const questionId = question.questionDTO.description;
      let answer = question.questionDTO.answer;
      let viewanswer = question.questionDTO.answer;
      if (
        questionId === "MILESTONE_QUESTION_2" ||
        questionId === "MILESTONE_QUESTION_3"
      ) {
        answer = answer ? dayjs(answer, "DD/MM/YYYY") : null;
      }
      rowObject[questionId] = answer;
      rowViewObject[questionId] = viewanswer;
    });
    listMilestone.push(rowObject);
    listViewMilestone.push(rowViewObject);
    listMilestoneStatus.push(rowMilestoneStatus);
  });
  const emptyList = listViewMilestone.filter(
    (item: any) =>
      item.MILESTONE_QUESTION_1 === null &&
      item.MILESTONE_QUESTION_2 === null &&
      item.MILESTONE_QUESTION_3 === null
  );
  return {
    listMilestone,
    listViewMilestone,
    listMilestoneStatus,
    isPreview: emptyList.length > 0 ? true : false,
  };
}

export function goalFormObject(list) {
  let singleGoal = {};
  list?.forEach((section: any) => {
    section.questionList?.forEach((question: any) => {
      const questionId = question.questionDTO.questionId;
      const description = question.questionDTO.description;
      let answer = question.questionDTO.answer;
      if (
        description === "GOAL_QUESTION_7" ||
        description === "GOAL_QUESTION_8"
      ) {
        answer = answer ? dayjs(answer, "DD/MM/YYYY") : null;
      } else if (description === "GOAL_QUESTION_6") {
        answer = answer ? parseInt(answer) : null;
      }
      singleGoal[questionId] = answer;
    });
  });
  return singleGoal;
}

export function dateObjectFormat(date) {
  if (typeof date === "string") {
    return date;
  } else if (date === null) {
    return "";
  } else {
    return isValidDate(date);
  }
}

export function goalEexceed(percentage: any) {
  return WEIGHTAGE_EXCEEDING.replace(":num", percentage);
}

export function goalVisibility(goalData: any) {
  if (goalData?.visibility === true || goalData?.GOAL_QUESTION_9 === true) {
    return PRIVATE;
  } else if (
    goalData?.visibility === false ||
    goalData?.GOAL_QUESTION_9 === false
  ) {
    return PUBLIC;
  }
}

export function goalWeight(goalData: any) {
  if (goalData?.weight || goalData?.GOAL_QUESTION_6) {
    return `${goalData?.weight || goalData?.GOAL_QUESTION_6} %`;
  }
  return "";
}

export function notificationGoal(filterGoal: any) {
  const exists = filterGoal?.filter((item) => item?.state === DRAFT);
  const noDraftUnsaved = filterGoal?.filter(
    (item) => item?.state !== DRAFT && item?.goalId !== null
  );
  const noDraftNoUnsaved = filterGoal?.filter(
    (item) => item?.state !== DRAFT && item?.sfGoalId !== null
  );
  let draftGoalExists = false;
  let actionNotification = false;
  if (exists.length > 0) {
    draftGoalExists = true;
    actionNotification = true;
  } else if (noDraftUnsaved.length > 0) {
    draftGoalExists = true;
    actionNotification = false;
  } else if (noDraftNoUnsaved.length > 0) {
    draftGoalExists = false;
    actionNotification = false;
  }
  return { draftGoalExists, actionNotification };
}
export function formatBadgesDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const formattedDate = `${day} ${
    monthNames ? monthNames[month] : month + 1
  } ${date.getFullYear()}`;
  return formattedDate;
}
export function formatHolidayCalendarDate(dateHoliday) {
  const date = new Date(dateHoliday);
  const day = date.getDate();
  const formattedDate = `${day}`;
  return formattedDate;
}

export function formatHolidayCalendarMonth(dateHoliday) {
  const date = new Date(dateHoliday);
  const month = date.getMonth();
  const monthNames = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const formattedMonth = `${monthNames ? monthNames[month] : month + 1}`;
  return formattedMonth;
}

export const formatBadgeName = (text) => {
  return text
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0)?.toUpperCase() + word?.slice(1))
    .join(" ");
};

export const timeSheetFormat = (defaultWeekSelection) => {
  let er = {
    project: "",
    totalHours: "00:00",
    task: "",
  };
  let mergeobject = {};
  Array.from(Array(7).keys()).forEach((item: any, index) => {
    const date = moment(defaultWeekSelection)
      .add(item, "days")
      .format("DD-MM-yyyy");
    mergeobject[`day_${index + 1}`] = date;
    // mergeobject[`date_${index + 1}`] = date;
    mergeobject[`comment_${index + 1}`] = "";
    mergeobject[`hour_${index + 1}`] = dayjs("00:00", "HH:mm");
  });
  const bothobj = { ...er, ...mergeobject };
  return bothobj;
};

export const oldtimeSheetProjectFormat = (projects, defaultWeekSelection) => {
  const listarray = projects.map((item) => ({
    projectName: item,
    totalHours: "45:00",
    overAllComment: "",
    isEnableWeekEnd: false,
    recordsList: timeSheetNewRowFormat(defaultWeekSelection),
  }));
  return listarray;
};

export const timeSheetProjectFormat = (projects) => {
  const listarray = projects.map((item) => {
    const { hours, minutes, recordsList } = item;
    const totalHours = `${leadingZero(hours)}:${leadingZero(minutes)}`;
    const formatRecordsList = recordsList.map((subitem) => {
      const { workedOn, task } = subitem;
      const taskData = task?.map((taskItm) => {
        const { hours, minutes } = taskItm;
        const working_hours = `${leadingZero(hours)}:${leadingZero(minutes)}`;
        return {
          ...taskItm,
          working_hours: dayjs(working_hours, "HH:mm"),
        };
      });

      return {
        ...subitem,
        task: taskData,
        weekSelection: dayjs(workedOn).format("MMM D, YYYY"),
      };
    });

    return { ...item, totalHours, recordsList: formatRecordsList };
  });
  return listarray;
};

export const timeSheetNewRowFormat = (defaultWeekSelection) => {
  const listarray = Array.from(Array(7).keys()).map((item: any) => ({
    dayOfWeek: moment(defaultWeekSelection).add(item, "days").format("ddd"),
    comments: "",
    task: "Billed",
    working_hours:
      item !== 5 && item !== 6
        ? dayjs("09:00", "HH:mm")
        : dayjs("00:00", "HH:mm"),
    workedOn: moment(defaultWeekSelection)
      .add(item, "days")
      .format("yyyy-MM-DD"),
  }));
  return listarray;
};

export const timeSheetUpdateRowFormat = (listofData) => {
  const list = listofData.map((item: any, index) => {
    return { ...item, working_hours: dayjs("00:00", "HH:mm") };
  });
  return list;
};

export const timeSheetCalculation = (listFilter) => {
  let day1 = 0.0;
  let day2 = 0.0;
  let day3 = 0.0;
  let day4 = 0.0;
  let day5 = 0.0;
  let day6 = 0.0;
  let day7 = 0.0;
  const checkListFilter = listFilter.map((item, rowindex) => {
    const {
      hour_1: h1,
      hour_2: h2,
      hour_3: h3,
      hour_4: h4,
      hour_5: h5,
      hour_6: h6,
      hour_7: h7,
    } = item;
    let single_row = 0.0;
    let hour_1: any =
      h1 && h1 !== ""
        ? typeof h1 === "string"
          ? replaceString(h1, ":", ".")
          : replaceString(dayjs(h1).format("HH:mm"), ":", ".")
        : "0.0";
    let hour_2: any =
      h2 && item.hour_2 !== ""
        ? typeof h2 === "string"
          ? replaceString(h2, ":", ".")
          : replaceString(dayjs(h2).format("HH:mm"), ":", ".")
        : "0.0";
    let hour_3: any =
      h3 && item.hour_3 !== ""
        ? typeof h3 === "string"
          ? replaceString(h3, ":", ".")
          : replaceString(dayjs(h3).format("HH:mm"), ":", ".")
        : "0.0";
    let hour_4: any =
      h4 && h4 !== ""
        ? typeof h4 === "string"
          ? replaceString(h4, ":", ".")
          : replaceString(dayjs(h4).format("HH:mm"), ":", ".")
        : "0.0";
    let hour_5: any =
      h5 && h5 !== ""
        ? typeof h5 === "string"
          ? replaceString(h5, ":", ".")
          : replaceString(dayjs(h5).format("HH:mm"), ":", ".")
        : "0.0";
    let hour_6: any =
      h6 && h6 !== ""
        ? typeof h6 === "string"
          ? replaceString(h6, ":", ".")
          : replaceString(dayjs(h6).format("HH:mm"), ":", ".")
        : "0.0";
    let hour_7: any =
      h7 && h7 !== ""
        ? typeof h7 === "string"
          ? replaceString(h7, ":", ".")
          : replaceString(dayjs(h7).format("HH:mm"), ":", ".")
        : "0.0";
    single_row =
      single_row +
      parseFloat(hour_1) +
      parseFloat(hour_2) +
      parseFloat(hour_3) +
      parseFloat(hour_4) +
      parseFloat(hour_5) +
      parseFloat(hour_6) +
      parseFloat(hour_7);
    day1 += parseFloat(hour_1);
    day2 += parseFloat(hour_2);
    day3 += parseFloat(hour_3);
    day4 += parseFloat(hour_4);
    day5 += parseFloat(hour_5);
    day6 += parseFloat(hour_6);
    day7 += parseFloat(hour_7);
    const replaceHours = replaceString(single_row.toFixed(2), ".", ":");
    return { ...item, totalHours: replaceHours };
  });
  let format_day1 = replaceString(day1.toFixed(2), ".", ":");
  let format_day2 = replaceString(day2.toFixed(2), ".", ":");
  let format_day3 = replaceString(day3.toFixed(2), ".", ":");
  let format_day4 = replaceString(day4.toFixed(2), ".", ":");
  let format_day5 = replaceString(day5.toFixed(2), ".", ":");
  let format_day6 = replaceString(day6.toFixed(2), ".", ":");
  let format_day7 = replaceString(day7.toFixed(2), ".", ":");
  const sumTotalHours = day1 + day2 + day3 + day4 + day5 + day6 + day7;
  let totalHours = replaceString(sumTotalHours.toFixed(2), ".", ":");
  return {
    format_day1,
    format_day2,
    format_day3,
    format_day4,
    format_day5,
    format_day6,
    format_day7,
    totalHours,
    checkListFilter,
  };
};

export const timeSheetHourCalculationold = (listFilter) => {
  let sum = 0.0;
  listFilter.forEach((item) => {
    const { working_hours: h1, totalHours } = item;

    let working_hours: any =
      h1 && h1 !== ""
        ? typeof h1 === "string"
          ? replaceString(h1, ":", ".")
          : replaceString(dayjs(h1).format("HH:mm"), ":", ".")
        : "0.0";
    sum = sum + parseFloat(working_hours);
  });
  const replaceHours = replaceString(sum.toFixed(2).toString(), ".", ":");
  return replaceHours;
};

export const timeSheetHourCalculation = (listFilter) => {
  let totalsum_hour: any = 0;
  let totalsum_minutes: any = 0;
  const listFilterResponse = listFilter.map((item, rowindex) => {
    let sum_hour = 0;
    let sum_minutes = 0;
    const inner = item.recordsList.map((subitem, subrowindex) => {
      const innerTask = subitem?.task?.map((taskItem, taskIndex) => {
        const { working_hours: h1 } = taskItem;
        let working_hours: any =
          h1 && h1 !== ""
            ? typeof h1 === "string"
              ? replaceString(h1, ":", ".")
              : replaceString(dayjs(h1).format("HH:mm"), ":", ".")
            : "0.0";
        const { hours = 0, minutes = 0 } = extractHourMinitue(
          working_hours,
          "."
        );

        sum_hour += parseInt(hours.toString(), 10);
        totalsum_hour += parseInt(hours.toString(), 10);
        sum_minutes += parseInt(minutes.toString(), 10);
        totalsum_minutes += parseInt(minutes.toString(), 10);
        if (sum_minutes >= 60) {
          sum_hour += ~~(sum_minutes / 60);
          sum_minutes = sum_minutes % 60;
        }
        if (totalsum_minutes >= 60) {
          totalsum_hour += ~~(totalsum_minutes / 60);
          totalsum_minutes = totalsum_minutes % 60;
        }
        return { ...taskItem };
      });
      return { ...subitem, task: innerTask };
    });
    const replaceHours = `${sum_hour}:${sum_minutes}`;
    return { ...item, totalHours: replaceHours, recordsList: inner };
  });
  return {
    listFilterResponse,
    totalHours: `${totalsum_hour}:${totalsum_minutes}`,
  };
};

export const extractHourMinitue = (strHours, spilitString) => {
  const splitString = strHours.split(spilitString);
  const [hours, minutes] = splitString;
  return { hours: parseInt(hours), minutes: parseInt(minutes) };
};

export const hourMinitue = () => {
  var h = 9,
    m = 0;
  var secsSinceMidnight = h * 3600 + m * 60;
  var oneTwelth = secsSinceMidnight / 3;
  h = Math.floor(oneTwelth / 3600);
  m = Math.floor((oneTwelth % 3600) / 60);
  console.log(h + ":" + m);
};

export const dailyComment = (records) => {
  const listComments = records?.filter((comment) => comment?.comments);
  return listComments?.length > 0 ? true : false;
};

export const isFilledComment = ({ index, parentindex, type, timesheets }) => {
  let comment;
  if (type === "overallComments") {
    comment = timesheets ? timesheets?.[parentindex]?.[type] : "";
  } else if (type === "comments") {
    comment = timesheets
      ? timesheets?.[parentindex]?.["recordsList"]?.[index]?.[type]
      : "";
  }
  return comment || "";
};
export function getTimeAgo(dateString) {
  const postedDate: any = new Date(dateString);
  const currentDate: any = new Date();
  const timeDifference: any = currentDate - postedDate;

  // Calculate days, weeks, months, and years
  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.42);
  const years = Math.floor(days / 365.25);

  // Return appropriate time ago string
  if (days === 0) {
    return "Today";
  } else if (days <= 6) {
    return `${days} days ago`;
  } else if (weeks <= 4) {
    return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
  } else if (months <= 11) {
    return `${months} month${months > 1 ? "s" : ""} ago`;
  } else {
    return `${years} year${years > 1 ? "s" : ""} ago`;
  }
}

export const download = ({ fileName, mimeType, fileContent }) => {
  //const { fileName, mimeType, fileContent } = info;
  const linkSource = `data:${mimeType};base64,${fileContent}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
  return true;
};

export const isFileType = ({ filename, type }) => {
  return filename.indexOf(type) > -1;
};

export const fileSize = ({ size }) => {
  const file = Math.round(size / 1024);
  let fileSize;
  if (file < 1024) {
    fileSize = `${file} kb`;
  } else {
    fileSize = `${file} mb`;
  }
  return fileSize;
};

export const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const saveByteArray = (reportName, byte, type) => {
  var blob = new Blob([byte], { type: "application/msword" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
};

export const jobStatus = (str) => {
  const convertion = str?.toLowerCase();
  const converted_string = replaceString(convertion, " ", "_");
  return converted_string;
};

export const formatNumber = (number) => {
  if (number >= 1000) {
    // Convert the number into a shorter format (k, M, etc.)
    const num = number / 1000;
    return num.toFixed(1) + "K"; // Display one decimal place and append 'k'
  } else {
    return number.toString(); // If less than 1000, just return the number
  }
};
export const isJobStatusAvailable = (status) => {
  const list = ["selected", "rejected"];
  return list.indexOf(status) > -1;
};
