import "./index.css";
import { useEffect, useState } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/store/redux-hooks";
import {
  dynamicApiCallLeave,
  dynamicApiCallRelation,
} from "../../../redux/actions/leavemanagement";
import Right from "../../../themes/assets/images/svg/leave_management/ChevronRight.svg";
import { RelationsPopup } from "./Modals";

const RelationsDropdown = (props: any) => {
  const {
    title,
    url,
    type,
    placeholder,
    selectedCardItem,
    mandatory,
    infoText,
    componentStatus,
    editValue,
    leaveTypeURL,
  } = props;
  const [dropdownValue, setDropdownValue] = useState(editValue);
  const [optionPopup, setOptionPopup] = useState(false);
  const [validationInfo, setValidationInfo] = useState(null);
  const [leaveBalanceID, setLeaveBalanceID] = useState("");
  const dispatch = useAppDispatch();
  const { warningLeaveLoading, warningLeaveData, requestingdays } =
    useAppSelector((state: any) => state.leavemanagement);

  console.log(requestingdays);
  const OnClickCard = () => {
    if (url) {
      dispatch(dynamicApiCallRelation({ url: url }));
    }
    setOptionPopup(true);
  };

  const leaveBalanceCheck = {
    link: leaveTypeURL,
    templateID: leaveBalanceID,
  };

  const OnClickSelectedCard = (data: any) => {
    const { displayName, remarks, validationMessage, order, leaveBalanceId } =
      data || "";
    setLeaveBalanceID(leaveBalanceId);

    if (displayName && dropdownValue === displayName) {
      setOptionPopup(false);
    } else if (
      (validationMessage &&
        validationMessage?.error === null &&
        componentStatus === "static") ||
      componentStatus === "dynamic"
    ) {
      setDropdownValue(displayName || remarks?.title);
      const value = order;
      setValidationInfo(validationMessage?.info);
      selectedCardItem(data, value);
      setOptionPopup(false);
    }
  };

  useEffect(() => {
    if (leaveBalanceID && leaveTypeURL) {
      dispatch(dynamicApiCallLeave({ leaveBalanceCheck }));
    }
  }, [leaveBalanceID]);

  return (
    <div id="form-item-container" className="form-item-container">
      <div id="form-item-text" className="form-item-text">
        {title} {mandatory && <span className="mandatory">*</span>}
      </div>
      <div
        onClick={() => OnClickCard()}
        id="select-type-input-box"
        className="select-type-input-box"
      >
        <div
          id="input-text"
          className="input-text"
          style={{ color: dropdownValue ? "#141414" : "#8c8c8c" }}
        >
          {dropdownValue ? dropdownValue : placeholder}
        </div>
        <img src={Right} />
      </div>
      {infoText || validationInfo ? <></> : null}
      {leaveBalanceID &&
      !warningLeaveLoading &&
      warningLeaveData?.leaveBalance === "0" ? (
        <div
          id="qa-floater-left"
          className="quick-apply-floater-landing"
          style={{ background: warningLeaveData?.placeHolder?.backgroundColor }}
        >
          <span className="quick-apply-floater-left-img">
            <img src={warningLeaveData?.resourceurl} alt="Warning" />
          </span>
          <span
            className="quick-apply-floater-left-txt"
            style={{ color: warningLeaveData?.placeHolder?.textColor }}
          >
            {warningLeaveData?.leaveTypeDisplayName}
          </span>
        </div>
      ) : null}
      {type === "DROPDOWN" || type === "RADIO_DROPDOWN" ? (
        <RelationsPopup
          key={"dropdwon-modal"}
          isVisible={optionPopup}
          title={title}
          onClose={() => setOptionPopup(false)}
          OnClickSelectedCard={OnClickSelectedCard}
        />
      ) : null}
    </div>
  );
};

export default RelationsDropdown;
